import { useDispatch, useSelector } from 'react-redux';
import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { UserManagementService } from '../services/UserManagementService';
import { setUsersRole } from '../../redux/features/users/usersSlice';
import { RootState } from '../../redux/app/store';

export const useUserRole = () => {
    const dispatch = useDispatch();
    const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    const reduxUsers = useSelector((state: RootState) => state.users.users);
    const { instance, inProgress } = useMsal();

    const fetchUserRole = async (email: string): Promise<string | null> => {
        const cachedUser = reduxUsers[email];
        const cachedRole = cachedUser?.role;
        const isLoading = cachedUser?.loading;

        if (cachedRole) {
            return cachedRole;
        }

        if (isLoading) {
            return null;
        }

        try {
          //  dispatch(setRoleLoading({email , isLoading: true}));
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const currentUser = await userManagementService.getUserRoleByEmail(accessToken);
            const role = currentUser?.role?.toString() || '';
            dispatch(setUsersRole({ email, role }));
            return role;
        } catch (error) {
            console.error('Failed to fetch role for', email, ':', error);
            dispatch(setUsersRole({ email, role: undefined }));
            return null;
        }
    };

    return { fetchUserRole };
};
