// useChatMemorySources.ts (Custom hook)
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useRef } from 'react';
import { RootState } from '../../redux/app/store';
import { ChatMemorySource } from '../models/ChatMemorySource';
import { ChatService } from '../services/ChatService';
import { AuthHelper } from '../auth/AuthHelper';
import { selectChatMemorySources } from '../../redux/features/chatMemory/chatMemorySelector';
import { setChatMemorySources } from '../../redux/features/chatMemory/chatMemorySlice';
import { useMsal } from '@azure/msal-react';

const useChatMemorySources = (chatId: string, gptEndpoint: string, isForcedRequest?: boolean) => {
    const dispatch = useDispatch();
    const chatService = useMemo(()=> new ChatService(process.env.REACT_APP_BACKEND_URI as string),[]);
    const sources = useSelector((state: RootState) => selectChatMemorySources(state, chatId));
    const { instance, inProgress } = useMsal();
    const isLoadingRef = useRef(false);

    const getChatMemorySources = useCallback(async (): Promise<ChatMemorySource[]> => {
        if (sources?.length > 0 && !isForcedRequest) {
            console.log(`Returning cached sources for chatId ${chatId}:`, sources);
            return sources;
        }

        if (isLoadingRef.current) {
            console.log(`Request for chatId ${chatId} is already in progress.`);
            return [];
        }

        isLoadingRef.current = true;

        try {
            const token = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            console.log(`Fetching sources from API for chatId ${chatId}`);
            const apiSources = await chatService.getChatMemorySourcesAsync(chatId, gptEndpoint, token);

            if (apiSources?.length > 0) {
                dispatch(setChatMemorySources({ chatId, sources: apiSources }));
            }

            return apiSources;
        } catch (e: any) {
            console.error('Error fetching chat memory sources:', e);
            return [];
        } finally {
            isLoadingRef.current = false;
        }
    }, [chatId, gptEndpoint, sources, dispatch, chatService, instance, inProgress]);

    return getChatMemorySources;
};

export default useChatMemorySources;