import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import proGPTLogo from '../../assets/iManageIcon.png';
import { Dialog, DialogSurface, DialogTitle, DialogContent, Button, Input, Divider } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { makeStyles } from '@fluentui/react-components';
import { customColors, customFonts } from '../../styles';
import { IGPT } from '../../libs/models/GPT';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../libs/hooks';
import React from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { IEngagement } from '../../libs/models/Engagement';
import _debounce from 'lodash/debounce';
import { useEAPermission } from '../../libs/hooks/useEAService';

const useClasses = makeStyles({
    dialogContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '60px 20px',
    },
    dialogTitle: {
        fontWeight: 750,
        fontSize: '20px',
        lineHeight: '24.2px',
        letterSpacing: '0%',
        fontFamily: customFonts.Lato,
    },
    dialogSubtitle: {
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '14.52px',
        letterSpacing: '0%',
        marginTop: '15px',
        fontFamily: customFonts.Lato,
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    divider: {
        width: '100%',
        marginBottom: '16px',
    },
    sectionContainer: {
        border: '1px solid gray',
        padding: '20px',
        width: '100%',
        maxWidth: '500px',
        marginBottom: '16px',
        textAlign: 'left',
        backgroundColor: '#F7F7F8',
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sectionTitle: {
        fontFamily: customFonts.TitleFont,
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    sectionImage: {
        width: '23%',
        height: '23%',
    },
    sectionBody: {
        fontFamily: customFonts.Lato,
        fontWeight: 400,
        fontSize: '12.7px',
        lineHeight: '14.52px',
        marginTop: '10px',
    },
    redText: {
        color: 'red',
    },
    greenText: {
        color: '#1DA41A',
    },
    orText: {
        textAlign: 'center',
        marginBottom: '16px',
    },
    nextButton: {
        padding: '10px 40px',
        border: `2px solid ${customColors.protivitiOrange}`,
        marginTop: '16px',
        width: '50%',
        fontFamily: customFonts.Lato,
        fontWeight: 700,
        fontSize: '16px',
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

interface EALandingProps {
    gpt?: IGPT | undefined;
    gptId?: string;
    isOpen?: boolean;
    onClose?: () => void;
}

export const EALanding: React.FC<EALandingProps> = ({ gpt, gptId, isOpen, onClose }) => {
    const [modalIsOpen, setModalIsOpen] = useState(isOpen);
    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    const closeModal = () => {
        setModalIsOpen(false);
        if (onClose) onClose();
    };

    const [projectName, setProjectName] = useState('');
    const classes = useClasses();
    const ea = useEAPermission();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<{ label: string; value: string, id: string } | null>(null);
    const [engagements, setEngagements] = useState<IEngagement[]>([]);
    const [options, setOptions] = useState<{ label: string; value: string, id: string }[]>([]);

    const handleInputChange = (inputValue: string) => {
        if (inputValue.length >= 3) {
            setSelectedOption(null);
            loadOptions(inputValue, (options: any) => setOptions(options));
        } else {
            setOptions([]);
        }
    };

    const loadOptions = _debounce(async (query: string, callback: any) => {
        try {
            if (query.length >= 3) {
                setIsLoading(true);
                await ea
                    .getEngagementsAsync(query)
                    .then((res: any[]) => {
                        setEngagements([...engagements, ...res]);
                        const engagementList = res.map((engagement) => ({
                            label: engagement.name + ' - ' + engagement.id,
                            value: engagement.name,
                            id: engagement.id
                        }));
                        setOptions(engagementList);
                        callback(engagementList);
                    })
                    .catch((error: any) => {
                        console.error('Error loading options:', error);
                        setIsLoading(false);
                        callback([]);
                    });
            } else {
                setOptions([]);
                callback([]);
            }
        } catch (error) {
            console.error('Error loading options:', error);
            callback([]);
        }
        setIsLoading(false);
    }, 750);

    const handleSelectChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
    };

    const isNextDisabled = !!(
        (!selectedOption && !projectName) ||
        (selectedOption && selectedOption.label.length > 0 && projectName && projectName.length > 0)
    );

    const [isCreatingChat, setIsCreatingChat] = useState(false);
    const navigate = useNavigate();
    const chat = useChat();
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const activeGPT = React.useMemo(() => {
        if (!gpt) return userGPTs.find((g) => g.id === gptId);
        return userGPTs.find((g) => g.gptEndpoint === conversations[selectedId]?.gptEndpoint);
    }, [selectedId, userGPTs]);

    const createChat = useCallback(async (projectName: string, engagementId: string | null) => {
        if (activeGPT) {
            setIsCreatingChat(true);
            const chatTitle = projectName;
            if (engagementId) {
                var newChatId = await toast.promise(chat.createChat(activeGPT, chatTitle, engagementId), {
                    pending: {
                        render: 'Creating chat...',
                        position: 'top-right',
                        className: classes.toastMessage,
                    },
                });
            } else {
                var newChatId = await toast.promise(chat.createChat(activeGPT, chatTitle), {
                    pending: {
                        render: 'Creating chat...',
                        position: 'top-right',
                        className: classes.toastMessage,
                    },
                });
            }
            
            if (newChatId == 'Chat session cannot be created more than 20.')
                toast.error('More than 20 chat sessions cannot be created. Remove a chat to create a new chat!', {
                    className: classes.toastMessage,
                });
            else if (newChatId.startsWith('Unable to create new chat'))
                toast.error(null, {
                    className: classes.toastMessage,
                });
            else {
                closeModal();
                navigate(`/chat/${newChatId}`);
            }
        }
        setIsCreatingChat(false);
    }, [activeGPT, selectedOption, chat, classes.toastMessage, navigate]);

    return (
        <Dialog modalType="modal" open={modalIsOpen}>
            <DialogSurface>
                <DialogContent className={classes.dialogContent}>
                    <Button
                        appearance="subtle"
                        icon={<Dismiss24Regular />}
                        onClick={closeModal}
                        className={classes.closeButton}
                    />
                    <DialogTitle className={classes.dialogTitle}>
                        Do you want to leverage files already centrally stored?
                    </DialogTitle>
                    <p className={classes.dialogSubtitle}>
                        Ability to pair with iManage mirrors access controls with the Engagement Accelerator. If you
                        don't see your client - opportunity in the drop-down, contact your EM or EMD.
                    </p>

                    <div className={`${classes.sectionContainer}`} style={selectedOption ? { border: '1.5px solid #1DA41A' } : {}}>
                        <div className={classes.sectionHeader}>
                            <div className={classes.sectionTitle}>
                                CLIENT OPPORTUNITY / <span className={classes.greenText}>YES</span> - PAIR WITH IMANAGE
                            </div>
                            <img src={proGPTLogo} className={classes.sectionImage} />
                        </div>
                        <p className={classes.sectionBody}>
                            Entering your Client - Opportunity allows the AI to pull relevant information and files from
                            iManage. You can still upload and utilize any files without pairing.
                        </p>

                        <Select
                            isMulti={false}
                            value={selectedOption}
                            isClearable={true}
                            classNamePrefix="select"
                            className="basic-single-select"
                            placeholder="Enter your iManage client opportunity name here..."
                            options={options}
                            isLoading={isLoading}
                            onInputChange={handleInputChange}
                            onChange={handleSelectChange}
                        />
                    </div>

                    <Divider className={classes.divider}>Or</Divider>

                    <div className={`${classes.sectionContainer}`} style={projectName ? { border: '1.5px solid red' } : {}}>
                        <div className={classes.sectionTitle}>
                            NAME YOUR PROJECT / <span className={classes.redText}>NO</span> - DO NOT PAIR WITH IMANAGE
                        </div>
                        <p className={classes.sectionBody}>
                            Entering a project name helps to organize your accelerators. You can change this name at any
                            time.
                        </p>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="Name your project here"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                    </div>

                    <Button
                        size="medium"
                        appearance="secondary"
                        shape="square"
                        disabled={isNextDisabled}
                        onClick={async () => await createChat(projectName ? projectName : selectedOption?.value || '', projectName ? null : selectedOption?.id || null)}
                        style={{
                            cursor: isCreatingChat ? 'progress' : 'pointer',
                            opacity: isCreatingChat ? 0.5 : 1,
                            pointerEvents: isCreatingChat ? 'none' : 'auto',
                            borderColor: customColors.protivitiOrange,
                        }}
                        className={classes.nextButton}
                    >
                        Next
                    </Button>
                </DialogContent>
            </DialogSurface>
        </Dialog>
    );
};
