import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC, useEffect, useRef, memo, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { PanelGroup, Panel } from 'react-resizable-panels';
import { setCanvasOpen } from '../../redux/features/conversations/conversationsSlice';

import { Artifact } from '../../libs/models/ChatMessage';
import Canvas from './canvas/Canvas';
import { ChatRoom } from './ChatRoom';
import { customColors } from '../../styles';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
    },
    chatSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: '200px',
        maxWidth: 'calc(100%)',
        transition: 'width 0.3s ease',
    },
    canvasSection: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '25px',
        left: 0,
        width: '50%',
        minWidth: '250px',
        maxWidth: 'calc(100% - 200px)',
        maxHeight: 'calc(100% - 50px)',
        padding: '2px',
        background: customColors.lightBlueGrayBackground3,
        backdropFilter: 'blur(100px)',
        transition: 'width 0.5s ease-in-out, opacity 0.4s ease-in-out, transform 0.5s ease-in-out',
        borderRadius: '20px 0 0 20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
});

const ChatRoomWithCanvas: FC = () => {
    const classes = useClasses();
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [artifacts, setArtifacts] = useState<Artifact[]>([]);

    const { conversations, selectedId, canvasOpen } = useAppSelector((state: RootState) => state.conversations);
    const messages = conversations[selectedId]?.messages;

    const panelGroupRef = useRef(null);
    useEffect(() => {
        if (panelGroupRef.current) {
            window.requestAnimationFrame(() => {
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, [canvasOpen]);

    const handleCloseCanvas = useCallback(() => {
        dispatch(setCanvasOpen(false));
        if (panelGroupRef.current && (!canvasOpen || canvasOpen === undefined)) {
            window.requestAnimationFrame(() => {
                const chatPanel = document.getElementById('chatPanel');
                const canvasPanel = document.getElementById('canvasPanel');
                if (chatPanel && canvasPanel) {
                    chatPanel.style.transition = 'flex 0.4s ease';
                    canvasPanel.style.transition = 'flex 0.4s ease';
                    chatPanel.style.flex = '0 0 100%';
                    canvasPanel.style.flex = '0 0 0%';
                    setTimeout(() => {
                        canvasPanel.style.display = 'none';
                    }, 1000);
                }
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, [dispatch]);

    const handleExpandCanvas = useCallback(() => {
        dispatch(setCanvasOpen(true));
        if (panelGroupRef.current) {
            window.requestAnimationFrame(() => {
                const chatPanel = document.getElementById('chatPanel');
                const canvasPanel = document.getElementById('canvasPanel');
                if (chatPanel && canvasPanel) {
                    canvasPanel.style.display = 'flex';
                    chatPanel.style.transition = 'flex 0.5s ease';
                    canvasPanel.style.transition = 'flex 0.5s ease';
                    chatPanel.style.flex = '0 0 40%';
                    canvasPanel.style.flex = '0 0 60%';
                }
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, [dispatch]);

    const handleRetractCanvas = useCallback(() => {
        dispatch(setCanvasOpen(true));
        if (panelGroupRef.current) {
            window.requestAnimationFrame(() => {
                const chatPanel = document.getElementById('chatPanel');
                const canvasPanel = document.getElementById('canvasPanel');
                if (chatPanel && canvasPanel) {
                    canvasPanel.style.display = 'flex';
                    chatPanel.style.transition = 'flex 0.5s ease';
                    canvasPanel.style.transition = 'flex 0.5s ease';
                    chatPanel.style.flex = '0 0 70%';
                    canvasPanel.style.flex = '0 0 30%';
                }
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, [dispatch]);

    useEffect(() => {
        const updatedArtifacts = messages?.flatMap((message) => message.artifacts || []) || [];
        setArtifacts(updatedArtifacts);
    }, [messages]);

    return (
        <div className={classes.container} ref={containerRef}>
            <PanelGroup direction="horizontal" ref={panelGroupRef}>
                <Panel
                    id="chatPanel"
                    defaultSize={canvasOpen ? 50 : 100}
                    minSize={40}
                    maxSize={canvasOpen ? 70 : 100}
                    className={classes.chatSection}
                    order={1}
                >
                    <ChatRoom />
                </Panel>
                <Panel
                    id="canvasPanel"
                    defaultSize={canvasOpen ? 60 : 0}
                    minSize={canvasOpen ? 20 : 0}
                    maxSize={canvasOpen ? 60 : 0}
                    className={classes.canvasSection}
                    order={2}
                    style={{
                        opacity: canvasOpen ? 1 : 0,
                        transform: canvasOpen ? 'translateX(0)' : 'translateX(100%)',
                        transition: 'opacity 0.4s ease, transform 0.4s ease, width 0.4s ease',
                        display: canvasOpen ? 'flex' : 'none',
                        height: '100%',
                        position: canvasOpen ? 'relative' : 'absolute',
                        right: canvasOpen ? undefined : '-999px',
                        pointerEvents: canvasOpen ? 'auto' : 'none',
                        overflow: 'hidden',
                    }}
                >
                    <Canvas
                        artifacts={artifacts}
                        onCloseCanvas={handleCloseCanvas}
                        onExpandCanvas={handleExpandCanvas}
                        onRetractCanvas={handleRetractCanvas}
                    />
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default memo(ChatRoomWithCanvas);
