import { Button } from '@fluentui/react-button';
import { makeStyles } from '@fluentui/react-components';
import { Tooltip } from '@fluentui/react-tooltip';
import React, { useCallback, useState } from 'react';
import { ReactComponent as EditComponent } from '../../../assets/Edit.svg';
import { Breakpoints } from '../../../styles';
import { InvitationCreateDialog } from '../invitation-dialog/InvitationCreateDialog';
import { DeleteChatDialog } from './dialogs/DeleteChatDialog';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { useChat } from '../../../libs/hooks';
import { asBlob } from 'html-docx-js-typescript';
import { timestampToDateString } from '../../utils/TextUtils';
import { ArrowDownload20Filled } from '@fluentui/react-icons';
import { ReactComponent as EditDisabled } from '../../../assets/EditDisabled.svg';

const useClasses = makeStyles({
    root: {
        display: 'contents',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    overlayRoot: {
        display: 'contents',
    },
});

interface IListItemActionsProps {
    chatId: string;
    chatName: string;
    onEditTitleClick: () => void;
}

export const ListItemActions: React.FC<IListItemActionsProps> = ({ chatId, chatName, onEditTitleClick }) => {
    const classes = useClasses();
    const chat = useChat();
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    //const { features } = useAppSelector((state: RootState) => state.app);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    //const { downloadFile } = useFile();
    const [isGettingInvitationId, setIsGettingInvitationId] = useState(false);
    const [isTitleGenerated, setIsTitleGenerated] = useState(conversations[chatId]?.isTitleGenerated);

    React.useEffect(() => {
        if (
            conversations[chatId]?.isTitleGenerated === undefined ||
            conversations[chatId]?.isTitleGenerated === false
        ) {
            setIsTitleGenerated(false);
        } else {
            setIsTitleGenerated(true);
        }
    }, [conversations, chatId]);

    // const onDownloadBotClick = useCallback(() => {
    //     // TODO: [Issue #47] Add a loading indicator
    //     void chat.downloadBot(conversations[chatId].userId, chatId, conversations[chatId].gptEndpoint).then((content) => {
    //         downloadFile(
    //             `chat-history-${chatName}-${new Date().toISOString()}.json`,
    //             JSON.stringify(content),
    //             'text/json',
    //         );
    //     });
    // }, [chat, chatId, chatName, downloadFile]);

    const convo = conversations[chatId];
    const { isCollapsed,isOverlay } = useAppSelector((state: RootState) => state.commonData);
    const gptEndpoint = chat ? convo.gptEndpoint : undefined;

    const isDefaultGPT = gptEndpoint
        ? userGPTs.find((gpt) => gpt.gptEndpoint === gptEndpoint)?.id === process.env.REACT_APP_DEFAULT_PROGPT
        : false;
    const isValidJson = (content: string) => {
        try {
            const parsed = JSON.parse(content);
            if (parsed && parsed.documents) {
                return parsed.documents[0].name;
            }
        } catch (e) {
            return '';
        }
    };

    const formatMarkdownCodeBlock = (content: string) => {
        const codeBlockRegex = /```([\s\S]*?)```/g;
        const tableRegex = /(?<!```markdown)\n(\|.*\|\s*\n\|\s*-+(\s*\|\s*-+)*\s*\|\s*\n((\|\s*.+\s*\|\s*\n)+))/g;
        const numberedListRegex = /((?:^\d+\.\s+.+\n?)+)/gm;
        const unorderedListRegex = /((?:^-+\s+.+\n?)+)/gm;
        // check for html code blocks before rendering markdown items
        const escapeHTMLContent = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const parsedNumberedList = escapeHTMLContent.replace(numberedListRegex, (match) => {
            return `<ol>${match}</ol>`;
        });
        const parsedUnorderedList = parsedNumberedList.replace(unorderedListRegex, (match) => {
            return `<ul>${match}</ul>`;
        });
        const replacedMarkdownElements = parsedUnorderedList
            .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.+?)\*/g, '<em>$1</em>');
        const markdownTables = replacedMarkdownElements.replace(tableRegex, (match) => {
            return `\`\`\`\n${match}\`\`\`\n`;
        });
        const replacedCodeBlock = markdownTables.replace(codeBlockRegex, (match) => {
            return `<code>${match}</code>`;
        });
        return replacedCodeBlock;
    };
    const onDownloadChatClick = useCallback(async () => {
        await chat.downloadBot(chatId, conversations[chatId].gptEndpoint).then(async (messages) => {
            const chatTitle = messages?.chatTitle;
            const chatHistory = messages?.chatHistory;
            const preHTML = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8" />
                    <title>Document</title>
                    <style>
                        body {
                            font-family: "Arial", sans-serif;
                            font-size: 10pt;
                            line-height: 14pt;
                        }
                        .header {
                            display: inline;
                        }
                        .timestamp {
                            font-size: 10pt;
                            font-weight: 400;
                        }
                        .content {
                            font-family: "Arial", sans-serif;
                            font-size: 10pt;
                        }
                    </style>
                </head>
                <body>`;
            const postHTML = '</body></html>';
            let innerHTML = '';
            innerHTML += `<h2>${chatTitle}</h2>`;
            chatHistory?.forEach((message) => {
                let content = message.content;
                const timestamp = timestampToDateString(message.timestamp, true);
                if (conversations[chatId].chatType) {
                    const documentName = isValidJson(content);
                    if (documentName) {
                        content = 'Document Uploaded: ' + documentName;
                    }
                }
                content = formatMarkdownCodeBlock(content);
                const userName =
                    message.userName == 'Bot'
                        ? conversations[chatId].chatType
                            ? 'DocumentGPT'
                            : 'ProGPT'
                        : message.userName;
                innerHTML += `
                <div class="message">
                <div class="header">
                <h4>${userName} &nbsp;&nbsp;&nbsp;&nbsp;<span class="timestamp">${timestamp}</span></h4>
                </div>
                <div class="content"><pre class="content">${content}</pre></div>
                <hr>
                </div>
                `;
            });
            const html = preHTML + innerHTML + postHTML;
            try {
                const converted = await asBlob(html, {
                    orientation: 'portrait',
                    margins: { top: 720, right: 720, bottom: 720, left: 720 },
                });

                const blob = new Blob([converted], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                const url = window.URL.createObjectURL(blob);

                // Create a temporary link to download the blob
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = conversations[chatId].title
                    ? `${conversations[chatId].title}.docx`
                    : 'document.docx';

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.log('Error');
            }
        });
    }, [chat, chatId]);

    return (
        <div className={isOverlay ? classes.overlayRoot : classes.root} style={isCollapsed ? {display: 'none'} : {}}>
            <Tooltip content={'Edit chat name'} relationship="label">
                <Button
                    icon={isTitleGenerated === false ? <EditDisabled /> : <EditComponent />}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={onEditTitleClick}
                    disabled={isTitleGenerated === false}
                />
            </Tooltip>
            {isDefaultGPT && (
                <Tooltip content={'Download chat session'} relationship="label">
                    <Button
                        disabled={!isDefaultGPT}
                        style={{ color: 'white', backgroundColor: 'transparent' }}
                        icon={<ArrowDownload20Filled />}
                        appearance="transparent"
                        aria-label="Edit"
                        onClick={onDownloadChatClick}
                    />
                </Tooltip>
            )}
            {/* <Tooltip content={'Share live chat code'} relationship="label">
                <Button
                    disabled={!features[FeatureKeys.MultiUserChat].enabled}
                    icon={<Share20 />}
                    appearance="transparent"
                    aria-label="Edit"
                    onClick={() => {
                        setIsGettingInvitationId(true);
                    }}
                />
            </Tooltip> */}
            <DeleteChatDialog chatId={chatId} chatName={chatName} />

            {/* {features[FeatureKeys.DeleteChats].enabled && <DeleteChatDialog chatId={chatId} chatName={chatName} />} */}
            {isGettingInvitationId && (
                <InvitationCreateDialog
                    onCancel={() => {
                        setIsGettingInvitationId(false);
                    }}
                    chatId={chatId}
                />
            )}
        </div>
    );
};
