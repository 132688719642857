import { IPersona } from '../models/IPersona';
import { ISavedPrompt } from '../models/ISavedPrompt';
import { IUser } from '../models/User';
import { BaseService } from './BaseService';

interface UserPreferences {
    pinnedGPTs: string[];
    personas: IPersona[];
    userPrompts: ISavedPrompt[];
    createdDate: string;
}


export class UserManagementService extends BaseService {
    public getUsersAsync = async (
        accessToken: string,
        pageNumber: number,
        pageSize: number,
        searchTerm: string,
    ): Promise<PaginatedResponse<IUser>> => {
        return await this.getResponseAsync<PaginatedResponse<IUser>>(
            {
                commandPath: `user/getAllUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
                method: 'GET',
            },
            accessToken,
        );
    };

    public getUserRoleByEmail = async (accessToken: string): Promise<IUser> => {
        return await this.getResponseAsync<IUser>(
            {
                commandPath: `user/getUserRoleByEmail`,
                method: 'GET',
            },
            accessToken,
        );
    };



    public updateUserAsync = async (data: IUser, accessToken: string): Promise<IUser> => {
        return await this.getResponseAsync<IUser>(
            {
                commandPath: `user`,
                method: 'PATCH',
                body: data,
            },
            accessToken,
        );
    };

    public updatePinnedGPTS = async (gpts: string[], userId: string, accessToken: string): Promise<any> => {
        const formData = new FormData();
        for (const gpt of gpts) {
            formData.append('gpts', gpt);
        }
        const result = await this.getResponseAsync<IUser>(
            {
                commandPath: `user/updatePinned/${userId}`,
                method: 'POST',
                body: formData,
            },
            accessToken,
        );
        return result;
    };

    public updateUserPersonasAsync = async (
        userId: string,
        data: IPersona[],
        accessToken: string,
    ): Promise<IPersona[]> => {
        return await this.getResponseAsync(
            {
                commandPath: `user/updateUserPersonas/${userId}`,
                method: 'PATCH',
                body: data,
            },
            accessToken,
        );
    };
    public createUserPromptAsync = async (
        userId: string,
        promptText: string,
        gptEndpoint: string,
        isDocumentChat: boolean,
        accessToken: string,
    ): Promise<ISavedPrompt> => {
        const data = { promptText: promptText, gptEndpoint: gptEndpoint, isDocumentChat: isDocumentChat };
        return await this.getResponseAsync(
            {
                commandPath: `user/createUserPrompt/${userId}`,
                method: 'POST',
                body: data,
            },
            accessToken,
        );
    };

    public updateUserPromptAsync = async (
        userId: string,
        data: ISavedPrompt,
        accessToken: string,
    ): Promise<ISavedPrompt> => {
        return await this.getResponseAsync(
            {
                commandPath: `user/updateUserPrompts/${userId}`,
                method: 'PATCH',
                body: data,
            },
            accessToken,
        );
    };

    public deleteUserPromptAsync = async (userId: string, promptId: string, accessToken: string): Promise<object> => {
        return await this.getResponseAsync<object>(
            {
                commandPath: `user/deleteUserPrompt/${userId}`,
                method: 'DELETE',
                body: promptId,
            },
            accessToken,
        );
    };

    public getUserPreferencesAsync = async (userId: string, accessToken: string): Promise<UserPreferences> => {
        return await this.getResponseAsync(
            {
                commandPath: `user/getUserPreferences/${userId}`,
                method: 'GET',
            },
            accessToken,
        );
    };
}
