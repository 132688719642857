import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { customColors, customFonts, ScrollBarStyles } from '../../styles';

const useClasses = makeStyles({
    // header styles
    hWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        textAlign: 'left',
        margin: '30px 40px 10px 40px',
        textOverflow: 'ellipsis',
        maxHeight: '150px',
    },
    hTextWrapper: {
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
    },
    hHeaderText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '20px',
        fontFamily: customFonts.TitleFont,
        color: '#0a5782 !important',
        lineHeight: '25px',
        //marginBottom: '5px',
    },
    hDescriptionText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        fontWeight: '400',
        fontSize: '14px',
        //lineHeight: '1rem',
        textAlign: 'left',
        color: '#000000 !important',
    },
    // ea window styles
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '0', // added this to fix overflow issue from parent with "display: flex"
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        // boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.075)', // replaced the box shadow with a lighter, longer one (for a sleeker, more modern look)
        ...ScrollBarStyles,
    },
    header: {
        ...shorthands.borderBottom('5px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        // backgroundColor: customColors.lightBlueGrayBackground1,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    popoverHeader: {
        ...shorthands.margin('0'),
        paddingBottom: tokens.spacingVerticalXXS,
        fontStyle: 'normal',
        fontWeight: '600',
        fontFamily: customFonts.Lato,
    },
    popover: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...shorthands.padding(tokens.spacingVerticalXXL),
        ...shorthands.gap(tokens.spacingVerticalMNudge),
        width: '398px',
    },
    input: {
        width: '100%',
        border: '2px solid #d1d5db',
        borderRadius: '4px',
    },
    buttons: {
        display: 'flex',
        alignSelf: 'end',
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    alerts: {
        display: 'flex',
        flexDirection: 'column',
        // ...shorthands.margin(0, '72px'),
        ...shorthands.margin(0, '1vw'),
    },
    chat: {
        backgroundColor: 'transparent',
        ...shorthands.margin('0px', '20px'),
        '& > span': {
            fontFamily: customFonts.Lato,
        },
        '&::after': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.margin('-13px'),
            ...shorthands.borderRadius('0'),
        },
        '&::before': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.margin('-13px'),
            ...shorthands.borderRadius('0'),
        },
        '&[aria-selected=true] > span': {
            fontFamily: customFonts.Lato,
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '16px',
            color: customColors.summarizeButtonDarker,
        },
    },
    // choose accelerator styles
    previewThumbnail: {
        width: '108px',
        height: '105px',
        objectFit: 'cover',
        marginBottom: '10px',
        marginRight: '10px',
        borderRadius: '0px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    caDescription: {
        backgroundColor: '#C6D8E954',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        textAlign: 'left',
        margin: '10px 40px 10px 40px',
        textOverflow: 'ellipsis',
        gap: '20px',
        padding: '20px',
        '@media (max-width: 850px)': {
            padding: '10px', // Add padding on smaller screens
            flexDirection: 'column', // Stack items vertically on smaller screens
        },
    },
    caTextWrapper: {
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginLeft: '20px',
    },
    caHeaderText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: customFonts.BarlowCondensed,
        color: '#0a5782 !important',
        lineHeight: '24px',
        marginBottom: '5px',
    },
    caDescriptionText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        fontWeight: '400',
        fontSize: '12px',
        //lineHeight: '1rem',
        textAlign: 'left',
        color: '#000000 !important',
    },
    summaryDescription: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        margin: '30px 40px 10px 40px',
        textOverflow: 'ellipsis',
        maxHeight: '150px',
    },
    summaryHeaderText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '20px',
        fontFamily: customFonts.TitleFont,
        color: '#0a5782 !important',
        lineHeight: '25px',
        //marginBottom: '5px',
    },
    summaryDescriptionText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        fontWeight: '400',
        fontSize: '14px',
        //lineHeight: '1rem',
        textAlign: 'left',
        color: '#000000 !important',
        marginLeft: '20px',
    },
    fieldName: {
        marginBottom: '5px',
        fontSize: '1.5em',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
    },
    iconStyle: {
        marginLeft: '8px',
        transform: 'scale(0.90)',
        cursor: 'pointer',
        color: customColors.summarizeButtonDarker,
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContentContainer: {
        position: 'relative',
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'inline-block',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        gap: '12px',
        borderRadius: '8px',
        ...ScrollBarStyles,
    },
    modalImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'scale-down',
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
    },
    // file attachment styles
    faDescription: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        margin: '10px 20px 10px 20px',
        textOverflow: 'ellipsis',
        maxHeight: '120px',
    },
    faHeaderText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '20px',
        fontFamily: customFonts.TitleFont,
        color: '#0a5782 !important',
        lineHeight: '19.2px',
        marginBottom: '5px',
    },
    faDescriptionText: {
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1rem',
        textAlign: 'left',
        color: '#696969 !important',
        marginLeft: '15px',
    },

    fileList: {
        border: '2px dashed #275B71',
        borderRadius: '5px',
        padding: '10px',
        margin: '20px',
        height: '220px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    fileListTitle: {
        paddingTop: '10px',
        paddingLeft: '30px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
    },
    fileListHeaders: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        textAlign: 'left',
        margin: '10px 20px 10px 20px',
        textOverflow: 'ellipsis',
        maxHeight: '120px',
    },
    spinnerContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(255, 255, 255, 0.4)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addFileWrapper: {
        width: '50px',
    },
    addFileButton: {
        paddingRight: '10px',
        paddingTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonImage: {
        width: '15px',
        height: '15px',
        marginRight: '10px',
    },
    // shared styles
    logo: {
        width: '43px',
        height: '44px',
        objectFit: 'cover',
        marginBottom: '10px',
        marginRight: '10px',
        borderRadius: '0px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    container: {
        display: 'flex',
        gap: '80px', // Space between the two options
        marginTop: '16px',
        marginLeft: '40px',
    },
    option: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    image: {
        width: '100px', // Adjust based on your design
        height: '100px',
    },
    fileImage: {
        width: '70px', // Adjust based on your design
        height: '70px',
    },
    button: {
        marginTop: '8px',
        padding: '8px 12px',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    selectedButton: {
        backgroundColor: '#18516E !important',
        color: 'white',
        border: '1px solid navy',
    },
    // kick of deck part 2 documentProperty styles //////////////////////////////////////////////////
    containerField: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '40px',
        padding: '50px',
        paddingTop: '30px',
        backgroundColor: '#C6D8E954',
        borderRadius: '8px',
        margin: '30px 40px 10px 40px',
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
    fieldGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    fieldLabel: {
        fontWeight: '600',
        fontSize: '15px',
        fontFamily: 'customFonts.TitleFont !important',
        color: '#030917BF !important',
    },
    fieldSubLabel: {
        fontSize: '13px',
        textAlign: 'right',
        color: '#030917BF !important',
    },
    customTextField: {
        fontSize: '14px',
        width: '100%',
        border: '2px solid #d1d5db',
        borderRadius: '4px',
    },
    dropdown: {
        width: '100%',
        border: '2px solid #d1d5db',
        borderRadius: '4px',
    },
    requiredField: {
        color: 'red',
        fontSize: '12px',
        marginTop: '8px',
        textAlign: 'right',
        marginRight: '40px',
    },
    scrollableContainer: {
        maxHeight: '1080px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#ccc transparent',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
});

export default useClasses;
