import { AccountInfo, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FluentProvider } from '@fluentui/react-components';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import MissingEnvVariablesError from './components/views/MissingEnvVariablesError';
import './index.css';
import { AuthHelper } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import React from 'react';
import { getMissingEnvVariables } from './checkEnv';
import { semanticKernelLightTheme } from './styles';
import 'bootstrap/dist/css/bootstrap.min.css';
if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

let container: HTMLElement | null = null;

document.addEventListener('DOMContentLoaded', () => {
    if (!container) {
        container = document.getElementById('root');
        if (!container) {
            throw new Error('Could not find root element');
        }
        const root = ReactDOM.createRoot(container);

        const missingEnvVariables = getMissingEnvVariables();
        const validEnvFile = missingEnvVariables.length === 0;

        let msalInstance: IPublicClientApplication | null = null;
        if (validEnvFile) {
            msalInstance = new PublicClientApplication(AuthHelper.msalConfig);
            //  const loginRequest = {
            //     scopes: ['openid', 'offline_access', 'profile'],
            //   };

            // msalInstance.loginPopup(loginRequest).then((response) => {

            //     if (response) {
            //         msalInstance?.setActiveAccount(response.account);
            //     }
            // });
            void msalInstance.handleRedirectPromise().then((response) => {

                if (response) {
                    msalInstance?.setActiveAccount(response.account);
                }
            });

            window.addEventListener("message", (event) => {

                // check the origin of the data
                let allowedOrigin: string[] = [];
                if (process.env.REACT_APP_AllowedIframeOriginUrl) {
                    allowedOrigin=process.env.REACT_APP_AllowedIframeOriginUrl.split(',').map(origin => origin.trim());
                }
                if (allowedOrigin.includes(event.origin)) {
                     // Sanitize and validate event.data
            if (event.data && typeof event.data === 'object' && 'account' in event.data) {
                const accountData = event.data.account;
                if (accountData && typeof accountData === 'object' && 'username' in accountData) {
                    const a: AccountInfo = accountData as AccountInfo;

                    //const a : AccountInfo = event && event.data && event.data.account?event.data.account:null;
                    /* eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment */
                  //  const a1  =  event.data??null;

                    /* eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
                   // const a: AccountInfo = a1.account ?? null;
                    // attempt SSO
                    msalInstance
                        ?.ssoSilent({
                            account: a,
                        })
                        .then((response) => {
                            msalInstance?.setActiveAccount(response.account);
                            // do something with response
                        })
                        .catch((error) => {
                            // handle errors
                            console.log(error);
                        });
                }else {
                    console.warn('Invalid account data received in event:', event.data);
                }
            } else {
                console.warn('Invalid event data received:', event.data);
            }
        } 
    }); // end of listener
        }

        root.render(
            <React.StrictMode>
                {!validEnvFile && <MissingEnvVariablesError missingVariables={missingEnvVariables} />}
                {validEnvFile && (
                    <ReduxProvider store={store}>
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                        <MsalProvider instance={msalInstance!}>
                            <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
                                <App />
                            </FluentProvider>
                        </MsalProvider>
                    </ReduxProvider>
                )}
            </React.StrictMode>,
        );
    }
});
