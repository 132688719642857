import Select from 'react-select';
import { useEffect, useState } from 'react';
import { RootState } from '../../redux/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Field, makeStyles, FluentProvider, Button, tokens } from '@fluentui/react-components';

import { IADEmployee } from '../../libs/models/ADEmployee';
import { IUserPermission } from '../../libs/models/UserPermission';
import { useUserPermission } from '../../libs/hooks/useUserPermission';
import { removeAlert } from '../../redux/features/app/appSlice';

import _debounce from 'lodash/debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { setUserGPTs } from '../../redux/features/gpt/gptSlice';
import { useGPT } from '../../libs/hooks/useGPT';
import { customFonts } from '../../styles';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        width: '60%',
        margin: '50px auto',
    },
    title: {
        marginBottom: '50px',
        fontSize: '3em',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '1.3',
        color: tokens.colorBrandBackgroundHover,
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        //backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    }
});

export const GPTPermission = () => {
    const classes = useClasses();
    const dispatch = useDispatch();
    const gpt = useGPT();
    const userPermission = useUserPermission();
    let selectedGPT = useSelector((state: RootState) => state.app.selectedGPT);
    let gptId = selectedGPT?.id ?? '';

    if (!selectedGPT) {
        const id = useParams();
        const allGPTs = useSelector((state: RootState) => state.gpts);
        selectedGPT = allGPTs.userGPTs.find((gpt) => gpt.id === id.gptId) ?? null;
        gptId = id.gptId ?? '';
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [adEmployees, setADEmployees] = useState<IADEmployee[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<IUserPermission[]>([]);
    const [selectedContributors, setSelectedContributors] = useState<IUserPermission[]>([]);
    const [selectedReaders, setSelectedReaders] = useState<IUserPermission[]>([]);
    const [selectedContributorOptions, setSelectedContributorOptions] = useState<{ label: string; value: string }[]>([]);
    const [selectedReaderOptions, setSelectedReaderOptions] = useState<{ label: string; value: string }[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const navigate = useNavigate();
    

    const toastAppearance = 2000;
    useEffect(() => {
        // permissions 0 == admin, 1 == contributor, 2 == reader
        const fetchUserPermissions = async () => {
            const fetchedUserPermissions: any = await userPermission.getPermissionAsync(gptId);
            const fetchedUsers = fetchedUserPermissions.map((u: IUserPermission) => ({
                userId: u.userId,
                userName: u.userName,
                name: u.name,
                customGPTId: u.customGPTId,
                customGPTName: u.customGPTName,
                permission: u.permission,
            }));
            
            let selectedContributors = fetchedUsers.filter((u: any) => u.permission === 1);
            const uniqueContributors = Array.from(new Set(selectedContributors.map((u: any) => u.userId)))
                .map((userId) => selectedContributors.find((u: any) => u.userId === userId));
            selectedContributors = uniqueContributors;
            
            let selectedReaders = fetchedUsers.filter((u: any) => u.permission === 2);
            const uniqueReaders = Array.from(new Set(selectedReaders.map((u: any) => u.userId)))
                .map((userId) => selectedReaders.find((u: any) => u.userId === userId));
            selectedReaders = uniqueReaders;

            const fetchedOwerOptions = selectedContributors.map((user: IUserPermission) => ({
                label: user.name,
                value: user.userId,
            }));

            const fetchedReaderOptions = selectedReaders.map((user: IUserPermission) => ({
                label: user.name,
                value: user.userId,
            }));
            setSelectedContributors(selectedContributors);
            setSelectedReaders(selectedReaders);
            setSelectedContributorOptions(fetchedOwerOptions);
            setSelectedReaderOptions(fetchedReaderOptions);
        };
        fetchUserPermissions();
    }, []);

    useEffect(() => {
        dispatch(removeAlert(0));
    }, []);

    useEffect(() => {
        setSelectedUsers([...selectedContributors, ...selectedReaders]);
    }, [selectedContributors, selectedReaders]);

    //const selectedGPT = gpts.find((gpt) => gpt.id === gptId);

    // Function to handle form submission
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await userPermission.createPermissionAsync(gptId, selectedUsers);

            if (response !== 'Create error') {
                const gpts = await gpt.getGPTs() || [];
                dispatch(setUserGPTs({ gpts: gpts }));
                setTimeout(() => {
                    navigate('/manage-gpts');
                }, toastAppearance);
            } else {
                setIsSubmitting(false);
                toast.error('Failed to update user');
            }
            
        } catch (error) {
            setIsSubmitting(false);
            console.error('Error submitting form:', error);
        }
    };

    const loadOptions = _debounce(async (query: string, callback: any) => {
        try {
            if (query.length >= 3) {
                setIsLoading(true);
                await userPermission
                    .getADEmployeeAsync(query)
                    .then((res) => {
                        setADEmployees([...adEmployees, ...res]);
                        const empList = res.map((employee) => ({
                            label: employee.givenName + ' ' + employee.surName + ' ' + employee.userPrincipalName,
                            value: employee.key,
                        }));
                        setOptions(empList);
                        callback(empList);
                    })
                    .catch((error) => {
                        console.error('Error loading options:', error);
                        setIsLoading(false);
                        callback([]);
                    });
            } else {
                setOptions([]);
                callback([]);
            }
        } catch (error) {
            console.error('Error loading options:', error);
            callback([]);
        }
        setIsLoading(false);
    }, 750);

    const handleSelectChange = (type: number) => (value: any) => {
        const idSet = new Set(value.map((x:any) => x.value));
        const contributorPermissions = adEmployees.filter((emps) => idSet.has(emps.key));
        const users: IUserPermission[] = contributorPermissions.map((user) => ({
            userId: user.key + '.' + process.env.REACT_APP_TENANT_ID,
            userName: user.userPrincipalName,
            name: user.givenName + ' ' + user.surName,
            customGPTId: selectedGPT ? selectedGPT.id : '',
            customGPTName: selectedGPT ? selectedGPT.name : '',
            permission: type,
        }));
        if (type === 1) {
            const prevContributors = selectedContributors.filter((contributors) =>
                value.map((x: any) => x.value).includes(contributors.userId),
            );
            setSelectedContributors([...prevContributors, ...users]);
            setSelectedContributorOptions(value);
        } else if (type === 2) {
            const prevReaders = selectedReaders.filter((Contributors) =>
                value.map((x: any) => x.value).includes(Contributors.userId),
            );
            setSelectedReaders([...prevReaders, ...users]);
            setSelectedReaderOptions(value);
        }
    };

    const handleCancel = () => {
        navigate('/manage-gpts');
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>Grant Permission for {selectedGPT?.name}</div>
            <form onSubmit={handleSubmit}>
                <Field label="Select users to grant Contributor permission: " size="large">
                    <Select
                        isMulti
                        value={selectedContributorOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        placeholder="Type to search users..."
                        options={options}
                        isLoading={isLoading}
                        onInputChange={(value) => loadOptions(value, (options: any) => options)}
                        onChange={handleSelectChange(1)} // 1 == contributor
                    />
                </Field>
                <br />
                <Field label="Select users to grant read permission:" size="large">
                    <Select
                        isMulti
                        value={selectedReaderOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        placeholder="Type to search users..."
                        options={options}
                        isLoading={isLoading}
                        onInputChange={(value) => loadOptions(value, (options: any) => options)}
                        onChange={handleSelectChange(2)} // 2 == reader
                    />
                </Field>
                <br />
                <FluentProvider>
                    <Button
                        size="large"
                        appearance="primary"
                        disabled={isSubmitting}
                        type="submit"
                        style={{ marginRight: '10px' }}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                    <Button size="large" appearance="primary" disabled={isSubmitting} onClick={handleCancel}>
                        Cancel
                    </Button>
                </FluentProvider>
                
            </form>
            <ToastContainer autoClose={toastAppearance} />
        </div>
        
    );
};
