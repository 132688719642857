import { Dropdown, Field, Input, Option, Radio, RadioGroup, Text } from '@fluentui/react-components';
import useClasses from '../../EAStyles';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import digitalProtiviti from '../../../../assets/protiviti-template-icon.png';
import standardProtiviti from '../../../../assets/standard-template-icon.png';
import wordIcon from '../../../../assets/word-icon.png';
import excelIcon from '../../../../assets/excel-icon.png';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { EADropdownValue, EADropdownValues } from '../../../../libs/models/EADropdownValues';
import { useEAPermission } from '../../../../libs/hooks/useEAService';

interface DocumentPropertyProps {
    selectedAccelTypeOption: string | (readonly string[] & string) | undefined;
}

export const DocumentProperty: React.FC<DocumentPropertyProps> = ({selectedAccelTypeOption}) => {
    const classes = useClasses();
    const ea = useEAPermission();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const engagement = conversations[selectedId].engagementAccelerator;

    const { control } = useFormContext();
    const [dropDownValues, setDropDownValues] = useState<EADropdownValues>({} as EADropdownValues);

    useEffect(() => {
        const fetchDropdownValues = async () => {
            try {
                await ea
                    .getEADropdownValuesAsync()
                    .then((res: any) => {
                        const values: EADropdownValues = {
                            industry: res.industry || [],
                            solution: res.solution || [],
                            country: res.country || [],
                            region: res.region || [],
                        };
                        setDropDownValues(values);
                    });
            } catch (error) {
                console.error('Failed to fetch dropdown values:', error);
            }
        };
        fetchDropdownValues();
    }, []);

    const industryOptions = dropDownValues.industry || [];
    const industryVal = engagement?.industry || ''; //if engagement is selected then pull value from microservice else set to "" empty
    const industrySelectionOptions = industryOptions.map((model: EADropdownValue) => ({ key: model.value, header: model.value }));

    const solutionOptions = dropDownValues.solution || [];
    const solutionVal = engagement?.solution || '';
    const solutionSelectionOptions = solutionOptions.map((model: EADropdownValue) => ({ key: model.value, header: model.value }));

    const regionOptions = dropDownValues.region || [];
    const regionVal = engagement?.region || '';
    const regionSelectionOptions = regionOptions.map((model: EADropdownValue) => ({ key: model.value, header: model.value }));


    //TODO: Update once we have Country, Third Party, and TeamSite options
    const countryOptions = ['Canada', 'India', 'United States', 'France Adamantia Advisory SAS', 'France Adamantia Services SAS'];
    const countryVal = engagement?.country || '';
    const countrySelectionOptions = countryOptions.map((model: string) => ({ key: model, header: model }));

    const thirdPartyOptions = ['Kick off deck', 'Onboarding brief flow', 'Project check list'];
    const thirdPartyToolVal = engagement?.thirdPartyTools || '';
    const thirdPartySelectionOptions = thirdPartyOptions.map((model: string) => ({ key: model, header: model }));

    const teamsSiteOptions = ['Kick off deck', 'Onboarding brief flow', 'Project check list'];
    const teamsSiteVal = engagement?.teamsSite || '';
    const teamsSiteSelectionOptions = teamsSiteOptions.map((model: string) => ({ key: model, header: model }));

    return (
        <>
            {selectedAccelTypeOption === 'kickoffdeck' || selectedAccelTypeOption === 'checklist' ? (
                <section className={classes.summaryDescription}>
                    <Text className={classes.summaryHeaderText}>
                        2. Review and edit the following information that was auto populated.
                    </Text>
                    <Text className={classes.summaryDescriptionText}>
                        Sources of the auto populated information are specified above each field.
                    </Text>
                </section>
            ) : (
                ''
            )}
            {selectedAccelTypeOption === 'kickoffdeck' ? (
                <>
                    <div className={classes.containerField}>
                        {/* Left Column */}
                        <div className={classes.fieldGroup}>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>INDUSTRY*</Text>
                                        <Text className={classes.fieldSubLabel}>* iManage</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="industry"
                                    control={control}
                                    rules={{
                                        validate: (value) =>
                                            value !== '' || industryVal !== '' || 'Industry selection is required', // Additional custom validation
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Select Industry"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [industryVal]}
                                            value={
                                            industrySelectionOptions.find(
                                                (option: { key: string }) => option.key === value,
                                            )?.header || industryVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                            if (data.selectedOptions) {
                                                onChange(data.selectedOptions[0]);
                                            }
                                            }}
                                        >
                                            {industrySelectionOptions.map(
                                            (option: {
                                                key: React.Key | null | undefined;
                                                header: string | undefined;
                                            }) => (
                                                <Option
                                                key={
                                                    option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : ''
                                                }
                                                text={String(option.header)}
                                                value={
                                                    option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : undefined
                                                }
                                                >
                                                {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>SOLUTION*</Text>
                                        <Text className={classes.fieldSubLabel}>* iManage</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="solution"
                                    control={control}
                                    rules={{
                                        validate: (value) =>
                                            value !== '' || solutionVal !== '' || 'Solution selection is required', // Additional custom validation
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Add your solution"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [solutionVal]}
                                            value={
                                                solutionSelectionOptions.find(
                                                    (option: { key: string }) => option.key === value,
                                                )?.header || solutionVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                                if (data.selectedOptions) {
                                                    onChange(data.selectedOptions[0]);
                                                }
                                            }}
                                        >
                                            {solutionSelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : undefined
                                                    }
                                                >
                                                    {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>
                        </div>

                        {/* Right Column */}
                        <div className={classes.fieldGroup}>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>THIRD PARTY TOOLS*</Text>
                                        <Text className={classes.fieldSubLabel}>* SOW then Proposal</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="thirdPartyTools"
                                    control={control}
                                    rules={{
                                        validate: (value) =>
                                            value !== '' || thirdPartyToolVal !== '' || 'Third Party Tool selection is required', // Additional custom validation
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="NA"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [thirdPartyToolVal]}
                                            value={
                                                thirdPartySelectionOptions.find(
                                                    (option: { key: string }) => option.key === value,
                                                )?.header || thirdPartyToolVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                                if (data.selectedOptions) {
                                                    onChange(data.selectedOptions[0]);
                                                }
                                            }}
                                        >
                                            {thirdPartySelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : undefined
                                                    }
                                                >
                                                    {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>DEPARTMENTS(S) IN SCOPE*</Text>
                                        <Text className={classes.fieldSubLabel}>* SOW</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="departmentInScope"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className={classes.customTextField} // Apply custom styles here
                                            placeholder="Enter departments"
                                        />
                                    )}
                                />
                            </Field>
                        </div>

                        {/* Required Fields Message */}
                    </div>
                    <div className={classes.requiredField}>* All fields required</div>
                </>
            ) : (
                ''
            )}
            {selectedAccelTypeOption === 'checklist' ? (
                <>
                    <div className={classes.containerField}>
                        {/* Left Column */}
                        <div className={classes.fieldGroup}>
                            {/* Contractor Information */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div className={classes.fieldLabel}>CONTRACTOR INFORMATION</div>
                                <Controller
                                    name="contractorInformation"
                                    control={control}
                                    defaultValue="yes"
                                    render={({ field }) => (
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <RadioGroup
                                                {...field}
                                                style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}
                                            >
                                                <Radio value="yes" label="Yes" />
                                                <Radio value="no" label="No" />
                                            </RadioGroup>
                                        </div>
                                    )}
                                />
                            </div>

                            {/* Background Check */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div className={classes.fieldLabel}>BACKGROUND CHECK</div>
                                <Controller
                                    name="backgroundCheck"
                                    control={control}
                                    defaultValue="yes"
                                    render={({ field }) => (
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <RadioGroup
                                                {...field}
                                                style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}
                                            >
                                                <Radio value="yes" label="Yes" />
                                                <Radio value="no" label="No" />
                                            </RadioGroup>
                                        </div>
                                    )}
                                />
                            </div>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>COUNTRY</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="country"
                                    control={control}
                                    defaultValue={countryVal}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Pulls in country/enter country"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [countryVal]}
                                            value={
                                                countrySelectionOptions.find(
                                                    (option: { key: string }) => option.key === value,
                                                )?.header || countryVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                                if (data.selectedOptions) {
                                                    onChange(data.selectedOptions[0]);
                                                }
                                            }}
                                        >
                                            {countrySelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : undefined
                                                    }
                                                >
                                                    {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>REGION</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="region"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Pulls in region/enter region"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [regionVal]}
                                            value={
                                            regionSelectionOptions.find(
                                                (option: { key: string }) => option.key === value,
                                            )?.header || regionVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                            if (data.selectedOptions) {
                                                onChange(data.selectedOptions[0]);
                                            }
                                            }}
                                        >
                                            {regionSelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                        ? String(option.key)
                                                        : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                        ? String(option.key)
                                                        : undefined
                                                    }
                                                    >
                                                    {option.header}
                                                    </Option>
                                                ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>INDUSTRY*</Text>
                                        <Text className={classes.fieldSubLabel}>* iManage then web</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="industry"
                                    control={control}
                                    defaultValue={industryVal}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Select Industry"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [industryVal]}
                                            value={
                                            industrySelectionOptions.find(
                                                (option: { key: string }) => option.key === value,
                                            )?.header || industryVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                            if (data.selectedOptions) {
                                                onChange(data.selectedOptions[0]);
                                            }
                                            }}
                                        >
                                            {industrySelectionOptions.map(
                                            (option: {
                                                key: React.Key | null | undefined;
                                                header: string | undefined;
                                            }) => (
                                                <Option
                                                key={
                                                    option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : ''
                                                }
                                                text={String(option.header)}
                                                value={
                                                    option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : undefined
                                                }
                                                >
                                                {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>
                        </div>

                        {/* Right Column */}
                        <div className={classes.fieldGroup}>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>DELIVERY CENTER</Text>
                                        <Text className={classes.fieldSubLabel}>* iManage then SOW</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="deliveryCenter"
                                    control={control}
                                    rules={{
                                        validate: (value) =>
                                            value !== '' || engagement?.deliveryCenter !== '' || 'Delivery Center is required', // Additional custom validation
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className={classes.customTextField} // Apply custom styles here
                                            placeholder="NA"
                                        />
                                    )}
                                />
                            </Field>

                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>ECOSYSTEM PARTNER</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="ecosystemPartner"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className={classes.customTextField} // Apply custom styles here
                                            placeholder="NA"
                                        />
                                    )}
                                />
                            </Field>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>THIRD PARTY TOOL</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="thirdPartyTools"
                                    control={control}
                                    defaultValue={thirdPartyToolVal}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="NA"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [thirdPartyToolVal]}
                                            value={
                                                thirdPartySelectionOptions.find(
                                                    (option: { key: string }) => option.key === value,
                                                )?.header || thirdPartyToolVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                                if (data.selectedOptions) {
                                                    onChange(data.selectedOptions[0]);
                                                }
                                            }}
                                        >
                                            {thirdPartySelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : undefined
                                                    }
                                                >
                                                    {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>CLIENT DATA REQUIREMENTS</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="clientDataRequirements"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className={classes.customTextField} // Apply custom styles here
                                            placeholder="NA"
                                        />
                                    )}
                                />
                            </Field>
                            <Field
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Text className={classes.fieldLabel}>TEAMS SITE</Text>
                                        <Text className={classes.fieldSubLabel}>* iManage</Text>
                                    </div>
                                }
                            >
                                <Controller
                                    name="teamsSite"
                                    control={control}
                                    defaultValue={teamsSiteVal}
                                    render={({ field: { value, onChange } }) => (
                                        <Dropdown
                                            placeholder="Add teams site"
                                            className={classes.dropdown}
                                            selectedOptions={value ? [value] : [teamsSiteVal]}
                                            value={
                                                teamsSiteSelectionOptions.find(
                                                    (option: { key: string }) => option.key === value,
                                                )?.header || teamsSiteVal
                                            }
                                            onOptionSelect={(_event, data) => {
                                                if (data.selectedOptions) {
                                                    onChange(data.selectedOptions[0]);
                                                }
                                            }}
                                        >
                                            {teamsSiteSelectionOptions.map(
                                                (option: {
                                                    key: React.Key | null | undefined;
                                                    header: string | undefined;
                                                }) => (
                                                    <Option
                                                    key={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : ''
                                                    }
                                                    text={String(option.header)}
                                                    value={
                                                        option.key !== null && option.key !== undefined
                                                            ? String(option.key)
                                                            : undefined
                                                    }
                                                >
                                                    {option.header}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    )}
                                />
                            </Field>
                        </div>

                        {/* Required Fields Message */}
                    </div>
                    {/* <div className={classes.requiredField}>* All fields required</div> */}
                </>
            ) : (
                ''
            )}
            {selectedAccelTypeOption === 'kickoffdeck' ? (
                <>
                    <section className={classes.summaryDescription}>
                        <Text className={classes.summaryHeaderText}>3. Select the Power Point deck format</Text>
                        <Text className={classes.summaryDescriptionText}>
                            Choose the design template for your projects. Choose whether you want the deck in “Standard
                            Protiviti format” or “Protiviti Digital format”.
                        </Text>
                    </section>
                    <Controller
                        name="powerPointFormat"
                        control={control}
                        defaultValue=""
                        rules={{
                            validate: (value) =>
                                value !== '' || 'Power point format selection is required', // Additional custom validation
                        }}
                        render={({ field }) => (
                            <div className={classes.container}>
                                {/* Standard Format */}
                                <div className={classes.option}>
                                    <img
                                        src={standardProtiviti}
                                        alt="Standard Protiviti Format"
                                        className={classes.image}
                                    />
                                    <button
                                        type="button"
                                        className={`${classes.button} ${field.value === 'standard' ? classes.selectedButton : ''}`}
                                        onClick={() => field.onChange('standard')}
                                    >
                                        Standard Protiviti Format
                                    </button>
                                </div>

                                {/* Digital Format */}
                                <div className={classes.option}>
                                    <img
                                        src={digitalProtiviti}
                                        alt="Protiviti Digital Format"
                                        className={classes.image}
                                    />
                                    <button
                                        type="button"
                                        className={`${classes.button} ${field.value === 'digital' ? classes.selectedButton : ''}`}
                                        onClick={() => field.onChange('digital')}
                                    >
                                        Protiviti Digital Format
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                </>
            ) : (
                ''
            )}
            {selectedAccelTypeOption === 'checklist' ? (
                <>
                    <section className={classes.summaryDescription}>
                        <Text className={classes.summaryHeaderText}>3. Select your file type</Text>
                        <Text className={classes.summaryDescriptionText}>
                            Select the type of file that you would like to create
                        </Text>
                    </section>
                    <Controller
                        name="fileFormat"
                        control={control}
                        defaultValue=""
                        rules={{
                            validate: (value) =>
                                value !== '' || 'File format selection is required', // Additional custom validation
                        }}
                        render={({ field }) => (
                            <div className={classes.container}>
                                {/* Standard Format */}
                                <div className={classes.option}>
                                    <img src={wordIcon} alt="Word Format" className={classes.fileImage} />
                                    <button
                                        type="button"
                                        className={`${classes.button} ${field.value === 'standard' ? classes.selectedButton : ''}`}
                                        onClick={() => field.onChange('standard')}
                                    >
                                        Select Word
                                    </button>
                                </div>

                                {/* Digital Format */}
                                <div className={classes.option}>
                                    <img src={excelIcon} alt="Excel Format" className={classes.fileImage} />
                                    <button
                                        type="button"
                                        className={`${classes.button} ${field.value === 'digital' ? classes.selectedButton : ''}`}
                                        onClick={() => field.onChange('digital')}
                                    >
                                        Select Excel
                                    </button>
                                </div>
                            </div>
                        )}
                    />
                </>
            ) : (
                ''
            )}
        </>
    );
};
