import { Button, Input } from '@fluentui/react-components';
import { AddCircleRegular, DeleteFilled } from '@fluentui/react-icons';
import { Controller, useFieldArray } from 'react-hook-form';
import { customColors } from '../../styles';

export const NestedItemsFieldArray = ({
    control,
    name,
    isFaq,
    field1,
    field2,
}: {
    control: any;
    name: string;
    isFaq?: boolean;
    field1: string;
    field2: string;
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id} style={{ marginBottom: '8px', marginRight: '8px' }}>
                    <Controller
                        name={`${name}.${index}.${field1}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                style={{
                                    borderRadius: 0,
                                    marginRight: '8px',
                                    border: `2px solid ${customColors.headerLightGray}`,
                                }}
                                placeholder={`Insert ${field1}`}
                            />
                        )}
                    />
                    <Controller
                        name={`${name}.${index}.${field2}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                style={{
                                    borderRadius: 0,
                                    marginRight: '8px',
                                    border: `2px solid ${customColors.headerLightGray}`,
                                }}
                                placeholder={`Insert ${field2}`}
                            />
                        )}
                    />
                    {isFaq && (
                        <DeleteFilled
                            type="button"
                            onClick={() => remove(index)}
                            style={{ fontSize: '30px', color: customColors.headerLightGray, marginLeft: '8px' }}
                        />
                    )}
                </div>
            ))}
            {isFaq && fields.length < 6 && (
                <Button
                    appearance="subtle"
                    icon={<AddCircleRegular />}
                    onClick={() => append({ field1: '', field2: '' })}
                    shape="square"
                    style={{ position: 'relative', minWidth: 0, padding: '0px' }}
                >
                    {name === 'faQs' ? 'Add FAQ' : 'Add Link'}
                </Button>
            )}
        </>
    );
};
