import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRampOG: BrandVariants = {
    10: '#060103',
    20: '#261018',
    30: '#431426',
    40: '#591732',
    50: '#701A3E',
    60: '#861F4B',
    70: '#982C57' /*This is it*/,
    80: '#A53E63',
    90: '#B15070',
    100: '#BC627E',
    110: '#C6748B',
    120: '#CF869A',
    130: '#D898A8',
    140: '#E0AAB7',
    150: '#E8BCC6',
    160: '#EFCFD6',
};

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#010305',
    20: '#0E1922',
    30: '#0E283B',
    40: '#193449',
    50: '#284054',
    60: '#374D60',
    70: '#46596B',
    80: '#556777',
    90: '#647483',
    100: '#738290',
    110: '#838F9C',
    120: '#929EA9',
    130: '#A3ACB6',
    140: '#B3BBC3',
    150: '#C3C9D0',
    160: '#D4D9DD',
};

export const customColors = {
    lightBlueGrayBackground1: '#F3F5F6',
    lightBlueGrayBackground2: '#F7F9FA',
    lightBlueGrayBackground3: '#F3F5F7',
    botMessageBorder: '#D0D0D0',
    userMessageBackground1: '#DBE8F3',
    userMessageBackground2: '#DEE8F5',
    userMessageBackground3: '#DDE7F5',
    userMessageBackground4: '#E1EBF3',
    userMessageBackground5: '#d9effa',
    userMessageBackground6: '#e3f2fa',
    summarizeButtonHover: '#083d5b',
    summarizeButton: '#0a5782',
    summarizeButtonDarker: '#004068',
    summarizeButtonHoverLighter: '#1c618c',
    newChatHoverLightBlue: '#92ACBE',
    protivitiOrange: '#F6871F',
    protivitiDarkBlue: '#004068',
    darkNavy: '#00294C',
    lightGray: '#D3DBE3',
    white: '#FFFFFF',
    headerLightGray: '#979797',
    warningRed: '#FF4D4F',
};

export const customFonts = {
    Lato: 'Lato, sans-serif',
    TitleFont: 'Barlow Condensed, sans-serif',
    Barlow: 'Barlow, sans-serif',
    BarlowCondensed: 'Barlow Condensed, sans-serif',
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#C3C9D0',
    // uncomment this to use Lato EVERYWHERE
    // fontFamilyBase: 'Lato, sans-serif',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#5A7594',
    // fontFamilyBase: 'Lato, sans-serif',
};

export const customTokens = themeToTokensObject(semanticKernelDarkTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 1000px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (min-width: 1000px) and (max-width: 1280px)': style };
    },
};

const hexToRgb = (hex: string): string => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(${hexToRgb(customColors.protivitiDarkBlue)}, 0.8)`,
            visibility: 'hidden',
            cursor: 'grab',
        },
        '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'hidden',
        },
    },
    '&:active': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(${hexToRgb(customColors.protivitiDarkBlue)}, 0.8)`,
            visibility: 'hidden',
            cursor: 'grabbing',
        },
        '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'hidden',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: 'auto',
        ...ScrollBarStyles,
    },
    outlineButton: {
        fontFamily: customFonts.Lato,
        backgroundColor: 'white',
        ...shorthands.borderRadius('0px'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        color: customColors.protivitiDarkBlue,
        fontWeight: 'semibold',
        ...shorthands.transition('all', '0.3s', 'ease'),
        ':hover': {
            backgroundColor: customColors.protivitiDarkBlue,
            color: 'white',
            ':active': {
                backgroundColor: customColors.protivitiOrange,
                color: 'white',
            },
        },
        ':active': {
            backgroundColor: customColors.protivitiOrange,
            color: 'white',
            ...shorthands.transition('all', '0s'),
        },
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
});
