import {
    Avatar,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import {
    AccessibilityCheckmarkRegular,
    ArrowReplyFilled,
    DeleteRegular,
    EditRegular,
    MoreVerticalFilled,
} from '@fluentui/react-icons';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGPT } from '../../libs/hooks/useGPT';
import { useUserPermission } from '../../libs/hooks/useUserPermission';
import { IGPT } from '../../libs/models/GPT';
import {
    changeSelectedGPT,
    showEditGPTPage,
    showManageGPTsPage,
    showManagePermissionPage,
    showRegisterPage,
    showUserManagementPage,
    updateGPTList,
} from '../../redux/features/app/appSlice';
import { customFonts } from '../../styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    card: {
        width: '400px',
        height: '170px',
        ...shorthands.margin('10px'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F7F7F8',
        ...shorthands.borderLeft('5px', 'solid', '#D9D9D9'),
        ...shorthands.transition('backgroundColor', '0.3s', 'ease-in-out'),

        '@media screen and (max-width: 1200px)': {
            width: `${parseInt('300px', 10) * 0.9}px`,
            height: `${parseInt('150px', 10) * 0.9}px`,
        },
    },
    image: {
        width: '72px',
        height: '72px',
        objectFit: 'cover',
        marginBottom: '10px',
        marginRight: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    description: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '10px',
        '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
        },
    },
    headerText: {
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '20px',
        fontFamily: customFonts.TitleFont,
        lineHeight: '19.2px',
        marginBottom: '5px',
    },

    descriptionText: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#696969',
        textAlign : 'left',
        lineHeight: '1.5', 
        wordWrap: 'break-word', 
        '@media (max-width: 768px)': {
            fontSize: '8.1px', 
            textAlign: 'center', 
            position: 'relative', 
            top: '-2.5px', 
        },
    },
    softDeleteButton: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        backgroundColor: 'white',
        color: '#566777',
        fontSize: '14px',
        '&:hover': {
            color: 'white',
            backgroundColor: '#566777',
        },
    },
    moreButton: {
        position: 'absolute',
        top: '10px',
        right: '5px',
        ...shorthands.border('none'),
        backgroundColor: 'transparent',
        ...shorthands.padding('5px', '10px'),
        '&:hover': {
            textDecoration: 'underline',
            WebkitTextDecorationColor: '#1976D2',
        },
    },
    hardDeleteButton: {
        color: 'red',
        backgroundColor: 'white',
        transition: 'all 0.3s ease',
        position: 'absolute',
        bottom: '10px',
        right: '50px',
        '&:hover': {
            color: 'white',
            backgroundColor: 'red',
        },
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

interface ManageGPTTileProps {
    gpt: IGPT;
    userRole: string | null;
}

export const ManageGPTTile: React.FC<ManageGPTTileProps> = ({ gpt, userRole }) => {
    const classes = useStyles();
    const editGPT = useGPT();
    const [blobUrl, setBlobUrl] = useState<string>('');

    useEffect(() => {
        const fetchBlobUrl = async () => {
            try {
                if(gpt?.gptIconFileName){
                    const url = process.env.REACT_APP_CDN_IMAGE_URL + gpt.gptIconFileName;
                    setBlobUrl(url);
                }
            } catch (error) {
                console.error('Error fetching SAS URL:', error);
            }
        };
    
        fetchBlobUrl();
    }, [gpt.gptIconFileName]);

    
    const [published, setPublish] = useState(gpt.isRegistered);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isInputValid, setIsInputValid] = useState(false);
    const [gptName, setGptName] = useState(gpt.name);
    const editUserPermissions = useUserPermission();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    const handleDelete = async (hardDelete: boolean) => {
        await editGPT.deleteGPT(gpt.id, hardDelete);
        const gptPermissions = await editUserPermissions.getPermissionAsync(gpt.id);
        if (gptPermissions) {
            await editUserPermissions.deleteGPTPermissionsAsync(gpt.id);
        }
        dispatch(updateGPTList());
        setIsDeleting(false);
    };

    const handleHardDeleteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setGptName(inputValue);
        setIsInputValid(inputValue.trim() === gpt.name && userRole === '0');
    };

    const validateHardDeleteInput = async () => {
        setIsDeleting(true);
        await handleDelete(true);
        toast.success(`${gptName} has been deleted permanently.`, {
            className: classes.toastMessage,
        });
        setIsDeleting(false);
        closeDialog();
    };

    const handlePublish = () => {
        gpt.isRegistered = !gpt.isRegistered;
        setPublish(gpt.isRegistered);
        void editGPT.updateGPT(gpt, undefined);
    };

    const handleManagePermission = () => {
        navigate(`/gptPermission/${gpt.id}`);
        dispatch(changeSelectedGPT(gpt));
        dispatch(showManagePermissionPage(true));
        dispatch(showUserManagementPage(false));
        dispatch(showRegisterPage(false));
        dispatch(showManageGPTsPage(false));
        dispatch(showEditGPTPage(false));
    };

    const handleEdit = () => {
        navigate(`/editGPTWindow/${gpt.id}`);
        dispatch(changeSelectedGPT(gpt));
        dispatch(showEditGPTPage(true));
        dispatch(showManageGPTsPage(false));
        dispatch(showUserManagementPage(false));
        dispatch(showManagePermissionPage(false));
        dispatch(showRegisterPage(false));
    };

    return (
        <Card className={classes.card}>
            <div className={classes.description}>
                <Avatar
                    className={classes.image}
                    aria-label="GPT Icon"
                    image={{
                        src: gpt?.gptIconFileName ? blobUrl: gpt.cardImageURL,
                    }}
                />

                {/**<Image src={gpt.cardImageURL} className={classes.image} alt={`${gpt.name} icon`} />**/}
                <div
                    style={{
                        marginLeft: '10px !important',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignContent: 'start',
                    }}
                >
                    <Text className={classes.headerText}>
                        {gpt.name}
                        {gpt.isDeleted && userRole === '0' && (
                            <Text weight="semibold" size={400} style={{ color: 'red' }}>
                                {' '}
                                (soft deleted)
                            </Text>
                        )}
                    </Text>

                    <Text className={classes.descriptionText}>{gpt.cardShortDescription}</Text>
                </div>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            appearance="transparent"
                            icon={<MoreVerticalFilled />}
                            aria-label="More options"
                            className={classes.moreButton}
                        />
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <Button icon={<AccessibilityCheckmarkRegular />} onClick={handleManagePermission}>
                                Manage Permissions
                            </Button>
                            <Button icon={<EditRegular />} onClick={handleEdit}>
                                Edit GPT
                            </Button>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                {/* Hard delete - (Admin only) */}
                {userRole === '0' && (
                    <Dialog open={isDialogOpen}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                title="Hard Delete"
                                appearance="outline"
                                className={classes.hardDeleteButton}
                                icon={<DeleteRegular />}
                                onClick={openDialog}
                            />
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogTitle
                                style={{
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    color: '#333',
                                    marginBottom: '16px',
                                }}
                            >
                                Are you absolutely sure?
                            </DialogTitle>
                            <DialogBody
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '20px',
                                    backgroundColor: '#f9f9f9',
                                    borderRadius: '8px',
                                }}
                            >
                                <p
                                    style={{
                                        color: '#6',
                                        lineHeight: '1.5',
                                    }}
                                >
                                    Please read the following carefully:
                                </p>
                                <ul
                                    style={{
                                        listStyleType: 'circle',
                                        marginLeft: '20px',
                                    }}
                                >
                                    <li style={{ marginBottom: '10px' }}>
                                        If any users have any chat sessions associated with the GPT, those chat sessions
                                        will be <u>permanently deleted</u> along with the GPT.
                                    </li>
                                    <li>This action cannot be undone.</li>
                                </ul>
                            </DialogBody>
                            <DialogBody style={{ marginTop: '20px' }}>
                                <DialogTitle style={{ fontStyle: 'italic', fontWeight: '400', fontSize: '16px' }}>
                                    Type "{gpt.name}" to confirm:{' '}
                                </DialogTitle>
                                <Input
                                    style={{ width: '250px' }}
                                    placeholder="Insert GPT name to delete"
                                    onChange={handleHardDeleteInputChange}
                                ></Input>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary" onClick={closeDialog}>
                                            Cancel
                                        </Button>
                                    </DialogTrigger>
                                    <DialogTrigger action="close" disableButtonEnhancement>
                                        <Button
                                            appearance="primary"
                                            style={{ transition: 'all 0.3s ease', backgroundColor: 'red' }}
                                            onClick={validateHardDeleteInput}
                                            disabled={!isInputValid || isDeleting}
                                        >
                                            {isDeleting ? 'Deletion in progress...' : 'Delete permanently'}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                )}
                {/*Soft delete */}
                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            title="Soft Delete"
                            appearance="outline"
                            icon={<DeleteRegular />}
                            className={classes.softDeleteButton}
                            disabled={gpt.isDeleted}
                        />
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Are you sure you want to delete {gpt.name}?</DialogTitle>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Cancel</Button>
                                </DialogTrigger>
                                <DialogTrigger action="close" disableButtonEnhancement>
                                    <Button
                                        appearance="primary"
                                        style={{ transition: 'all 0.3s ease' }}
                                        onClick={() => handleDelete(false)}
                                    >
                                        Delete
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '15px',
                        backgroundColor: 'transparent',
                        fontWeight: '600',
                        fontSize: '16px',
                        fontFamily: customFonts.TitleFont,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button appearance="primary" icon={<ArrowReplyFilled />} onClick={handlePublish}>
                        {published ? 'Unpublish' : 'Publish'}
                    </Button>
                </div>
            </div>
        </Card>
    );
};
