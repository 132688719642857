import React, { useState, useEffect } from 'react';


const calculateTimeAgo = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return 'Just now';
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    }
};


export const TimeAgo: React.FC<{ dateString: string }> = ({ dateString }) => {
    const [formattedTime, setFormattedTime] = useState(calculateTimeAgo(dateString));

    useEffect(() => {
        setFormattedTime(calculateTimeAgo(dateString));
        const intervalId = setInterval(() => {
            setFormattedTime(calculateTimeAgo(dateString));
        }, 60000); 
        
        return () => clearInterval(intervalId);
    }, [dateString]);

    return <>{formattedTime}</>;
};


export const formatDate = (dateString: string) => {
    return calculateTimeAgo(dateString);
};