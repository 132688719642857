import { Avatar, Dropdown, Input, Option, Text } from '@fluentui/react-components';
import useClasses from '../../EAStyles';
import ImageScaleButton from '../../../chat/controls/ImageScaleButton';

import blank from '../../../../assets/blank.png';
import kickoffDeck_preview from '../../../../assets/kickoffdeck_preview.png';
import onboarding_preview from '../../../../assets/onboarding_preview.png';
import checklist_preview from '../../../../assets/checklist_preview.png';
import kickoffDeck from '../../../../assets/kickoffdeck.png';
import onboarding from '../../../../assets/onboarding.png';
import checklist from '../../../../assets/checklist.png';
import wordIcon from '../../../../assets/icons8-microsoft-word-2019-48.png';
import pptIcon from '../../../../assets/icons8-microsoft-powerpoint-2019-48.png';
import excelIcon from '../../../../assets/icons8-microsoft-excel-2019-48.png';

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface ChooseAcceleratorProps {
    selectedAccelTypeOption: string | (readonly string[] & string) | undefined;
    handleAccelTypeOptionSelect: (option: string | undefined) => void;
}

export const ChooseAccelerator: React.FC<ChooseAcceleratorProps> = ({
    selectedAccelTypeOption,
    handleAccelTypeOptionSelect,
}) => {
    const classes = useClasses();

    const { control } = useFormContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState(blank);
    const [selectedAccelerator, setSelectedAccelerator] = useState('');

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const options = [
        { key: 'kickoffdeck', text: 'Kick off deck', imageSrc: pptIcon },
        { key: 'onboarding', text: 'Internal Onboarding Brief', imageSrc: wordIcon },
        { key: 'checklist', text: 'Project Check List', imageSrc: excelIcon },
    ];

    useEffect(() => {
        switch (selectedAccelTypeOption) {
            case 'onboarding':
                setSelectedPreview(onboarding_preview);
                setSelectedAccelerator(onboarding);
                break;
            case 'kickoffdeck':
                setSelectedPreview(kickoffDeck_preview);
                setSelectedAccelerator(kickoffDeck);
                break;
            case 'checklist':
                setSelectedPreview(checklist_preview);
                setSelectedAccelerator(checklist);
                break;
            default:
                setSelectedPreview(blank);
                setSelectedAccelerator('');
                break;
        }
    }, [selectedAccelTypeOption]);

    const ModalComponent = ({
        isOpen,
        toggleModal,
        children,
    }: {
        isOpen: boolean;
        toggleModal: () => void;
        children: React.ReactNode;
    }) => {
        if (!isOpen) return null;
        return (
            <div className={classes.modalOverlay}>
                <div className={classes.modalContentContainer} onClick={(e) => e.stopPropagation()}>
                    <div style={{ position: 'absolute', top: '8px', right: '5px', objectFit: 'contain' }}>
                        <ImageScaleButton iconType="minimize" onClick={toggleModal} />
                    </div>
                    {children}
                </div>
            </div>
        );
    };

    const ImageDisplayComponent = () => (
        <img
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            src={selectedAccelerator}
            className={classes.modalImage}
        />
    );

    return (
        <>
            <div className={classes.caDescription}>
                <div className={selectedAccelerator ? classes.pointerCursor : ''}>
                    <Avatar
                        className={classes.previewThumbnail}
                        aria-label="logo"
                        image={{ src: selectedPreview }}
                        onClick={selectedAccelerator ? toggleModal : undefined}
                    />
                    <div style={{ fontSize: '12px', textAlign: 'left' }}>Document Preview</div>
                </div>
                <div className={classes.caTextWrapper}>
                    <Text className={classes.caHeaderText}>CHOOSE YOUR ACCELERATOR</Text>
                    <Text className={classes.caDescriptionText}>
                        Choose the type of document you would like to build
                    </Text>
                    <div style={{ paddingTop: '20px' }}>
                        <Controller
                            name="acceleratorType"
                            control={control}
                            defaultValue={''}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <div>
                                    <Dropdown
                                        className={classes.dropdown}
                                        placeholder="Select Document"
                                        value={selectedAccelTypeOption}
                                        onOptionSelect={(_, data) => {
                                            if (data.optionValue) {
                                                onChange(data.optionValue);
                                                handleAccelTypeOptionSelect(data.optionValue);
                                            }
                                        }}
                                    >
                                        {options.map((option) => (
                                            <Option key={option.key} value={option.key} text={option.text}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={option.imageSrc}
                                                        alt={option.text}
                                                        style={{ width: 20, height: 20, marginRight: 8 }}
                                                    />
                                                    {option.text}
                                                </div>
                                            </Option>
                                        ))}
                                    </Dropdown>
                                    {error && <p style={{ color: 'red', fontSize: '12px' }}>{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                </div>
                {selectedAccelTypeOption === 'checklist' ? (
                    <div className={classes.caTextWrapper}>
                        <Text className={classes.caHeaderText}>ADD YOUR ITRAC CODE</Text>
                        <Text className={classes.caDescriptionText}>
                            Entering your iTrac code allows the AI to pull relevant information and files from iManage.
                            <br />
                            The code will not be billed.
                        </Text>
                        <div style={{ paddingTop: '0px' }}>
                            <Input className={classes.input} appearance="outline" />
                        </div>
                    </div>
                ) : null}
            </div>
            <section className={classes.summaryDescription}>
                <Text className={classes.summaryHeaderText}>
                    1. The Engagement Accelerator uses project files as a basis to build your document
                </Text>
                <Text className={classes.summaryDescriptionText}>
                    {selectedAccelTypeOption === '' ? (
                        <div>
                            Select the type of document you would like to build to see the documents used to build it.
                        </div>
                    ) : (
                        ''
                    )}
                    {selectedAccelTypeOption === 'onboarding' ? (
                        <div>
                            The <b style={{ color: '#18516E' }}>Internal Onboarding Brief</b> uses the{' '}
                            <b style={{ color: '#18516E' }}>MSA, SOW, and Proposal</b> files. Please see below to view
                            or upload your files. Other contextual files are okay to upload as well.
                        </div>
                    ) : (
                        ''
                    )}
                    {selectedAccelTypeOption === 'kickoffdeck' ? (
                        <div>
                            The <b style={{ color: '#18516E' }}>Kick Off Deck</b> uses the{' '}
                            <b style={{ color: '#18516E' }}>MSA, SOW, and Proposal</b> files. Please see below to view
                            or upload your files. Other contextual files are okay to upload as well.{' '}
                            <b style={{ color: '#18516E' }}>
                                This tool also uses public web data to develop your document.
                            </b>
                        </div>
                    ) : (
                        ''
                    )}
                    {selectedAccelTypeOption === 'checklist' ? (
                        <div>
                            Engagement Accelerator uses <b style={{ color: '#18516E' }}>SOW and Proposal</b> Files as a
                            basis to build your selected document.
                        </div>
                    ) : (
                        ''
                    )}
                </Text>
            </section>
            <ModalComponent isOpen={isModalOpen} toggleModal={toggleModal}>
                <ImageDisplayComponent />
            </ModalComponent>
        </>
    );
};
