import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as FolderIconComponent } from '../../../../assets/folder icon.svg';

interface DropzoneAreaProps {
    isHidden?: boolean;
    onFilesAdded: (files: File[]) => void;
}
const useClasses = makeStyles({
    dropText: {
        marginTop: '0.75rem',
        color: '#333',
        fontSize: 'larger',
    },
    manageText: {
        marginTop: '0.5rem',
        fontSize: 'medium',
    },
    browseText: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontSize: 'larger',
        cursor: 'pointer',
    },
    dragDropContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
    },
    active: {
        backgroundColor: '#f9f9f9',
    },
    hidden: {
        display: 'none',
    },
});
export interface DropzoneAreaRef {
    openDialog: () => void;
}

const DropzoneArea = forwardRef<DropzoneAreaRef, DropzoneAreaProps>(({ onFilesAdded, isHidden }, ref) => {
    const classes = useClasses();
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            onFilesAdded(acceptedFiles);
        },
        [onFilesAdded],
    );
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop });
    useImperativeHandle(ref, () => ({
        openDialog() {
            open();
        },
    }));

    return (
        <div
            {...getRootProps()}
            className={mergeClasses(
                classes.dragDropContainer,
                isDragActive && classes.active,
                isHidden && classes.hidden,
            )}
        >
            <input {...getInputProps()} />

            <FolderIconComponent
                style={{
                    color: '#666',
                    height: '56px',
                    width: '80px',
                }}
            />
            <p className={classes.dropText}>
                Drop your files here, or <span className={classes.browseText}>browse</span>
            </p>
            <div className={classes.manageText}>No files were found in iManage</div>
        </div>
    );
});

export default DropzoneArea;
