import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Image,
    Link,
    tokens,
    mergeClasses,
    shorthands,
    Text,
} from '@fluentui/react-components';
import { RootState } from '../../redux/app/store';
import { setShowQuickGuide } from '../../redux/features/commonData/CommonDataSlice';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { useEffect, useState } from 'react';
import page1Image from '../../assets/quick-guide/GuidePage1.png';
import customGPTs from '../../assets/quick-guide/CustomGPTs.png';
import PageOneBar from '../../assets/quick-guide/page1bar.svg';
import PageTwoBar from '../../assets/quick-guide/page2bar.svg';
import PageThreeBar from '../../assets/quick-guide/page3bar.svg';
import PageFourBar from '../../assets/quick-guide/page4bar.svg';
import CloseIcon from '../../assets/quick-guide/Close.png';
import { Breakpoints, customColors, customFonts, ScrollBarStyles } from '../../styles';
import { useNavigate } from 'react-router-dom';

export const useClasses = makeStyles({
    dialog: {
        minWidth: '900px',
        maxWidth: '1000px',
        width: '60%',
        height: '70%',
        minHeight: '600px',
        ...Breakpoints.small({
            minWidth: '680px',
            width: '70%',
            maxWidth: '850px',
            height: '60%',
            minHeight: '500px',
        }),
        '@media (max-width: 768px)': {
            minWidth: '350px',
            width: 'clamp(350px, 80%, 690px)',
            overFlowY: 'scroll',
            ...ScrollBarStyles,
        },
    },
    dialogTitle: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleContainer: {
        width: 'min-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        gap: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
    },
    title: {
        textAlign: 'center',
        color: customColors.protivitiDarkBlue,
        font: customFonts.TitleFont,
        fontWeight: '700',
        fontSize: '24px',
    },
    pageProgressBar: {
        width: 'clamp(300px, 40%, 330px)',
        height: 'clamp(30px, 100%, 30px)',
        '@media (max-width: 768px)': {
            width: 'clamp(250px, 40%, 330px)',
            height: 'clamp(30px, 100%, 30px)',
        },
    },
    closeButton: {
        marginBottom: '50px',
        ...Breakpoints.small({
            maxWidth: '24px',
            maxHeight: '24px',
        }),
    },
    dialogBody: {
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '10px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            height: '80%',
        },
    },
    dialogContent: {
        height: '100%',
        width: '90%',

        paddingBottom: '20px',
        overflowY: 'auto',
        ...ScrollBarStyles,
        ...Breakpoints.small({
            width: '100%',
        }),
        '@media (max-width: 768px)': {
            ...ScrollBarStyles,
        },
    },

    pageOneRoot: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '20px',
        ...Breakpoints.small({
            height: '90%',
            gap: '10px',
        }),
        '@media (max-width: 768px)': {
            gap: '20px',
            height: 'fit-content',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    pageOneImageContainer: {
        width: 'clamp(350px,40%,390px)',
        borderRadius: '16px',
        ...Breakpoints.small({
            minWidth: '250px',
            width: '300px',
            height: '100%',
        }),
        '@media (max-width: 768px)': {},
    },
    pageOneImage: {
        borderRadius: '16px',
        '@media (max-width: 768px)': {},
    },
    pageOneTextContainer: {
        flex: 1,
        width: 'clamp(250px, 60%, 575px)',
        height: '95%',
        paddingLeft: '10px',
        paddingRight: '5%',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        ...Breakpoints.small({
            justifyContent: 'flex-start',
            paddingLeft: '0',
            height: '100%',
            paddingTop: '0',
        }),
        '@media (max-width: 768px)': {
            width: '80%',
            maxWidth: '80%',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
    },
    pageOneTitle: {
        width: '100%',
        textAlign: 'left',
        font: customFonts.Lato,
        fontWeight: '700',
        fontSize: 'clamp(36px,1.5em,50px)',
        color: 'rgba(0, 66, 107, 0.75)',
        height: 'fit-content',
        lineHeight: '36px',
        ...Breakpoints.small({
            fontSize: 'clamp(1.2rem, 24px, 2rem)',
            lineHeight: 'clamp(1.2rem, 2.5vw, 2rem)',
        }),
    },
    pageOneDescription: {
        font: customFonts.Lato,
        fontWeight: '400',
        fontSize: '18px',
        color: '#46596b',
        lineHeight: 'clamp(18px, 3vh, 32px)',
        ...Breakpoints.small({
            fontSize: '16px',
            lineHeight: '20px',
        }),
    },

    pageTwoRootContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '20px',
        ...Breakpoints.small({
            height: '90%',
            gap: '10px',
        }),
        '@media (max-width: 768px)': {
            gap: '20px',
            height: 'fit-content',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    pageTwoImageContainer: {
        width: 'clamp(350px,40%,390px)',
        borderRadius: '16px',
        ...Breakpoints.small({
            minWidth: '250px',
            width: '300px',
            height: '100%',
        }),
        '@media (max-width: 768px)': {},
    },
    pageTwoTextContainer: {
        flex: 1,
        width: 'clamp(250px, 50%, 575px)',
        height: '95%',
        paddingLeft: '10px',
        paddingRight: '5%',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...Breakpoints.small({
            justifyContent: 'flex-start',
            paddingLeft: '0',
            height: '100%',
            paddingTop: '0',
        }),
        '@media (max-width: 768px)': {
            width: '80%',
            maxWidth: '80%',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
    },
    pageTwoTitle: {
        width: '100%',
        textAlign: 'left',
        font: customFonts.Lato,
        fontWeight: '700',
        fontSize: '36px',
        color: 'rgba(0, 66, 107, 0.75)',
        marginBottom: '15px',
    },
    pageTwoDescription: {
        font: customFonts.Lato,
        fontWeight: '400',
        fontSize: '18px',
        color: '#46596b',
        lineHeight: '32px',
    },
    pageTwoLink: {
        textAlign: 'left',
        font: customFonts.Lato,
        fontWeight: '400',
        fontSize: '18px',
        color: customColors.protivitiOrange,
        lineHeight: '32px',
        textDecoration: 'underline',
        alignSelf: 'start',
        paddingLeft: '0px',
    },
    pageThreeRootContainer: {
        height: '90%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pageThreeTitle: {
        width: '100%',
        textAlign: 'center',
        font: customFonts.Lato,
        fontWeight: '700',
        fontSize: '36px',
        color: 'rgba(0, 66, 107, 0.75)',
        marginBottom: '20px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            fontSize: '24px',
        },
    },
    pageThreeVideoContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pageThreeVideo: {
        width: '70%',
        height: '100%',
    },
    pageFourRoot: {
        height: '80%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    pageFourContactContainer: {},
    pageFourContactTitle: {
        width: '100%',
        textAlign: 'center',
        font: customFonts.Lato,
        fontWeight: '700',
        fontSize: '36px',
        color: 'rgba(0, 66, 107, 0.75)',
        marginBottom: '20px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            fontSize: '24px',
            marginBottom: '10px',
        },
    },
    pageFourContact: {
        font: customFonts.Lato,
        fontWeight: '400',
        fontSize: '18px',
        color: '#46596b',
        lineHeight: '32px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            fontSize: '16px',
            marginBottom: '10px',
        },
    },
    pageFourResourcesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pageFourResources: {
        width: '100%',
        textAlign: 'center',
        font: customFonts.Lato,
        fontWeight: '700',
        fontSize: '36px',
        color: 'rgba(0, 66, 107, 0.75)',
        marginBottom: '20px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            fontSize: '24px',
            marginBottom: '10px',
        },
    },
    pageFourLinks: {
        textAlign: 'center',
        font: customFonts.Lato,
        fontWeight: '400',
        fontSize: '18px',
        color: customColors.protivitiOrange,
        lineHeight: '32px',
        textDecoration: 'underline',
        paddingLeft: '0px',
        ...Breakpoints.small({}),
        '@media (max-width: 768px)': {
            fontSize: '16px',
            marginBottom: '10px',
            lineHeight: '24px',
        },
    },

    dialogActions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px',
        '@media (max-width: 768px)': {
            marginTop: '10px',
        },
    },
    buttons: {
        borderRadius: '18px',
        width: '150px',
        maxHeight: '32px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    leftButton: {
        ...shorthands.border('1px', 'solid', customColors.protivitiDarkBlue),
        color: customColors.protivitiDarkBlue,
    },
    rightButton: {
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        backgroundColor: customColors.protivitiOrange,
        color: 'white',
    },
});
const GettingStarted = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { showQuickGuide } = useAppSelector((state: RootState) => state.commonData);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [links, setLinks] = useState<Record<string, string>>({});
    const navigate = useNavigate();
    const onOpenExploreGPTPage = () => {
        dispatch(setShowQuickGuide(false));
        navigate('/explore-gpts');
    };
    useEffect(() => {
        if (process.env.REACT_APP_GETTING_STARTED) {
            const parsedLinks = JSON.parse(process.env.REACT_APP_GETTING_STARTED);
            setLinks(parsedLinks);
        }
    }, []);

    const PageOne = () => (
        <div className={classes.pageOneRoot}>
            <div className={classes.pageOneImageContainer}>
                <Image className={classes.pageOneImage} src={page1Image} fit="contain" />
            </div>
            <div className={classes.pageOneTextContainer}>
                <Text className={classes.pageOneTitle}>What is ProGPT?</Text>
                <Text className={classes.pageOneDescription}>
                    ProGPT is an OpenAI-powered application, similar to ChatGPT, designed exclusively for Protiviti
                    employees. Housed securely behind our firewall, ProGPT harnesses the capabilities of AI without the
                    concern of sensitive data exposure to the public. Just like other generative AI chatbots, you can
                    enter a prompt using conversational language and ProGPT will provide iterative responses.
                </Text>
            </div>
        </div>
    );
    const PageTwo = () => (
        <div className={classes.pageTwoRootContainer}>
            <div className={classes.pageTwoTextContainer}>
                <Text className={classes.pageTwoTitle}>Specialized GPTs</Text>
                <Text className={classes.pageTwoDescription}>
                    Specialized GPTs are assistants tailored to help with specific tasks and are augmented by specific
                    curated knowledge sources.
                </Text>
                <Button className={classes.pageTwoLink} appearance="transparent" onClick={() => onOpenExploreGPTPage()}>
                    Explore specialized GPTs
                </Button>
            </div>
            <div className={classes.pageTwoImageContainer}>
                <Image src={customGPTs} fit="contain" />
            </div>
        </div>
    );
    const PageThree = () => (
        <div className={classes.pageThreeRootContainer}>
            <div className={classes.pageThreeTitle}>Walkthrough</div>
            <div className={classes.pageThreeVideoContainer}>
                <iframe
                    className={classes.pageThreeVideo}
                    src={links.walkthroughVideo}
                    allowFullScreen
                    title="Navigating ProGPT"
                ></iframe>
            </div>
        </div>
    );
    const PageFour = () => (
        <div className={classes.pageFourRoot}>
            <div className={classes.pageFourContactContainer}>
                <div className={classes.pageFourContactTitle}>Questions?</div>
                <div className={classes.pageFourContact}>
                    Contact{' '}
                    <Link
                        className={classes.pageFourLinks}
                        href={'mailto:AI@protiviti.com'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        AI@protiviti.com
                    </Link>
                </div>
            </div>
            <div className={classes.pageFourResourcesContainer}>
                <div className={classes.pageFourResources}>Resources</div>
                <Link className={classes.pageFourLinks} href={links.FAQs} target="_blank" rel="noopener noreferrer">
                    ProGPT FAQs, Recent Feature Releases, and More
                </Link>
                <Link
                    className={classes.pageFourLinks}
                    href={links.ProGPTvsAtlas}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    ProGPT vs Atlas
                </Link>
                <Link className={classes.pageFourLinks} href={links.GenAI} target="_blank" rel="noopener noreferrer">
                    Learn More About Gen AI at Protiviti
                </Link>
            </div>
        </div>
    );
    const slides = [
        {
            content: <PageOne />,
            image: <Image src={PageOneBar} fit="contain" />,
            leftButtonText: 'Show Me Later',
            rightButtonText: 'Next',
        },
        {
            content: <PageTwo />,
            image: <Image src={PageTwoBar} fit="contain" />,
            leftButtonText: 'Back',
            rightButtonText: 'Next',
        },
        {
            content: <PageThree />,
            image: <Image src={PageThreeBar} fit="contain" />,
            leftButtonText: 'Back',
            rightButtonText: 'Next',
        },
        {
            content: <PageFour />,
            image: <Image src={PageFourBar} fit="contain" />,
            leftButtonText: 'Back',
            rightButtonText: 'Try ProGPT Now',
        },
    ];
    const handleLeftClick = (text: string) => {
        if (text === 'Show Me Later') {
            dispatch(setShowQuickGuide(false));
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };
    const handleRightClick = (text: string) => {
        if (text === 'Try ProGPT Now') {
            dispatch(setShowQuickGuide(false));
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };
    return (
        <Dialog open={showQuickGuide}>
            <DialogSurface className={classes.dialog}>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle className={classes.dialogTitle}>
                        <div className={classes.titleContainer}>
                            <div className={classes.title}>Getting Started With ProGPT</div>
                            <div className={classes.pageProgressBar}>{slides[currentSlide].image}</div>
                        </div>
                        <Button
                            className={classes.closeButton}
                            icon={<Image src={CloseIcon} fit="contain" />}
                            appearance="transparent"
                            onClick={() => dispatch(setShowQuickGuide(false))}
                        />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>{slides[currentSlide].content}</DialogContent>
                </DialogBody>
                <DialogActions className={classes.dialogActions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            className={mergeClasses(classes.leftButton, classes.buttons)}
                            appearance="secondary"
                            onClick={() => handleLeftClick(slides[currentSlide].leftButtonText)}
                        >
                            {slides[currentSlide].leftButtonText}
                        </Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            className={mergeClasses(classes.rightButton, classes.buttons)}
                            appearance="secondary"
                            onClick={() => handleRightClick(slides[currentSlide].rightButtonText)}
                        >
                            {slides[currentSlide].rightButtonText}
                        </Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};

export default GettingStarted;
