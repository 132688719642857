import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GPTState, initialState } from './gptState';
import { IGPT } from '../../../libs/models/GPT';

export const GPTSlice = createSlice({
    name: 'gpts',
    initialState,
    reducers: {
        setUserGPTs: (state: GPTState, action: PayloadAction<{ gpts: IGPT[] }>) => {
            state.userGPTs = action.payload.gpts;
        },
        setPublicGPTs: (state: GPTState, action: PayloadAction<{ publicGPTs: IGPT[] }>) => {
            state.publicGPTs = action.payload.publicGPTs;
        },
    },
});

export const { setUserGPTs, setPublicGPTs,} = GPTSlice.actions;

export default GPTSlice.reducer;
