import { IGPT } from '../../../libs/models/GPT';
import { IPersona } from '../../../libs/models/IPersona';
import { ISavedPrompt } from '../../../libs/models/ISavedPrompt';

export interface UserPreferencesState {
    userPersonas: IPersona[];
    pinnedGPTs: IGPT[];
    userPrompts: ISavedPrompt[];
}

export const initialState: UserPreferencesState = {
    userPersonas: [],
    pinnedGPTs: [],
    userPrompts: [],
};
