import React, { useState, useEffect } from 'react';
import Loading from '../../../views/Loading';
interface ExcelViewerProps {
    uri?: string;
    fileName?: string;
}

const ExcelViewer: React.FC<ExcelViewerProps> = ({ uri, fileName }) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500); // 500ms delay to simulate loading

        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{ width: '100%', height: '100%', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {loading ? (
                <Loading text={`Loading ${fileName}...`} />
            ) : (
                <iframe src={uri} width="100%" height="100%" frameBorder="0"></iframe>
            )}
        </div>
    );
};

export default ExcelViewer;
