import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Textarea,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import React from 'react';
import { customColors, customFonts } from '../../styles';

const useClasses = makeStyles({
    dialogTextArea: {
        width: '100%',
        height: '100%'
    },
    dialogTitle: {},
    dialogSurface: {
        height: '33%'
    },
    dialogBody: {
        height: '100%'
    },
    dialogContent: {},
    addPersonaButton: {
        ...shorthands.borderRadius('0'),
        backgroundColor: customColors.white,
        width: 'max-content',
        fontWeight: '700',
        fontFamily: customFonts.Lato,
        fontSize: '13.5px',
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),

        '&: hover > button, &: hover': {
            backgroundColor: customColors.protivitiOrange,
        },
    },
});

interface SaveDialogProps {
    tooltipContent: string;
    textAreaValue: string;
    title: string;
    onClose: () => void;
    onSave: (saveValue: string) => void;
}
const SaveDialog: React.FC<SaveDialogProps> = ({ tooltipContent, textAreaValue, title, onClose, onSave }) => {
    const classes = useClasses();
    const [inputValue, setInputValue] = React.useState(textAreaValue);
    const handleValueChange = (newValue: string) => {
        if (title === 'Title') {
            if (newValue.length <= 20) {
                setInputValue(newValue);
            }
        } else {
            setInputValue(newValue);
        }
    };
    return (
        <Dialog open={true}>
            <DialogSurface style={title=="Title" ? {} : {height: '33%'}}>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle className={classes.dialogTitle}>
                        {title}{' '}
                        <Tooltip content={tooltipContent} relationship="label">
                            <InfoRegular />
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Textarea
                            aria-required="true"
                            className={classes.dialogTextArea}
                            value={inputValue}
                            onChange={(_e, data) => handleValueChange(data.value)}
                            resize='vertical'
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={onClose}>
                                Close
                            </Button>
                        </DialogTrigger>
                        <Button
                            className={classes.addPersonaButton}
                            onClick={() => onSave(inputValue)}
                            disabled={!inputValue.trim()}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default SaveDialog;
