import React from 'react';
import { makeStyles, Text } from '@fluentui/react-components';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';

const useStyles = makeStyles({
    spinnerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px',
        animationName: {
            from: { opacity: 0, transform: 'scale(0.95)' },
            to: { opacity: 1, transform: 'scale(1)' },
        },
        animationDuration: '0.4s',
        animationTimingFunction: 'ease-out',
        animationFillMode: 'forwards',
    },
    spinnerSvg: {
        width: '80px',
        height: '80px',
        animationName: {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' },
        },
        animationDuration: '2s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        filter: 'drop-shadow(0 0 6px rgba(0, 64, 104, 0.4))',
    },
    spinnerCircle: {
        fill: 'none',
        stroke: '#004068',
        strokeWidth: '4',
        strokeLinecap: 'round',
        animationName: {
            '0%': {
                strokeDasharray: '1, 150',
                strokeDashoffset: '0',
            },
            '50%': {
                strokeDasharray: '90, 150',
                strokeDashoffset: '-35',
            },
            '100%': {
                strokeDasharray: '90, 150',
                strokeDashoffset: '-124',
            },
        },
        animationDuration: '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
    spinnerText: {
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: 500,
        color: '#2D3748',
    },
});

const ArtifactSpinner: React.FC = () => {
    const classes = useStyles();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const botResponseStatus = useAppSelector(
        (state: RootState) => state.currentmessages.generatingMessages[selectedId]?.botResponseStatus,
        shallowEqual,
    );


    const [statusText, setStatusText] = React.useState('');

    React.useEffect(() => {
        const statusMap: { [key: string]: string } = {
            'Getting ready to generate document': 'Getting ready to generate document',
            'Generating document': 'Generating document...',
            'Generating annotation(s)': 'Almost done...',
            'Generating artifact(s)': 'Almost done...',
            'Finalizing document': 'Finalizing document...',
            'Generating document failed': 'Failed to generate document!',
            'Generating document succeeded': 'Document generated!'
        };

        setStatusText(statusMap[botResponseStatus]);
    }, [botResponseStatus]);

    return (
        <div className={classes.spinnerWrapper}>
            <svg className={classes.spinnerSvg} viewBox="0 0 50 50">
                <circle className={classes.spinnerCircle} cx="25" cy="25" r="20" />
            </svg>
            <Text className={classes.spinnerText}>{statusText}</Text>
        </div>
    );
};

export default ArtifactSpinner;
