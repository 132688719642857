import { UserManagementService } from '../services/UserManagementService';
import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { User } from 'microsoft-cognitiveservices-speech-sdk';
import { toast } from 'react-toastify';
import { makeStyles } from '@fluentui/react-components';
import { customFonts } from '../../styles';
import { IPersona } from '../models/IPersona';
import { ISavedPrompt } from '../models/ISavedPrompt';
import { useDispatch } from 'react-redux';
import { addUserPrompt, updateUserPrompt } from '../../redux/features/users/userPreferencesSlice';


const useStyles = makeStyles({
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

interface UserPreferences {
    pinnedGPTs: string[];
    personas: IPersona[];
    userPrompts: ISavedPrompt[];
    createdDate: string;
}


export const useUser = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    const { instance, inProgress } = useMsal();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userId = activeUserInfo?.id ?? '';

    const updatePinnedGPTS = async (gpts: string[]) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await userManagementService.updatePinnedGPTS(gpts, userId, accessToken).then((user: User) => {
            return user;
        });
    };

    const getUserPreferences = async (): Promise<UserPreferences> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const userPreferences = await userManagementService.getUserPreferencesAsync(userId, accessToken);
            return userPreferences;
        } catch (e: any) {
            const errorMessage = `Unable to get user settings. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return { pinnedGPTs: [], personas: [], userPrompts: [], createdDate: new Date().toDateString()};
    };

    const updatePersonas = async (userId: string, updatedPersonas: IPersona[]) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const newPersonas = await userManagementService.updateUserPersonasAsync(
                userId,
                updatedPersonas,
                accessToken,
            );
            toast.success('Personas updated successfully', {
                className: classes.toastMessage,
            });
            return newPersonas;
        } catch (e: any) {
            const errorMessage = `Unable to get personas. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return [];
    };
    const createPrompt = async (userId: string, promptText: string, gptEndpoint: string, isDocumentChat: boolean) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const newPrompt = await userManagementService.createUserPromptAsync(
                userId,
                promptText,
                gptEndpoint,
                isDocumentChat,
                accessToken,
            );
            dispatch(addUserPrompt({ userPrompt: newPrompt }));
            toast.success('Prompt saved successfully', {
                className: classes.toastMessage,
            });
            return newPrompt;
        } catch (e: any) {
            const errorMessage = `Unable to create new prompt. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
            return errorMessage;
        }
    };
    const updatePrompt = async (userId: string, updatedPrompt: ISavedPrompt) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const newPrompt = await userManagementService.updateUserPromptAsync(userId, updatedPrompt, accessToken);
            dispatch(updateUserPrompt({ userPrompt: newPrompt }));
            toast.success('Prompt saved successfully', {
                className: classes.toastMessage,
            });
            return newPrompt;
        } catch (e: any) {
            const errorMessage = `Unable to update prompt. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
            return errorMessage;
        }
    };

    const deletePrompt = async (userId: string, promptId: string) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const result = await userManagementService.deleteUserPromptAsync(userId, promptId, accessToken);
            return result;
        } catch (e: any) {
            const errorMessage = `Unable to delete prompt. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
            return errorMessage;
        }
    };



    return { updatePinnedGPTS, updatePersonas, getUserPreferences, updatePrompt, createPrompt, deletePrompt, };
};
