import { SelectTabEventHandler, Tab, TabList } from '@fluentui/react-components';
import {
    BotSparkle20Regular,
    BotSparkle20Filled,
    ChatSettings20Filled,
    ChatSettings20Regular,
    // DocumentBulletList20Filled,
    // DocumentBulletList20Regular,
} from '@fluentui/react-icons';
import useClasses from './EAStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setSelectedTab } from '../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../shared/Alerts';
import { useNavigate } from 'react-router-dom';
import { ThreadRunConfig } from '../custom-gpt/ThreadRunConfig';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { UserManagementService } from '../../libs/services/UserManagementService';
import { IGPT } from '../../libs/models/GPT';
import { InputsTab } from './tabs/InputsTab';
import { ChatTab } from './tabs/ChatTab';
// import { DocumentsTab } from '../chat/tabs/DocumentsTab';
import { shallowEqual } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const toastAppearance = 3000;

export const EAWindow: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    //const { features } = useAppSelector((state: RootState) => state.app);
    const selectedTab = useAppSelector((state: RootState) => state.conversations.selectedTab);
    // const location = useLocation();
    const navigate = useNavigate();
    // const showShareBotMenu = features[FeatureKeys.BotAsDocs].enabled || features[FeatureKeys.MultiUserChat].enabled;
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [userRole, setUserRole] = useState<string | null>(null);
    const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    const { instance, inProgress } = useMsal();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const [selectedGPT, setSelectedGPT] = useState<IGPT>();
    //const [isEditing, setIsEditing] = useState<boolean>(false);
    const chatName = conversations[selectedId] ? conversations[selectedId].title : '';
    const [isThreadRunSettingsVisible, setIsThreadRunSettingsVisible] = useState(false);
    const { isResponseGenerating } = useAppSelector(
        (state: RootState) => ({
            isResponseGenerating: state.currentmessages.generatingMessages[selectedId].isResponseGenerating,
        }),
        shallowEqual,
    );
    const settingsRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: { target: any }) {
            if (settingsRef.current && !settingsRef.current.contains(event.target)) {
                setIsThreadRunSettingsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [settingsRef]);
    if (chatName === '') {
        navigate('/');
    }
    const onTabSelect: SelectTabEventHandler = (_event, data) => {
        dispatch(setSelectedTab(data.value));
        //  navigate(`/${isDocumentChat ? 'documentchat' : 'chat'}/${selectedId}/${data.value}`);
    };

    useEffect(() => {
        const fetchUserRole = async () => {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const currentUser = await userManagementService.getUserRoleByEmail(accessToken);
            const role = currentUser?.role?.toString() || '';
            setUserRole(role);
        };

        if (activeUserInfo?.email) {
            fetchUserRole();
        }
    }, []);

    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId]?.gptEndpoint && gpt.isActive);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    useEffect(() => {
        dispatch(setSelectedTab('inputs'));
    }, [selectedId, dispatch]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                        <Tab data-testid="inputsTab" id="inputs" value="inputs" className={classes.chat}>
                            Inputs
                        </Tab>
                        {selectedGPT && selectedGPT.id !== process.env.REACT_APP_DEFAULT_PROGPT && (
                            <Tab
                                data-testid="ea-chat"
                                id="ea-chat"
                                value="ea-chat"
                                icon={selectedTab === 'ea-chat' ? <BotSparkle20Filled /> : <BotSparkle20Regular />}
                                className={classes.chat}
                            >
                                EA AI Chat
                            </Tab>
                        )}
                        {/* {(selectedGPT?.isDocumentUploadEnabled) && (
                            <Tab
                                data-testid="documentsTab"
                                id="documents"
                                value="documents"
                                className={classes.chat}
                                icon={
                                    selectedTab === 'documents' ? (
                                        <DocumentBulletList20Filled />
                                    ) : (
                                        <DocumentBulletList20Regular />
                                    )
                                }
                            >
                                Documents
                            </Tab>
                        )} */}
                    </TabList>
                </div>
                {selectedGPT && selectedGPT.id !== process.env.REACT_APP_DEFAULT_PROGPT && userRole === '0' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '24px',
                            position: 'relative',
                        }}
                        ref={settingsRef}
                    >
                        {isThreadRunSettingsVisible ? (
                            <ChatSettings20Filled
                                className={classes.pointerCursor}
                                onClick={() => setIsThreadRunSettingsVisible(false)}
                            />
                        ) : (
                            <ChatSettings20Regular
                                className={classes.pointerCursor}
                                onClick={() => setIsThreadRunSettingsVisible(true)}
                            />
                        )}

                        {isThreadRunSettingsVisible &&
                            selectedGPT &&
                            selectedGPT.id !== process.env.REACT_APP_DEFAULT_PROGPT && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '100%',
                                        right: 0,
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        zIndex: 10000,
                                    }}
                                >
                                    <ThreadRunConfig />
                                </div>
                            )}
                    </div>
                )}
            </div>

            {selectedTab === 'inputs' && <InputsTab />}
            {(selectedTab === 'ea-chat' || isResponseGenerating) && <ChatTab />}
            {selectedTab !== 'ea-chat' && (
                <div className={classes.alerts}>
                    <Alerts />
                </div>
            )}

            <ToastContainer autoClose={toastAppearance} />
        </div>
    );
};
