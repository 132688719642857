import {
    Button,
    Link,
    makeStyles,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarIntent,
    MessageBarTitle,
    shorthands,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React from 'react';
import { customColors } from '../../styles';

const useClasses = makeStyles({
    messageBarTitle: {
    },
    messageBar: {
        color: customColors.protivitiDarkBlue,
        ...shorthands.borderColor(customColors.protivitiOrange),
        width: '100%',
        textAlign: 'center',
        minHeight: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageBarButton: {
        maxHeight: '20px',
        marginLeft: '15px',
        ...shorthands.borderColor(customColors.protivitiOrange),
        verticalAlign: 'baseline',
    }
});

interface NotificationBannerProps {
    onCloseBanner: () => void;
}
const NotificationBanner: React.FC<NotificationBannerProps> = ({ onCloseBanner }) => {
    const classes = useClasses();
    const { message, category, link, linkMessage } = JSON.parse(process.env.REACT_APP_BANNER_CONFIG || '{}');
    const [intentType, setIntentType] = React.useState<MessageBarIntent>('info');
    React.useEffect(() => {
        if (category) {
            switch (category) {
                case 'information':
                    setIntentType('info');
                    break;
                case 'maintenance':
                    setIntentType('warning');
                    break;
                case 'outage':
                    setIntentType('error');
                    break;
                default:
                    break;
            }
        }
    }, []);

    return (
        <MessageBar className={classes.messageBar} shape="square" intent={intentType}>
            <MessageBarBody>
                <MessageBarTitle className={classes.messageBarTitle}>{message} </MessageBarTitle>
                {link && linkMessage.length > 0 && (
                    <Button className={classes.messageBarButton}>
                    <Link href={link} style={{ fontWeight: 'bold', textDecoration: 'none' }} target="_blank">
                        {linkMessage}
                    </Link>
                    </Button>
                )}
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                        onClick={onCloseBanner}
                    />
                }
            ></MessageBarActions>
        </MessageBar>
    );
};

export default NotificationBanner;
