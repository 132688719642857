import { createAsyncThunk } from '@reduxjs/toolkit';
import { TabValue } from '@fluentui/react-components';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { Artifact, ChatMessageType, IChatMessage, UserFeedback } from '../../../libs/models/ChatMessage';
import { IChatUser } from '../../../libs/models/ChatUser';
import { ChatCitation } from '../../../libs/models/ChatCitation';
import { ChatState } from './ChatState';
import {
    ConversationInputChange,
    Conversations,
    ConversationsState,
    ConversationSystemDescriptionChange,
    ConversationTitleChange,
    initialState,
    AppendMessageArgs,
    ChatInfo,
} from './ConversationsState';
import { DeleteChatState } from './DeleteChatState';
import { ChatMemorySource } from '../../../libs/models/ChatMemorySource';
import { IThreadRunConfig } from '../../../libs/models/GPT';


export const conversationsSlice: Slice<ConversationsState> = createSlice({
    name: 'conversations',
    initialState,
    reducers: {
        setConversations: (state: ConversationsState, action: PayloadAction<Conversations>) => {
            state.conversations = action.payload;
        },
        editConversationTitle: (state: ConversationsState, action: PayloadAction<ConversationTitleChange>) => {
            const id = action.payload.id;
            const newTitle = action.payload.newTitle;
            state.conversations[id].title = newTitle;
            frontLoadChat(state, id);
        },
        conversationTitleGenerated: (state: ConversationsState, action: PayloadAction<ConversationTitleChange>) => {
            const id = action.payload.id;
            var isTitleGenerated = false;
            if (action.payload.isTitleGenerated) {
                isTitleGenerated = action.payload.isTitleGenerated;
            }
            const title = action.payload.newTitle;
            if (state.conversations[id]) {
                if (
                    !state.conversations[id].isTitleGenerated ||
                    (state.conversations[id].isTitleGenerated && state.conversations[id].title)
                ) {
                    state.conversations[id].title = title;
                    state.conversations[id].isTitleGenerated = isTitleGenerated;
                }
            }
        },
        editConversationInput: (state: ConversationsState, action: PayloadAction<ConversationInputChange>) => {
            const id = action.payload.id;
            const newInput = action.payload.newInput;
            state.conversations[id].input = newInput;
        },
        editConversationSystemDescription: (
            state: ConversationsState,
            action: PayloadAction<ConversationSystemDescriptionChange>,
        ) => {
            const id = action.payload.id;
            const newSystemDescription = action.payload.newSystemDescription;
            state.conversations[id].systemDescription = newSystemDescription;
        },
        editConversationMemoryBalance: (
            state: ConversationsState,
            action: PayloadAction<{ id: string; memoryBalance: number }>,
        ) => {
            const id = action.payload.id;
            const newMemoryBalance = action.payload.memoryBalance;
            state.conversations[id].memoryBalance = newMemoryBalance;
        },
        setSelectedConversation: (state: ConversationsState, action: PayloadAction<string>) => {
            state.selectedId = action.payload;

            const currentConversation = state.conversations[state.selectedId];
            const targetConversation = state.conversations[action.payload];

            const isCurrentConvoDocuChat = currentConversation.chatType;
            const isTargetConvoDocuChat = targetConversation.chatType;

            if (typeof currentConversation !== undefined && typeof targetConversation !== undefined) {
                if (isCurrentConvoDocuChat && !isTargetConvoDocuChat && state.selectedTab === 'documents') {
                    state.selectedTab = 'chat';
                }
            }
        },
        setSelectedTab: (state: ConversationsState, action: PayloadAction<TabValue>) => {
            state.selectedTab = action.payload;
        },
        addConversation: (state: ConversationsState, action: PayloadAction<ChatState>) => {
            const newId = action.payload.id;
            state.conversations = { [newId]: action.payload, ...state.conversations };
            state.selectedId = newId;
        },
        setConversation: (state: ConversationsState, action: PayloadAction<ChatState>) => {
            const setId = action.payload.id;
            state.conversations[setId] = action.payload;
        },
        //reducer to delete conversation in frontend
        deleteConversation: (state: ConversationsState, action: PayloadAction<DeleteChatState>) => {
            const keys = Object.keys(state.conversations);
            const chatId = action.payload.chatId;
            const isSignal = action.payload.isSignal;

            if (isSignal) {
                state.selectedId = chatId;
            }

            if (chatId === state.selectedId) {
                if (keys.length > 1) {
                    state.selectedId = chatId === keys[0] ? keys[1] : keys[0];
                } else {
                    state.selectedId = '';
                }
            }

            const { [chatId]: _, ...remainingConversations } = state.conversations;
            state.conversations = remainingConversations;

            // Check if only one conversation is left
            if (Object.keys(state.conversations).length === 1) {
                const remainingChatId = Object.keys(state.conversations)[0];
                state.selectedId = remainingChatId;

                frontLoadChat(state, remainingChatId);
            }

            action.payload.chatId = chatId;
        },
        deleteConversations: (state: ConversationsState, action: PayloadAction<ChatInfo[]>) => {
            const chatInfo = action.payload
            const filteredIds = chatInfo.map((chatItem) => chatItem.chatId)
            const remainingConversations = Object.fromEntries(
                    Object.entries(state.conversations).filter(([key]) => !filteredIds.includes(key)),
                );
            state.conversations = remainingConversations;
            if (Object.keys(state.conversations).length === 1) {
                const remainingChatId = Object.keys(state.conversations)[0];
                state.selectedId = remainingChatId;

                frontLoadChat(state, remainingChatId);
            }
            
        },
        addMessagesToConversation: (
            state: ConversationsState,
            action: PayloadAction<{ messages: IChatMessage[]; chatId: string }>,
        ) => {
            const { messages, chatId } = action.payload;
            state.conversations[chatId].messages = messages;
        },
        appendMessagesToConversation: (
            state: ConversationsState,
            action: PayloadAction<{ messages: IChatMessage[]; chatId: string }>,
        ) => {
            const { messages, chatId } = action.payload;

            // Create a set of existing message IDs for quick lookup
            const existingMessageIds = new Set(state.conversations[chatId].messages.map((message) => message.id));

            // Filter new messages to remove any that already exist
            const filteredMessages = messages.filter((message) => !existingMessageIds.has(message.id));
            // Append filtered new messages to the existing ones in the state
            state.conversations[chatId].messages = [
                ...filteredMessages, // these are just received, but they're OLDER in timestamp
                ...state.conversations[chatId].messages, // previously loaded, but technically "newer" in terms of timestamp
            ];
        },
        setLoadMoreMessages: (
            state: ConversationsState,
            action: PayloadAction<{ loading: boolean; chatId: string }>,
        ) => {
            const { loading, chatId } = action.payload;
            if (state.conversations[chatId]) {
                state.conversations[chatId].loadMore = loading;
            }
        },
        setIsChatSessionLoading: (
            state: ConversationsState,
            action: PayloadAction<{ isChatSessionLoading: boolean; chatId: string }>,
        ) => {
            const { isChatSessionLoading, chatId } = action.payload;
            if (state.conversations[chatId]) {
                state.conversations[chatId].isChatSessionLoading = isChatSessionLoading;
            }
        },
        addUserToConversation: (
            state: ConversationsState,
            action: PayloadAction<{ user: IChatUser; chatId: string }>,
        ) => {
            const { user, chatId } = action.payload;
            state.conversations[chatId].users.push(user);
            state.conversations[chatId].userDataLoaded = false;
        },
        setImportingDocumentsToConversation: (
            state: ConversationsState,
            action: PayloadAction<{ importingDocuments: string[]; chatId: string }>,
        ) => {
            const { importingDocuments, chatId } = action.payload;
            state.conversations[chatId].importingDocuments = importingDocuments;
        },
        setIsImporting: (
            state: ConversationsState,
            action: PayloadAction<{ chatId: string; isImporting: number }>,
        ) => {
            const chatId = action.payload.chatId;
            const isImporting = action.payload.isImporting;
            state.conversations[chatId].isImporting = isImporting;
        },
        decrementImporting: (
            state: ConversationsState,
            action: PayloadAction<{ chatId: string }>,
        ) => {
            const chatId = action.payload.chatId;
            const current = state.conversations[chatId].isImporting;
            if (current) {
                state.conversations[chatId].isImporting = current - 1;
            } else {
                state.conversations[chatId].isImporting = 0;
            }
        },
        setUsersLoaded: (state: ConversationsState, action: PayloadAction<string>) => {
            state.conversations[action.payload].userDataLoaded = true;
        },
        /*
         * addMessageToConversationFromUser() and addMessageToConversationFromServer() both update the conversations state.
         * However they are for different purposes. The former action is for updating the conversation from the
         * webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
         * in the same group.
         * The addMessageToConversationFromServer() action is triggered by the SignalR middleware when a response is received
         * from the webapi.
         */
        addMessageToConversationFromUser: (
            state: ConversationsState,
            action: PayloadAction<{ message: IChatMessage; chatId: string }>,
        ) => {
            const { message, chatId } = action.payload;
            updateConversation(state, chatId, message);
        },
        addMessageToConversationFromServer: (
            state: ConversationsState,
            action: PayloadAction<{ message: IChatMessage; chatId: string }>,
        ) => {
            const { message, chatId } = action.payload;

            if (message.citations) {
                updateConversation(state, chatId, message, message.citations);
            } else {
                updateConversation(state, chatId, message);
            }
        },
        removeMessageFromConversation: (
            state: ConversationsState,
            action: PayloadAction<{ chatId: string; messageId: string }>,
        ) => {
            const { chatId, messageId } = action.payload;
            const conversation = state.conversations[chatId];

            if (!conversation) {
                console.error(`Conversation with chatId ${chatId} not found.`);
                return;
            }

            const index = conversation.messages.findIndex((msg) => msg.id === messageId);
            if (index !== -1) {
                conversation.messages.splice(index, 1);
            }
        },
        setDocumentsToConversation: (
            state: ConversationsState,
            action: PayloadAction<{ documents: ChatMemorySource[]; chatId: string }>,
        ) => {
            const { documents, chatId } = action.payload;
            state.conversations[chatId].uploadedDocuments = documents;
        },
        /*
         * updateUserIsTyping() and updateUserIsTypingFromServer() both update a user's typing state.
         * However they are for different purposes. The former action is for updating an user's typing state from
         * the webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
         * in the same group.
         * The updateUserIsTypingFromServer() action is triggered by the SignalR middleware when a state is received
         * from the webapi.
         */
        updateUserIsTyping: (
            state: ConversationsState,
            action: PayloadAction<{ userId: string; chatId: string; isTyping: boolean }>,
        ) => {
            const { userId, chatId, isTyping } = action.payload;
            updateUserTypingState(state, userId, chatId, isTyping);
        },
        updateUserIsTypingFromServer: (
            state: ConversationsState,
            action: PayloadAction<{ userId: string; chatId: string; isTyping: boolean }>,
        ) => {
            const { userId, chatId, isTyping } = action.payload;
            updateUserTypingState(state, userId, chatId, isTyping);
        },
        updateMessageProperty: <K extends keyof IChatMessage, V extends IChatMessage[K]>(
            state: ConversationsState,
            action: PayloadAction<{
                property: K;
                value: V;
                citationsArray: ChatCitation[];
                chatId: string;
                messageIdOrIndex: string | number;
                updatedContent?: string;
                frontLoad?: boolean;
                modelError?: boolean;
                modelId?: string;
            }>,
        ) => {
            const { property, value, citationsArray, messageIdOrIndex, chatId, updatedContent, frontLoad, modelError, modelId } =
                action.payload;
            const conversation = state.conversations[chatId];
            const conversationMessage =
                typeof messageIdOrIndex === 'number'
                    ? conversation.messages[messageIdOrIndex]
                    : conversation.messages.find((m) => m.id === messageIdOrIndex);

            if (conversationMessage) {
                conversationMessage[property] = value;
                if (updatedContent) {
                    conversationMessage.content = updatedContent;
                }
                conversationMessage.citations = citationsArray;
                conversationMessage.modelError = modelError;
                if (modelId) {
                    conversationMessage.modelId = modelId;
                }
            }

            if (frontLoad) {
                frontLoadChat(state, chatId);
            }
        },
        updateMessageWithImage: (
            state: ConversationsState,
            action: PayloadAction<{
                chatId: string;
                messageIdOrIndex: string | number;
                image?: string;
                frontLoad?: boolean;
            }>,
        ) => {
            const { messageIdOrIndex, chatId, image } = action.payload;
            const conversation = state.conversations[chatId];
            const conversationMessage =
                typeof messageIdOrIndex === 'number'
                    ? conversation.messages[messageIdOrIndex]
                    : conversation.messages.find((m) => m.id === messageIdOrIndex);

            if (conversationMessage) {
                conversationMessage.images = image;
            }
        },
        updateMessageWithAnnotations: (
            state: ConversationsState,
            action: PayloadAction<{
                chatId: string;
                messageIdOrIndex: string | number;
                annotations: string[];
                frontLoad?: boolean;
            }>,
        ) => {
            const { messageIdOrIndex, chatId, annotations } = action.payload;
            const conversation = state.conversations[chatId];
            const conversationMessage =
                typeof messageIdOrIndex === 'number'
                    ? conversation.messages[messageIdOrIndex]
                    : conversation.messages.find((m) => m.id === messageIdOrIndex);

            if (conversationMessage && annotations) {
                try {
                    annotations.forEach((fileId) => {
                        if (!conversationMessage.annotations) {
                            conversationMessage.annotations = [];
                        }
                        conversationMessage.annotations!.push(fileId);
                    });
                } catch (error) {
                    console.error('An error occurred while adding annotations from server:', error);
                }
            }
            frontLoadChat(state, chatId);
        },
        updateMessageWithArtifact: (
            state: ConversationsState,
            action: PayloadAction<{
                chatId: string;
                messageIdOrIndex: string | number;
                artifacts: Artifact[];
                frontLoad?: boolean;
            }>,
        ) => {
            const { messageIdOrIndex, chatId, artifacts } = action.payload;
            const conversation = state.conversations[chatId];
            const conversationMessage =
                typeof messageIdOrIndex === 'number'
                    ? conversation.messages[messageIdOrIndex]
                    : conversation.messages.find((m) => m.id === messageIdOrIndex);

            if (conversationMessage && artifacts?.length > 0 && conversationMessage.id) {
        
        const mappedArtifacts = artifacts.map((artifact) => ({
            ...artifact,
            type: artifact.type as
                | 'code'
                | 'image'
                | 'pdf'
                | 'pptx'
                | 'markdown'
                | 'csv'
                | 'png'
                | 'txt'
                | 'json'
                | 'html'
                | 'docx'
                | 'xlsx'
                | 'aspx',
        }));
                conversationMessage.artifacts = mappedArtifacts;
        } else {
            console.error(`Invalid artifacts or message id for message ${messageIdOrIndex} in conversation ${chatId}`);
        }
        frontLoadChat(state, chatId);
        },
        
        updateSavedInput: (state: ConversationsState, action: PayloadAction<{ input: string }>) => {
            state.savedInput = action.payload.input;
        },
        updateConversationInput: (
            state: ConversationsState,
            action: PayloadAction<{ chatId: string; input: string }>,
        ) => {
            const chatId = action.payload.chatId;
            const input = action.payload.input;
            state.conversations[chatId].input = input;
        },
        updateConversationWithThreadRunConfig: (
            state: ConversationsState,
            action: PayloadAction<{ chatId: string; threadRunConfig: IThreadRunConfig }>,
        ) => {
            const chatId = action.payload.chatId;
            if (action.payload.threadRunConfig) {
                state.conversations[chatId].threadRunConfig = action.payload.threadRunConfig;
            } else {
                const threadRunConfig: IThreadRunConfig = {
                    temperature: 1,
                    maxPromptTokens: 16000,
                    maxCompletionTokens: 1000
                }
                state.conversations[chatId].threadRunConfig = threadRunConfig;
            }
        },
        selectConversationsToDelete: (state: ConversationsState, action: PayloadAction< {selectedConversations: ChatState[]} >) => {
            const selectedConversations = action.payload.selectedConversations;
            state.selectedConversations = selectedConversations;
        },
        setCanvasOpen(state, action: PayloadAction<boolean>) {
            state.canvasOpen = action.payload;
        },
    },
});

export const appendMessages = createAsyncThunk(
    'conversations/appendMessages', // Ensure this is unique and follows the domain/eventName pattern
    async ({ chatId, messages }: AppendMessageArgs, { dispatch /*getState*/ }) => {
        dispatch(appendMessagesToConversation({ messages, chatId }));
        return { chatId, messages };
    },
);

const frontLoadChat = (state: ConversationsState, id: string) => {
    const conversation = state.conversations[id];
    const { [id]: _, ...rest } = state.conversations;
    state.conversations = { [id]: conversation, ...rest };
};

const updateConversation = (
    state: ConversationsState,
    chatId: string,
    message: IChatMessage,
    citations?: ChatCitation[],
    threadRunConfig?: IThreadRunConfig,
) => {
    const requestUserFeedback = message.userId === 'bot' && message.type === ChatMessageType.Message;
    if (message.userId !== 'bot') {
        const existingMessageIndex = state.conversations[chatId].messages.findIndex(
            (m) => m.timestamp === message.timestamp,
        );
        // If a message with the same timestamp exists, do not push the new message
        if (existingMessageIndex === -1) {
            state.conversations[chatId].messages.push({
                ...message,
                citations,
                threadRunConfig,
                userFeedback: requestUserFeedback ? UserFeedback.Requested : undefined,
            });
        }
    } else {
        state.conversations[chatId].messages.push({
            ...message,
            citations,
            userFeedback: requestUserFeedback ? UserFeedback.Requested : undefined,
        });  
        
    }
    frontLoadChat(state, chatId);
};

const updateUserTypingState = (state: ConversationsState, userId: string, chatId: string, isTyping: boolean) => {
    const conversation = state.conversations[chatId];
    const user = conversation.users.find((u) => u.id === userId);
    if (user) {
        user.isTyping = isTyping;
    }
};

export const {
    setConversations,
    setConversation,
    editConversationTitle,
    editConversationInput,
    editConversationSystemDescription,
    editConversationMemoryBalance,
    setSelectedConversation,
    setSelectedTab,
    addConversation,
    deleteConversation,
    setImportingDocumentsToConversation,
    addMessagesToConversation,
    addMessageToConversationFromUser,
    addMessageToConversationFromServer,
    removeMessageFromConversation,
    updateMessageProperty,
    setDocumentsToConversation,
    updateMessageWithImage,
    updateMessageWithArtifact,
    updateUserIsTyping,
    updateUserIsTypingFromServer,
    setUsersLoaded,
    updateSavedInput,
    updateConversationInput,
    appendMessagesToConversation,
    updateConversationWithThreadRunConfig,
    decrementImporting,
    setIsImporting,
    setLoadMoreMessages,
    setIsChatSessionLoading,
    selectConversationsToDelete,
    deleteConversations,
    conversationTitleGenerated,
    setCanvasOpen
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
