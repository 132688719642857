import {
    Button,
    Card,
    CardHeader,
    CardPreview,
    Input,
    Text,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import {
    AddCircleFilled,
    AddCircleRegular,
    Search24Regular,
    bundleIcon,
    iconFilledClassName,
    iconRegularClassName,
} from '@fluentui/react-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as Arrow } from '../../assets/arrow-smallupward-icon-black-rgb.svg';
import { useGPT } from '../../libs/hooks/useGPT';
import { IGPT } from '../../libs/models/GPT';
import { useAppDispatch } from '../../redux/app/hooks';
import { AppState } from '../../redux/features/app/AppState';
import { removeAlert } from '../../redux/features/app/appSlice';
import { customColors, customFonts, ScrollBarStyles } from '../../styles';
import { ManageGPTTile } from './ManageGPTTile';
import { useAppSelector } from '../../redux/app/hooks';
import { UsersState } from '../../redux/features/users/UsersState';
import { useUserRole } from '../../libs/hooks/useUserRole';

const useClasses = makeStyles({
    card: {
        width: 'fit-content',
        maxWidth: '100%',
        height: 'fit-content',
    },

    horizontalCardImage: {
        width: '64px',
        height: '64px',

        '@media (max-width: 768px)': {
            width: '50px',
            height: '50px',
            margin: '0 auto',
            marginTop: '-10px',
        },
    },

    caption: {
        color: tokens.colorNeutralForeground3,

        '@media (max-width: 768px)': {
            fontSize: '0.65rem',
            position: 'relative',
            marginLeft: '-10px',
            top: '-10px',
        },
    },

    container: {
        width: '200px',
        borderBottomLeftRadius: tokens.borderRadiusMedium,
        borderBottomRightRadius: tokens.borderRadiusMedium,

        fontSize: '32px',
        ':hover': {
            [`& .${iconFilledClassName}`]: {
                display: 'block',
            },
            [`& .${iconRegularClassName}`]: {
                display: 'none',
            },
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        ...shorthands.padding('60px', '10px'),
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        ...ScrollBarStyles,
    },
    header: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        backgroundColor: tokens.colorNeutralBackground1,
        textAlign: 'center',
        height: '150px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',

        '@media (max-width: 768px)': {
            height: 'auto',
            padding: '10px',
        },
    },
    title: {
        marginTop: '70px',
        marginBottom: '20px',
        fontSize: '5em',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'center',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,

        '@media (max-width: 768px)': {
            fontSize: '3.5em',
            marginTop: '20px',
        },
    },
    searchBar: {
        margin: '0 auto',
        marginLeft: '-140px',
        marginBottom: '30px',
        marginTop: '40px',
        width: '120%',
        height: '52px',
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    featuredSection: {
        textAlign: 'left',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    gptList: {
        marginTop: '-40px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '85%',
        height: '100%',
        //...shorthands.overflow('auto'),
    },
    arrowUnfocused: {
        fill: customColors.darkNavy,
        stroke: customColors.darkNavy,
    },
    arrowFocused: {
        fill: customColors.protivitiOrange,
        stroke: customColors.protivitiOrange,
    },
    buttonUnfocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', '#979797'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
    buttonFocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        ...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
    allGPTsText: {
        fontSize: '35px',
        fontWeight: '600',
        margin: '0',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
        '@media (max-width: 768px)': {
            fontSize: '25px',
            marginTop: '-30px',
            marginLeft: '-50px'
        },
    },
    registerGPTText: {
        fontFamily: customFonts.TitleFont,
        fontWeight: 'bold',
        fontSize: '1.2rem',
        wordWrap: 'break-word',
        textAlign: 'left',
        '@media (max-width: 768px)': {
            fontSize: '1rem',
            position: 'relative',
            top: '-1px',
        },
    },
});
interface RootState {
    app: AppState;
    users: UsersState;
}

export const ManageGPTWindow: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const gpt = useGPT();
    const navigate = useNavigate();
    let showSearchBar = false;
    const AddCircle = bundleIcon(AddCircleFilled, AddCircleRegular);
    const [gpts, setGpts] = useState<IGPT[]>([]);
    const [isFocused, setIsFocused] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const gptList = useSelector((state: RootState) => state.app.gptList);

    const [userRole, setUserRole] = useState<string | null>(null);
    // const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    // const { instance, inProgress } = useMsal();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    // const { users } = useAppSelector((state: RootState) => state.users);
    const { fetchUserRole } = useUserRole();


    useEffect(() => {
        const fetchRoleForUser = async () => {
            const email = activeUserInfo?.email;
            if (!email) return;

            try {

                const role = await fetchUserRole(email); // Use the hook's logic
                if (role !== null) {
                    setUserRole(role);  // Set local state to display the role
                }
            } catch (error) {
                console.error('Error fetching role in ManageGPTWindow:', error);
            }
        };

        fetchRoleForUser();
    }, [activeUserInfo?.email, fetchUserRole]);

    if (gpts.length > 6) showSearchBar = true;

    function sortGPTs(GPTs: IGPT[]) {
        const gptsSorted = [...GPTs];
        gptsSorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        return gptsSorted;
    }

    const filteredGPTs = useMemo(
        () =>
            gpts.filter(
                (gpt) =>
                    gpt.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.cardShortDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.tag.toLowerCase().includes(searchQuery.toLowerCase()),
            ),
        [gpts, searchQuery],
    );

    const fetchGPTs = useCallback(async () => {
        let fetchedGPTs = await gpt.getRegisteredGPTs();

        if (userRole === '0') { //list all gpts for admin
            const allGPTs = await gpt.getAllGPTs(); //list of all gpts for admin
            fetchedGPTs = allGPTs;
        }

        setGpts(sortGPTs(fetchedGPTs));
        dispatch(removeAlert(0));
    }, [userRole]);

    useEffect(() => {
        if (userRole) {
            fetchGPTs();
        }
    }, [fetchGPTs, gptList]);

    const handleClick = () => {
        //Dispatch an action when the button is clicked
        navigate('/register-gpt');
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <Text as="h1" className={classes.title}>
                    Manage My GPTs
                </Text>

                {showSearchBar && (
                    <div className={classes.searchBar}>
                        <Input
                            style={{ width: '50%', height: '50px' }}
                            contentBefore={<Search24Regular />}
                            contentAfter={
                                <Button
                                    appearance="transparent"
                                    className={isFocused ? classes.buttonFocused : classes.buttonUnfocused}
                                    style={{
                                        transition: 'all 0.3s ease',
                                        marginRight: '-2px',
                                    }}
                                    icon={
                                        <Arrow className={isFocused ? classes.arrowFocused : classes.arrowUnfocused} />
                                    }
                                />
                            }
                            placeholder="Search GPTs"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onFocus={() => {
                                setIsFocused(true);
                            }}
                            onBlur={() => {
                                setIsFocused(false);
                            }}
                        />
                    </div>
                )}
            </div>
            { }

            <div className={classes.tabContent}>
                <div className={classes.featuredSection}>
                    <div
                        className={classes.header}
                        style={{
                            width: '75%',
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: showSearchBar ? '80px' : '30px',
                        }}
                    >
                        <Text className={classes.allGPTsText}>
                            All GPTs
                        </Text>

                        {userRole == '0' && (
                            <Card className={classes.card} orientation="horizontal" appearance="subtle">
                                <CardPreview className={classes.horizontalCardImage}>
                                    <div className={classes.container}>
                                        <AddCircle className={classes.horizontalCardImage} onClick={handleClick} />
                                    </div>
                                </CardPreview>

                                <CardHeader
                                    header={
                                        <Text className={classes.registerGPTText}>
                                            Register GPT
                                        </Text>
                                    }
                                    description={
                                        <Text className={classes.caption} weight="semibold">
                                            Register a specialized GPT
                                        </Text>
                                    }
                                />
                            </Card>
                        )}
                    </div>
                    <div className={classes.gptList}>
                        {filteredGPTs.map((gpt) => (
                            <div key={gpt.id}>
                                <ManageGPTTile gpt={gpt} userRole={userRole} />
                            </div>
                        ))}
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};
