// ChatMemorySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialChatMemoryState } from './chatMemoryState';
import { ChatMemorySource } from '../../../libs/models/ChatMemorySource';

const chatMemorySlice = createSlice({
    name: 'chatMemory',
    initialState: initialChatMemoryState,
    reducers: {
        setChatMemorySources(state, action: PayloadAction<{ chatId: string; sources: ChatMemorySource[] }>) {
            console.log('1 ChatMemorySources slice:', action.payload.sources);
            state.chatMemorySources = {
                ...state.chatMemorySources,
                [action.payload.chatId]: action.payload.sources,
            };
            console.log(' 2 ChatMemorySources slice:', action.payload.sources);
        },
        setChatLoading(state, action: PayloadAction<{ chatId: string; isLoading: boolean }>) {
            console.log(' 3 ChatLoading slice:', action.payload.isLoading);
            state.chatLoading = {
                ...state.chatLoading,
                [action.payload.chatId]: action.payload.isLoading,
            };
        },
        clearChatMemorySources(state) {
            state.chatMemorySources = {};
        },
    },
});

export const { setChatMemorySources, setChatLoading, clearChatMemorySources } = chatMemorySlice.actions;
export default chatMemorySlice.reducer;
