import React from 'react';
import { makeStyles } from '@fluentui/react-components';

const useClasses = makeStyles({
    codeArea: {
        position: 'relative',
        overflow: 'auto',
    },
});

export interface OutputCodeBlockProps {
    className?: string;
    children?: React.ReactNode;
}

export const OutputCodeBlock: React.FC<OutputCodeBlockProps> = ({ children }) => {
    const classes = useClasses();

    return <code className={classes.codeArea}>{children}</code>;
};
