import { Button } from '@fluentui/react-button';
import { makeStyles, shorthands, Textarea, Checkbox, Tooltip } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { customColors, customFonts } from '../../../../styles';
import { useEffect, useState } from 'react';
import type { CheckboxProps } from '@fluentui/react-components';
import { Info16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        ...shorthands.borderRadius('0'),
    },
    delete: {
        backgroundColor: customColors.protivitiOrange,
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
    button: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),
    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '24px',
        paddingBottom: '10px',
    },
    saveButton: {
        ...shorthands.borderRadius('0'),
        backgroundColor: customColors.white,
        width: 'max-content',
        fontWeight: '700',
        fontFamily: customFonts.Lato,
        fontSize: '13.5px',
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),

        '&: hover > button, &: hover': {
            backgroundColor: customColors.protivitiOrange,
        },
    },
    closeButton: {
        backgroundColor: customColors.white,
        width: 'max-content',
        fontWeight: '700',
        fontFamily: customFonts.Lato,
        fontSize: '13.5px',
        ...shorthands.borderRadius('0'),
    },
    textArea: {
        maxHeight: '520px',
        width: '100%',
        ...shorthands.borderColor('#D9D9D9'),
        ...shorthands.padding('10px', 0, 0, 0),
        marginBottom: '15px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '15px',
        '@media (max-width: 768px)': {
            marginTop: '10px',
        },
    },
});
const Rows = 16;
interface MetaPromptDialogProps {
    onSaveButtonClick: (savePrompt: boolean, value: string, checked: CheckboxProps['checked'], title: string) => void;
    prompt: string;
    chatId: string;
    onClose: () => void;
    numSavedPersonas: number;
}

export const MetaPromptDialog: React.FC<MetaPromptDialogProps> = ({
    onSaveButtonClick,
    prompt,
    chatId,
    onClose,
    numSavedPersonas,
}) => {
    const classes = useClasses();
    const [value, setValue] = useState<string>('');
    const [checked, setChecked] = useState<CheckboxProps['checked']>(false);
    const [title, setTitle] = useState<string>('');

    const handleSave = async () => {
        onSaveButtonClick(true, value, checked, title);
        setValue('');
        setChecked(false);
        setTitle('');
        onClose();
    };
    const handleClose = () => {
        onClose();
        setValue(prompt);
        setChecked(false);
        setTitle('');
    };
    const handleTitleChange = (newValue: string) => {
        if (newValue.length <= 20) {
            setTitle(newValue);
        }
    };
    useEffect(() => {
        // Taking a dependency on the chatId because the value state needs
        // to be reset when the chatId changes. Otherwise, the value may
        // not be updated when the user switches between chats that has
        // the same prompt.
        if (prompt !== '') {
            setValue(prompt);
        }
    }, [chatId, prompt]);

    return (
        <Dialog open={true}>
            {/* <DialogTrigger>
                <Button className={classes.saveButton} onClick={() => setIsOpen(true)}>
                    Edit
                </Button>
            </DialogTrigger> */}
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle className={classes.title}>Persona</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Textarea
                            className={classes.textArea}
                            size="medium"
                            resize="vertical"
                            value={value}
                            rows={Rows}
                            onChange={(_event, data) => {
                                setValue(data.value);
                            }}
                        />
                        <div style={checked ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                            <span className={classes.title}>
                                Persona Title{' '}
                                <Tooltip
                                    content="The title your persona will be saved with. You can save up to 20 personas across both normal and document chat sessions."
                                    relationship="label"
                                >
                                    <Button icon={<Info16 />} appearance="transparent" />
                                </Tooltip>
                            </span>
                            <Textarea
                                size="medium"
                                value={title}
                                onChange={(_event, data) => {
                                    handleTitleChange(data.value);
                                }}
                                style={{ height: '32px' }}
                            />
                        </div>
                        <Checkbox
                            label={`Add to Your Personas (${numSavedPersonas}/20)`}
                            onChange={(_event, data) => setChecked(data.checked)}
                            disabled={numSavedPersonas >= 20}
                        />
                        <DialogActions className={classes.dialogActions}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" className={classes.closeButton} onClick={handleClose}>
                                    Close
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" className={classes.saveButton} onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
