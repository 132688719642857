import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { Plugin } from '../../redux/features/plugins/PluginsState';
import { addPlugin } from '../../redux/features/plugins/pluginsSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import {
    PluginManifest,
    requiresOAuth,
    requiresServiceLevelAuth,
    requiresUserLevelAuth,
} from '../models/PluginManifest';
import { PluginService } from '../services/PluginService';

export const usePlugins = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const pluginService = React.useMemo(() => new PluginService(process.env.REACT_APP_BACKEND_URI as string), []);

    const addCustomPlugin = async (manifest: PluginManifest, manifestDomain: string) => {
        const newPlugin: Plugin = {
            name: manifest.name_for_human,
            nameForModel: manifest.name_for_model,
            publisher: 'Custom Plugin',
            description: manifest.description_for_human,
            enabled: false,
            authRequirements: {
                personalAccessToken: requiresUserLevelAuth(manifest.auth),
                OAuth: requiresOAuth(manifest.auth),
                service: requiresServiceLevelAuth(manifest.auth),
            },
            headerTag: manifest.name_for_model,
            icon: manifest.logo_url,
            manifestDomain: manifestDomain,
        };

        // TODO: add new plugins to storage
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const approvedPlugin = await pluginService.addApprovedPluginsAsync(accessToken, newPlugin);
            dispatch(addPlugin(approvedPlugin));
        } catch {
            const errorMessage = `Unable to get approved plugins. Please refresh the page or contact support.`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const getApprovedPlugins = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const approvedPlugins = await pluginService.getApprovedPluginsAsync(accessToken);
            if (approvedPlugins.length > 0) {
                for (const approvedPlugin of approvedPlugins) {
                    dispatch(addPlugin(approvedPlugin));
                }
            }
        } catch {
            const errorMessage = `Unable to get approved plugins. Please refresh the page or contact support.`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const setPluginStateAsync = async (chatId: string, pluginId: string, enabled: boolean): Promise<void> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await pluginService.setPluginStateAsync(chatId, pluginId, accessToken, enabled);
    };

    return {
        addCustomPlugin,
        getApprovedPlugins,
        setPluginStateAsync,
    };
};