import { IFileItem } from '../../components/engagementaccelerator/components/inputs/FileList';
import { EADropdownValues } from '../models/EADropdownValues';
import { IEngagement } from '../models/Engagement';
import { EngagementDocument, EngagementFiles } from '../models/EngagementDocument';
import { BaseService } from './BaseService';

export class EAService extends BaseService {
    public getEngagementsByNameAsync = async (accessToken: string, nameSearch: string): Promise<IEngagement[]> => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            const commandPath = 'ea/getEngagements?nameSearch=' + nameSearch;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };

    public getEngagementAsync = async (accessToken: string, engagementId: string): Promise<IEngagement> => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            const commandPath = `ea/getEngagement/${engagementId}`;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };
    public uploadDocumentsAsync = async (accessToken: string, threadId: string, filesToBeUpload: IFileItem[]) => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });
        headers.append('Content-Type', 'application/json');
        const formData = new FormData();

        filesToBeUpload.map((file) => {
            formData.append('files', file.file as File);
        });

        try {
            const commandPath = `ea/ImportDocument/${threadId}`;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'POST',
                headers,
                body: formData,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };

    public uploadEngagementFilesToAssitantAsync = async (
        accessToken: string,
        engagementId: string,
        threadId: string,
        files: EngagementDocument[],
    ): Promise<EngagementDocument> => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            const commandPath = `/ea/UploadEngagementFileToAssistant/${engagementId}/${threadId}`;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'POST',
                headers,
                body: JSON.stringify(files),
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };
    public getEngagementFilesAsync = async (accessToken: string, engagementId: string): Promise<EngagementFiles> => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');

        try {
            const commandPath = `/ea/getEngagementFiles/${engagementId}`;
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };
    public getEADropdownValuesAsync = async (accessToken: string): Promise<EADropdownValues> => {
        const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
        });

        headers.append('Content-Type', 'application/json');
        try {
            const commandPath = '/ea/getDropdownValues';
            const requestUrl = new URL(commandPath, process.env.REACT_APP_BACKEND_URI);
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers
            });

            if (!response.ok) {
                const responseText = await response.text();
                const errorMessage = `${response.status}: ${response.statusText}${
                    responseText ? ` => ${responseText}` : ''
                }`;

                throw Object.assign(new Error(errorMessage));
            }

            const data = await response.json(); // Parse the response as JSON
            return data; // Return the parsed JSON data
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };

}
