import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    makeStyles,
    shorthands,
    Tooltip,
} from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import promptSuggestions from '../../../prompt-library.json';
import { customColors, customFonts, ScrollBarStyles } from '../../../styles';
import { ToolTipBlock } from './ToolTipBlock';
import { IGPT } from '../../../libs/models/GPT';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import ToolTipBlockWrapper from './ToolTipBlockWrapper';
import { SavedToolTipBlock } from './SavedToolTipBlock';
import { ISavedPrompt } from '../../../libs/models/ISavedPrompt';
import { Add16Filled } from '@fluentui/react-icons';
import { useUser } from '../../../libs/hooks/useUser';
import SaveDialog from '../../utils/SaveDialog';

const useClasses = makeStyles({
    toolTipcontainer: {
        backgroundColor: customColors.white,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        ...ScrollBarStyles,

        //maxHeight:'450px',
    },
    header: {
        ...shorthands.padding(0, 0, 0, '20px'),
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: customFonts.Lato,
    },
    toolTipRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    largeBlockContainer: {
        ...shorthands.padding('20px', 0, 0, 0),
    },
    suggestionContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 850px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        // '@media (max-width: 700px)' : {
        //     gridTemplateColumns: 'repeat(1, 1fr)',
        // }
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: customFonts.Lato,
        color: customColors.summarizeButtonDarker,
    },
});

interface PopoverContentProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    isDocumentChat: boolean;
    selectedId: string;
}

export const PopoverContent = ({ setValue, isDocumentChat, selectedId }: PopoverContentProps) => {
    const classes = useClasses();
    const userService = useUser();
    const _chatType = isDocumentChat ? 'Document' : 'Regular';
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const [selectedGPT, setSelectedGPT] = useState<IGPT | undefined>(undefined);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const { userPrompts } = useAppSelector((state: RootState) => state.userpreferences);
    const [filteredPrompts, setFilteredPrompts] = useState<ISavedPrompt[]>([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [_newPromptText, setNewPromptText] = React.useState('');
    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId]?.gptEndpoint && gpt.isActive);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    useEffect(() => {
        setFilteredPrompts(
            userPrompts.filter((item) => {
                if (isDocumentChat) {
                    if (item.isDocumentChat && item.gptEndpoint === conversations[selectedId].gptEndpoint) {
                        return true;
                    }
                    return false;
                } else {
                    return item.gptEndpoint === conversations[selectedId].gptEndpoint && !item.isDocumentChat;
                }
            }),
        );
    }, [userPrompts]);

    const handleSavePrompt = async (newPromptText: string) => {
        const userId = conversations[selectedId].userId;
        await userService.createPrompt(
            userId,
            newPromptText,
            conversations[selectedId]?.gptEndpoint,
            isDocumentChat ?? false,
        );
        setIsDialogOpen(false);
        setNewPromptText('');
    };
    return (
        <div className={classes.toolTipcontainer}>
            <Accordion collapsible multiple>
                <div style={{ padding: '20px' }}>
                    <AccordionItem value="1">
                        <AccordionHeader>
                            <span className={classes.headerText}>PROMPT SUGGESTIONS</span>
                        </AccordionHeader>
                        <AccordionPanel>
                            {selectedGPT?.id === process.env.REACT_APP_DEFAULT_PROGPT ? (
                                Object.keys(promptSuggestions).map((chatType) => {
                                    if (chatType === _chatType) {
                                        const categorySuggestions =
                                            promptSuggestions[chatType as keyof typeof promptSuggestions];
                                        return Object.keys(categorySuggestions).map((childCategory, childIndex) => {
                                            const suggestions =
                                                categorySuggestions[childCategory as keyof typeof categorySuggestions];
                                            return (
                                                <ToolTipBlock
                                                    key={childIndex}
                                                    category={childCategory}
                                                    suggestions={suggestions}
                                                    setValue={setValue}
                                                />
                                            );
                                        });
                                    } else {
                                        return <div key="5"></div>;
                                    }
                                })
                            ) : (
                                <ToolTipBlockWrapper promptLibrary={selectedGPT?.promptLibrary} setValue={setValue} />
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                </div>
                <div style={{ padding: '20px' }}>
                    <AccordionItem value="2">
                        <div style={{ display: 'flex' }}>
                            <AccordionHeader>
                                <span className={classes.headerText}>YOUR PROMPTS</span>{' '}
                            </AccordionHeader>
                            <Tooltip content="Save new prompts across your chat sessions" relationship="label">
                                <Button
                                    icon={<Add16Filled />}
                                    style={{ marginRight: '10px' }}
                                    onClick={(e) => {
                                        setIsDialogOpen(true);
                                        e.stopPropagation();
                                    }}
                                    shape="circular"
                                    appearance="transparent"
                                />
                            </Tooltip>
                        </div>
                        <AccordionPanel>
                            <SavedToolTipBlock suggestions={filteredPrompts} setValue={setValue} />
                        </AccordionPanel>
                    </AccordionItem>
                </div>
            </Accordion>
            {isDialogOpen && (
                <SaveDialog
                    tooltipContent="Enter the prompt you would like to save"
                    textAreaValue=""
                    title="Prompt"
                    onClose={() => setIsDialogOpen(false)}
                    onSave={handleSavePrompt}
                />
            )}
        </div>
    );
};
