import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { toast } from 'react-toastify';
import { customFonts } from '../../styles';
import { makeStyles } from '@fluentui/react-components';
import { EAService } from '../services/EAService';
import { EADropdownValues } from '../models/EADropdownValues';

import { EngagementDocument } from '../models/EngagementDocument';
import { IFileItem } from '../../components/engagementaccelerator/components/inputs/FileList';

const useStyles = makeStyles({
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

export const useEAPermission = () => {
    //const dispatch = useDispatch();
    const { instance, inProgress } = useMsal();
    const eaService = new EAService(process.env.REACT_APP_BACKEND_URI as string);
    const classes = useStyles();

    const getEngagementsAsync = async (nameSearch: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const engagements = await eaService.getEngagementsByNameAsync(accessToken, nameSearch);
            return engagements;
        } catch {
            const errorMessage = `Unable to get Engagements. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return [];
    };

    const getEngagementAsync = async (engagementId: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const engagement = await eaService.getEngagementAsync(accessToken, engagementId);
            return engagement;
        } catch {
            const errorMessage = `Unable to get Engagement. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return [];
    };

    const getEADropdownValuesAsync = async () => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const dropDownValues = await eaService.getEADropdownValuesAsync(accessToken);
            return dropDownValues;
        } catch {
            const errorMessage = `Unable to get EA dropdown values. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return {} as EADropdownValues;
    }

    return {
        getEngagementsAsync,
        getEngagementAsync,
        getEADropdownValuesAsync
    };
};

export const useEngagementDocument = () => {
    //const dispatch = useDispatch();
    const { instance, inProgress } = useMsal();
    const eaService = new EAService(process.env.REACT_APP_BACKEND_URI as string);
    const classes = useStyles();

    const uploadDocumentsAsync = async (engagementId: string, filesToBeUploaded: IFileItem[]) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            await toast
                .promise(eaService.uploadDocumentsAsync(accessToken, engagementId, filesToBeUploaded), {
                    pending: {
                        render: 'Uploading files...',
                        position: 'top-right',
                        className: classes.toastMessage,
                    },
                })
                .then(() => {
                    toast.success('Documents uploaded successfully!', { className: classes.toastMessage });
                });
        } catch {
            const errorMessage = `Unable to uploaded Engagement. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
    };

    const uploadEngagementFilesToAssitantAsync = async (
        engagementId: string,
        threadId: string,
        files: EngagementDocument[],
    ) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const engagement = await eaService.uploadEngagementFilesToAssitantAsync(
                accessToken,
                engagementId,
                threadId,
                files,
            );
            return engagement;
        } catch {
            const errorMessage = `Unable to get Engagement. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return [];
    };

    const getEngagementFilesAsync = async (engagementId: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const engagement = await eaService.getEngagementFilesAsync(accessToken, engagementId);
            return engagement;
        } catch {
            const errorMessage = `Unable to get Engagement. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
        return [];
    };

    return {
        uploadDocumentsAsync,
        uploadEngagementFilesToAssitantAsync,
        getEngagementFilesAsync,
    };
};
