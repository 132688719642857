import React, { useState } from 'react';
import { makeStyles, shorthands, Button, Tooltip } from '@fluentui/react-components';
import { Copy16Regular, Checkmark16Regular } from '@fluentui/react-icons';
import { OutputCodeBlockProps } from './OutputCodeBlock';
import { customColors } from '../../../../styles';
import { DiagramDialog } from '../../chat-list/dialogs/DigramDialog';

const useClasses = makeStyles({
    container: {
        position: 'relative',
        maxHeight: '100%',
        marginBottom: '10px',
        transition: 'height 0.2s ease-in-out',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 1)',
        ...shorthands.margin('0px'),
    },
    collapsed: {
        height: '0',
        maxHeight: '0',
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-in-out',
    },
    expanded: {
        maxHeight: '50%',
        transition: 'max-height 0.2s ease-in-out',
    },
    preContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        maxHeight: '83vh',
        ...shorthands.margin('0px'),
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.overflow('auto'),
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f5f5f5',
        color: customColors.summarizeButtonDarker,
        cursor: 'text',
    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: '6px',
    },

    codeLanguageArea: {
        color: '#fff',
        backgroundColor: customColors.summarizeButtonDarker,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },

    copyButton: {
        position: 'absolute',
        right: '12px',
        zIndex: 1,
        cursor: 'pointer',
        '&:hover': {},
    },

    successCheckMark: {
        color: 'green',
        fontWeight: 'bold',
    },
    spinner: {
        '& > span > svg > circle:nth-child(2)': {
            path: customColors.protivitiOrange,
            stroke: customColors.protivitiOrange,
        },
    },
});

interface OutputCanvasPreBlockProps {
    children?: React.ReactNode;
}

export const OutputCanvasPreBlock: React.FC<OutputCanvasPreBlockProps> = ({ children }) => {
    const classes = useClasses();
    const [copySuccess, setCopySuccess] = useState(false);
    const [isHovered, setHovered] = useState(true);
    const codeElement = children as React.ReactElement<OutputCodeBlockProps>;
    const languageClassName = codeElement.props.className ?? '';
    const languageMatch = /language-(\w+)/.exec(languageClassName);
    const language = languageMatch ? languageMatch[1] : '';

    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(codeElement.props.children?.toString() ?? '')
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => {
                    setCopySuccess(false);
                }, 2000);
            })
            .catch((err) => {
                console.error('Failed to copy to clipboard', err);
                setCopySuccess(false);
            });
    };

    return (
        <>
            <div
                className={`${classes.container}`}
                onMouseOver={() => {
                    setHovered(true);
                }}
                onMouseOut={() => {
                    setHovered(false);
                }}
            >
                {language && (
                    <div id="language" className={classes.codeLanguageArea}>
                        {language}
                    </div>
                )}
                <pre className={classes.preContainer}>{children}</pre>

                {isHovered && (
                    <div className={classes.buttonContainer} style={{ top: language ? '30px' : '7px' }}>
                        {language === 'mermaid' && (
                            <div>
                                <DiagramDialog graphDefinition={codeElement.props.children as string} />
                            </div>
                        )}

                        <div>
                            <Tooltip content="Copy Code" relationship="label">
                                <Button
                                    icon={
                                        copySuccess ? (
                                            <Checkmark16Regular className={classes.successCheckMark} />
                                        ) : (
                                            <Copy16Regular />
                                        )
                                    }
                                    onClick={handleCopyToClipboard}
                                    onMouseOver={() => {
                                        setHovered(true);
                                    }}
                                    onMouseOut={() => {
                                        setHovered(false);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};