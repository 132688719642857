import { ComponentType, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setRoleLoading, setUsersRole } from '../redux/features/users/usersSlice';
import { useUserRole } from '../libs/hooks/useUserRole';

const withRoleProtection = (WrappedComponent : ComponentType<any>, allowedRoles: string[]) => {
    return (props: any) => {
        const [userRole, setUserRole] = useState('');
       // const { instance, inProgress } = useMsal();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
        const usersState = useSelector((state: RootState) => state.users.users);
        //const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
        const { fetchUserRole } = useUserRole();
        
        useEffect(() => {
            const fetchRoleForUser = async () => {
                const email = activeUserInfo?.email;
                if (!email) return;
        
                // Check if the role or loading state is already in Redux
                const userFromStore = usersState[email];
                console.log('userFromStore withRoleProtection', userFromStore);
        
                if (userFromStore?.role) {
                    console.log('Using cached role for:', email);
                    setUserRole(userFromStore.role); // Use the cached role from Redux
                    return;
                }
        
                if (userFromStore?.loading) {
                    console.log('Role fetching already in progress for:', email);
                    return; // Exit if a fetch is already in progress
                }
        
                try {
                    // Dispatch action to set loading state
                    dispatch(setRoleLoading({ email, isLoading: true }));
                    console.log('API getting called from withRoleProtection for email:', email);
        
                    // Fetch the role from the API
                    const role = await fetchUserRole(email);
        
                    if (role !== null) {
                        dispatch(setUsersRole({ email, role })); // Store the role in Redux
                        setUserRole(role); // Update local state with the fetched role
                    }
                } catch (error) {
                    console.error('Error fetching user role in withRoleProtection:', error);
                } finally {
                    // Reset loading state
                    dispatch(setRoleLoading({ email, isLoading: false }));
                }
            };
        
            if (activeUserInfo?.email) {
                fetchRoleForUser(); // Run this effect when the email changes or user information is updated
            }
        }, [activeUserInfo?.email, fetchUserRole, dispatch, usersState]);
        
        // Redirect if the user's role is not allowed
        useEffect(() => {
            if (userRole && !allowedRoles.includes(userRole)) {
                navigate('/'); // Redirect to a "Not Authorized" page
            }
        }, [userRole, allowedRoles, navigate]);

        return allowedRoles.includes(userRole) ? <WrappedComponent {...props} /> : null;
    };
};

export default withRoleProtection;