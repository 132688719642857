import {
    Input,
    SelectTabEventHandler,
    Tab,
    TabList,
    Text,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { IGPT } from '../../libs/models/GPT';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';
import { Breakpoints, customColors, customFonts } from '../../styles';
import RatingFeedback from '../rating/RatingFeedback';
import { ExploreGPTTile } from './ExploreGPTTile';
import { useGPT } from '../../libs/hooks/useGPT';
import GPTOverview from '../custom-gpt/GPTOverview';
import { EALanding } from '../engagementaccelerator/EALanding';
import ExploreGPTBanner from '../../assets/ExploreGPTBanner.png'

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        ...shorthands.padding('60px', '10px'),
        backgroundColor: '#F7F7F8',
        color: tokens.colorNeutralForeground1,
        ...shorthands.overflow('auto'),
        '@media (max-width: 576px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    header: {
        width: '85%',
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${ExploreGPTBanner})`,
        padding: '20px 20px 20px 20px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        gap: '10px',
        marginBottom: '20px',
        '@media (max-width: 1500px)': {
            backgroundSize: '100% 100%'
        }
    },
    title: {
        fontSize: '2em',
        marginTop: '10px',
        textAlign: 'center',
        lineHeight: '24px',
        color: customColors.white,
        '@media (max-width: 576px)': {
            fontSize: '1.6em',
        }
    },
    subtitle: {
        fontSize: '1.3em',
        textAlign: 'center',
        color: customColors.white,
        '@media (max-width: 576px)': {
            fontSize: '1.2em'
        }
    },
    searchBar: {
        width: '50%',
        minWidth: '550px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        ...Breakpoints.small({
            minWidth: '350px'
        }),
        '@media (max-width: 576px)': {
            minWidth: '250px'
        }
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke1),
    },
    tab: {
        ...shorthands.padding('10px', '20px'),
        ...shorthands.margin('0', '5px'),
        backgroundColor: '#fffff',
        cursor: 'pointer',
        ...shorthands.borderRadius('5px'),
        ...shorthands.border('none'),
        color: tokens.colorNeutralForeground1,
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '85%',
        '@media (max-width: 1175px)': {
            justifyContent: 'center',
        },
    },
    featuredSection: {
        textAlign: 'left',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground1,
        padding: '0px 10px 0px 10px',
        '@media screen and (min-width: 1151px) and (max-width: 1674px)': {
            width: '100%',
        },
        '@media screen and (max-width: 1150px)': {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    featuredHeader: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'flex-start',
        verticalAlign: 'top',
        '@media screen and (max-width: 1500px)': {
            width: '100%',
        },
    },
    gptList: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        ...shorthands.overflow('auto'),
        '@media screen and (max-width: 1700px)': {
            justifyContent: 'space-evenly',
        },

    },
    exploreTileWrapper: {
        marginBottom: '10px',
        height: '150px',
    },
    arrowUnfocused: {
        fill: customColors.darkNavy,
        stroke: customColors.darkNavy,
    },
    arrowFocused: {
        fill: customColors.protivitiOrange,
        stroke: customColors.protivitiOrange,
    },
    buttonUnfocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', '#979797'),
        //...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
    buttonFocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        ...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
    searchBarInput: {
        width: '100%',
        height: '50px',
    }
});

export const ExploreGPTWindow: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const gptService = useGPT();
    const [searchQuery, setSearchQuery] = useState('');
    const [gpts, setGpts] = useState<IGPT[]>([]);
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
    const [isEALandingOpen, setIsEALandingOpen] = useState(false);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [_isFocused, setIsFocused] = React.useState(false);
    const [userId] = useState<string | undefined>(activeUserInfo?.id);
    const [customGPTId, setCustomGPTId] = useState<string | undefined>(undefined);
    userId: activeUserInfo?.id as string;
    const [activeTab, setActiveTab] = useState('gpts');
    const [accelerators, setAccelerators] = useState<IGPT[]>([]);
    const onTabSelect: SelectTabEventHandler = (_event, data) => {
        setActiveTab(data.value as string);
    };

    function sortGPTs(GPTs: IGPT[]) {
        const gptsSorted = [...GPTs];
        gptsSorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        return gptsSorted;
    }

    useEffect(() => {
        setGpts(sortGPTs(userGPTs.filter((gpt) => gpt.isAccelerator == (false || undefined))));
        dispatch(removeAlert(0));
    }, []);

    useEffect(() => {
        const acceleratorsList = userGPTs.filter((gpt) => gpt.isAccelerator === true);
        setAccelerators(acceleratorsList);
    }, [userGPTs]);

    const filteredGPTs = useMemo(
        () =>
            gpts.filter(
                (gpt) =>
                    gpt.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.cardShortDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (gpt.tag.toLowerCase().includes(searchQuery.toLowerCase()) && gpt.isRegistered && !gpt.isDeleted),
            ),
        [gpts, searchQuery],
    );

    const filteredAccelerators = useMemo(
        () =>
            accelerators.filter(
                (gpt) =>
                    gpt.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.cardShortDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (gpt.tag.toLowerCase().includes(searchQuery.toLowerCase()) && gpt.isRegistered && !gpt.isDeleted),
            ),
        [accelerators, searchQuery],
    );

    const allGPTs = useMemo(() => [...filteredGPTs, ...filteredAccelerators], [filteredGPTs, filteredAccelerators]);

    const updateGpts = async () => {
        const updatedGpts = await gptService.getGPTs();
        setAccelerators(updatedGpts.filter((gpt) => gpt.isAccelerator === true));
        setGpts(sortGPTs(updatedGpts.filter((gpt) => gpt.isAccelerator == (false || undefined))));
    };

    /*const handleButtonClick = (event: React.MouseEvent, gptId: string) => {
        event.stopPropagation();
        //  userId = activeUserInfo?.id as string;
        setIsRatingOpen(true);
        //setUserId(userId);
        setCustomGPTId(gptId);
    };*/

    const handleCloseRating = () => {
        setIsRatingOpen(false);
    };

    const handleLearnMore = (gptId: string) => {
        setCustomGPTId(gptId);
        setIsLearnMoreOpen(true);
    };

    const handleCloseLearnMore = () => {
        setIsLearnMoreOpen(false);
    };

    const handleEALanding = (gptId: string) => {
        setCustomGPTId(gptId);
        setIsEALandingOpen(true);
    };

    const handleCloseEALanding = () => {
        setIsEALandingOpen(false);
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
            >
                <Text className={classes.title}>
                    Protiviti Specialized GPT Library
                </Text>
                <Text as="p" className={classes.subtitle}>
                    Explore Protiviti's specialized GPTs built upon our intellectual property
                </Text>

                <div className={classes.searchBar}>
                    <Input
                        className={classes.searchBarInput}
                        contentBefore={<Search24Regular />}
                        placeholder="Search Specialized GPTs"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                    />
                </div>
                <div>
                    <TabList selectedValue={activeTab} onTabSelect={onTabSelect} appearance="subtle">
                        <Tab data-testid="allTab" id="all" value="all" className={classes.tab}>
                            All
                        </Tab>
                        <Tab data-testid="gptsTab" id="gpts" value="gpts" className={classes.tab}>
                            Custom GPTs
                        </Tab>
                        <Tab data-testid="acceleratorsTab" id="accelerators" value="accelerators" className={classes.tab}>
                            Accelerators
                        </Tab>
                    </TabList>
                </div>
            </div>

            {/* TODO: IMPLEMENT FUNCTIONAL TABS -- PLEASE DO NOT REMOVE THE COMMENTED CODE BELOW
             {
                <div className={classes.tabContainer} style={{ width: '50%' }}>
                    <Button
                        className={classes.tab}
                        style={{ backgroundColor: tokens.colorBrandBackgroundHover, color: 'white' }}
                    >
                        Top Picks
                    </Button>
                    <Button className={classes.tab}>IAFA</Button>
                    <Button className={classes.tab}>TC</Button>
                    <Button className={classes.tab}>R&C</Button>
                    <Button className={classes.tab}>Digital</Button>
                    <Button className={classes.tab}>General</Button>
                </div>
            } */}
            <div className={classes.tabContent}>
                {activeTab === 'all' && (
                    <div className={classes.featuredSection}>
                        <div className={classes.featuredHeader}>
                            <Text
                                as="h1"
                                style={{
                                    // marginTop: '150px',
                                    marginBottom: '2px',
                                    fontSize: '25px',
                                    fontWeight: '500',
                                    color: customColors.summarizeButtonDarker,
                                    fontFamily: customFonts.TitleFont,
                                }}
                            >
                                All Protiviti GPTs & Accelerators
                            </Text>
                        </div>

                        <div className={classes.gptList}>
                            {allGPTs.map(
                                (gpt) =>
                                    !JSON.parse(process.env.REACT_APP_EXPLOREGPT_PAGE_EXCEPTIONS || "[]").includes(gpt.id) && (
                                        <div key={gpt.id} className={classes.exploreTileWrapper}>
                                            <ExploreGPTTile
                                                gpt={gpt}
                                                isFunctionTriggered={gpt.gptEndpoint.includes('function')}
                                                handleLearnMore={handleLearnMore}
                                                handleEALanding={handleEALanding}
                                            />
                                        </div>
                                    ),
                            )}
                        </div>
                        {isLearnMoreOpen && (
                            <GPTOverview
                                gptId={customGPTId}
                                isModal={true}
                                isOpen={isLearnMoreOpen}
                                onClose={handleCloseLearnMore}
                            />
                        )}
                        {isEALandingOpen && (
                            <EALanding
                                gptId={customGPTId}
                                isOpen={isEALandingOpen}
                                onClose={handleCloseEALanding}
                            />
                        )}
                        {isRatingOpen && (
                            <RatingFeedback
                                isOpen={isRatingOpen}
                                onClose={handleCloseRating}
                                userId={userId}
                                gptId={customGPTId}
                                updateGpts={updateGpts}
                            />
                        )}
                    </div>
                )}

                {activeTab === 'gpts' && (
                    <div className={classes.featuredSection}>
                        <div
                            className={classes.featuredHeader}
                        >
                            <Text
                                as="h1"
                                style={{
                                    // marginTop: '150px',
                                    marginBottom: '2px',
                                    fontSize: '25px',
                                    fontWeight: '500',
                                    color: customColors.summarizeButtonDarker,
                                    fontFamily: customFonts.TitleFont,
                                }}
                            >
                                Specialized Protiviti GPTs
                            </Text>
                            <Text
                                as="p"
                                style={{
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    color: customColors.headerLightGray,
                                    fontFamily: customFonts.TitleFont,
                                }}
                            >
                                GPTs currently in production and ready to use
                            </Text>
                        </div>
                        <div className={classes.gptList}>
                            {filteredGPTs.map(
                                (gpt) =>
                                    !JSON.parse(process.env.REACT_APP_EXPLOREGPT_PAGE_EXCEPTIONS || "[]").includes(gpt.id) && (
                                        <div key={gpt.id} className={classes.exploreTileWrapper}>
                                            <ExploreGPTTile
                                                gpt={gpt}
                                                isFunctionTriggered={gpt.gptEndpoint.includes('function')}
                                                //handleButtonClick={(event) => handleButtonClick(event, gpt.id)}
                                                handleLearnMore={handleLearnMore}
                                                handleEALanding={handleEALanding}
                                            />
                                        </div>
                                    ),
                            )}
                        </div>
                        {isLearnMoreOpen && (
                            <GPTOverview
                                gptId={customGPTId}
                                isModal={true}
                                isOpen={isLearnMoreOpen}
                                onClose={handleCloseLearnMore}
                            />
                        )}
                        {isRatingOpen && (
                            <RatingFeedback
                                isOpen={isRatingOpen}
                                onClose={handleCloseRating}
                                userId={userId}
                                gptId={customGPTId}
                                updateGpts={updateGpts}
                            />
                        )}
                    </div>
                )}

                {activeTab === 'accelerators' && (
                    <div className={classes.featuredSection} style={{ marginTop: '0' }}>
                        <div className={classes.featuredHeader}>
                            <Text
                                as="h1"
                                style={{
                                    marginTop: '2%',
                                    marginBottom: '2px',
                                    fontSize: '25px',
                                    fontWeight: '500',
                                    color: customColors.summarizeButtonDarker,
                                    fontFamily: customFonts.TitleFont,
                                }}
                            >
                                Specialized Protiviti Accelerators
                            </Text>
                            <Text
                                as="p"
                                style={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: customColors.headerLightGray,
                                    fontFamily: customFonts.TitleFont,
                                }}
                            ></Text>
                        </div>
                        <div className={classes.gptList}>
                            {accelerators.map(
                                (gpt) =>
                                    gpt.id !== process.env.REACT_APP_DEFAULT_PROGPT &&
                                    gpt.id !== process.env.REACT_APP_DEFAULT_ASSISTANTGPT && (
                                        <div key={gpt.id} className={classes.exploreTileWrapper}>
                                            <ExploreGPTTile
                                                gpt={gpt}
                                                isFunctionTriggered={gpt.gptEndpoint.includes('function')}
                                                //handleButtonClick={(event) => handleButtonClick(event, gpt.id)}
                                                handleLearnMore={handleLearnMore}
                                                handleEALanding={handleEALanding}
                                            />
                                        </div>
                                    ),
                            )}
                        </div>
                        {isLearnMoreOpen && (
                            <GPTOverview
                                gptId={customGPTId}
                                isModal={true}
                                isOpen={isLearnMoreOpen}
                                onClose={handleCloseLearnMore}
                            />
                        )}
                        {isEALandingOpen && (
                            <EALanding
                                gptId={customGPTId}
                                isOpen={isEALandingOpen}
                                onClose={handleCloseEALanding}
                            />
                        )}
                        {isRatingOpen && (
                            <RatingFeedback
                                isOpen={isRatingOpen}
                                onClose={handleCloseRating}
                                userId={userId}
                                gptId={customGPTId}
                                updateGpts={updateGpts}
                            />
                        )}
                    </div>
                )}
                <ToastContainer />
            </div>
        </div>
    );
};
