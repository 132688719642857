import { makeStyles, shorthands } from '@fluentui/react-components';
import { ArrowRight20Regular, ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { customColors, customFonts } from '../../../styles';
import { IGPT } from '../../../libs/models/GPT';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { appInsights } from '../../../App';
import { updateChatInput } from '../../../redux/features/currentmessages/currentmessagesSlice';
import { shallowEqual } from 'react-redux';

const useClasses = makeStyles({
    headerText: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: customFonts.Lato,
        color: customColors.summarizeButtonDarker,
        ...shorthands.padding('15px'),
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 1410px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (max-width: 920px)': {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    smallBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: customColors.white,
        ...shorthands.margin('5px'),
        ...shorthands.borderRadius('10px'),
        ...shorthands.padding('15px'),
    },
    smallBlockCategoryText: {
        width: '90%',
        color: customColors.headerLightGray,
        fontWeight: 'bold',
        fontSize: '15px',
        fontFamily: customFonts.Lato,
        ...shorthands.padding(0, 0, 0, '1em'),
    },
    title: {
        display: 'flex',
        textAlign: 'center',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    smallButton: {
        width: '95%',
        whiteSpace: 'normal',
        textAlign: 'left',
        cursor: 'auto',
        color: customColors.summarizeButtonDarker,
        backgroundColor: customColors.white,
        fontSize: '15px',
        fontWeight: '500',
        fontFamily: customFonts.Lato,
        ...shorthands.padding('5px', '10px', '5px', '10px'),
        ...shorthands.margin('5px', 0, 0, 0),
        '&:hover': {
            cursor: 'pointer',
            fontWeight: '700',
        },
    },
    largeButton: {
        width: '100%',
        whiteSpace: 'normal',
        textAlign: 'left',
        cursor: 'auto',
        color: customColors.summarizeButtonDarker,
        backgroundColor: customColors.white,
        fontSize: '15px',
        fontweight: '500',
        fontFamily: customFonts.Lato,
        ...shorthands.padding('5px', '10px', '5px', '10px'),
        ...shorthands.margin('5px', 0, 0, 0),
        '&:hover': {
            cursor: 'pointer',
            fontWeight: '700',
        },
    },
    columnContainer: {
        width: '98%',
    },
    largeBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5px',
        ...shorthands.padding('15px'),
    },
    largeBlockCategoryContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&: hover': {
            cursor: 'pointer',
        },
        ...shorthands.margin(0, 0, '5px', 0),
    },
    largeBlockCategoryText: {
        fontWeight: '600',
        fontSize: '18px',
        color: customColors.headerLightGray,
        ...shorthands.padding(0, 0, 0, '1em'),
    },
    largeBlockCategoryInfoText: {
        fontWeight: '600',
        fontSize: '12px',
        color: customColors.headerLightGray,
        ...shorthands.padding(0, 0, 0, '1em'),
    },
    followUp: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

interface ToolTipBlockProps {
    category: string;
    suggestions: Record<string, Record<string, string | Record<string, string | string[]>>>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const ToolTipBlock: React.FC<ToolTipBlockProps> = ({ category, suggestions, setValue }) => {
    const initialCollapsedState: Record<string, boolean> = {};
    Object.keys(suggestions).forEach((category) => {
        initialCollapsedState[category] = true;
    });

    const [hoveredButton, setHoveredButton] = React.useState<string | null>(null);
    const [collapsed, setCollapsed] = React.useState<Record<string, boolean>>(initialCollapsedState);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const activeUserInfo = useAppSelector((state: RootState) => state.app.activeUserInfo, shallowEqual);
    const [selectedGPT, setSelectedGPT] = useState<IGPT | undefined>(undefined);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const classes = useClasses();
    const dispatch = useAppDispatch();

    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId].gptEndpoint);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    const handleOnClick = (suggestion: string, buttonText: string) => {
        const contentToCopy: string = suggestion;
        setValue(contentToCopy);
        dispatch(updateChatInput({ chatId: selectedId, text: contentToCopy }));
        document.body.click();

        // Tracking the click event with Application Insights
        appInsights.trackEvent({
            name: 'Prompt Suggestion Click',
            properties: {
                source: 'Suggested Prompts Button',
                GPTId: conversations[selectedId].customGPTId,
                GPTName: conversations[selectedId].customGPTName,
                type: 'Prompt',
                email: activeUserInfo?.email,
                buttonText: buttonText,
                suggestion: suggestion,
            },
        });
    };

    const toggleCollapse = (category: string) => {
        setCollapsed((prevState) => ({ ...prevState, [category]: !prevState[category] }));
    };

    return category === 'Small blocks' ? (
        <div>
            <div className={classes.gridContainer}>
                {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.smallBlockContainer} key={index}>
                        <span className={classes.smallBlockCategoryText}>
                            {category === 'Small blocks' ? 'Starter prompts' : category}
                        </span>
                        <div className={classes.wrapper}>
                            {Object.keys(suggestions[category]).map((smallBlock, index) => {
                                const buttonKey = `${category}-${smallBlock}`;
                                return (
                                    <button
                                        key={index}
                                        className={classes.smallButton}
                                        onClick={() => {
                                            handleOnClick(suggestions[category][smallBlock] as string, smallBlock);
                                        }}
                                        onMouseEnter={(e) => {
                                            setHoveredButton(buttonKey);
                                            (e.currentTarget as HTMLButtonElement).style.border = '1px solid #EA7024';
                                            (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                '5px solid #EA7024';
                                        }}
                                        onMouseLeave={(e) => {
                                            setHoveredButton(null);
                                            (e.currentTarget as HTMLButtonElement).style.border = '1px solid #D9D9D9';
                                            (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                '5px solid #D9D9D9';
                                        }}
                                        style={{
                                            transition: 'background-color 0.3s ease',
                                            border: '1px solid #D9D9D9',
                                            borderLeft: '5px solid #D9D9D9',
                                        }}
                                    >
                                        {smallBlock}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : selectedGPT?.id === process.env.REACT_APP_DEFAULT_PROGPT && category === 'Large blocks' ? (
        <div style={{ marginTop: '30px' }}>
            <div className={classes.columnContainer}>
                {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.largeBlockContainer} key={index}>
                        <div onClick={() => toggleCollapse(category)} className={classes.largeBlockCategoryContainer}>
                            <span className={classes.largeBlockCategoryText}>
                                {category}
                                {category == 'Process Diagram' && (
                                    <span className={classes.largeBlockCategoryInfoText}>
                                        {' '}
                                        (This is in beta. Mermaid Syntax may be invalid. If so, Please click Regenerate
                                        button to try again.)
                                    </span>
                                )}
                            </span>
                            {!collapsed[category] ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
                        </div>
                        {!collapsed[category] && (
                            <div>
                                {Object.entries(suggestions[category].Options).map(([largeBlock, values], index) => {
                                    const buttonKey = `${category}-${largeBlock}`;
                                    return (
                                        <div
                                            key={index}
                                            onMouseEnter={() => setHoveredButton(buttonKey)}
                                            onMouseLeave={() => setHoveredButton(null)}
                                        >
                                            <button
                                                className={classes.largeButton}
                                                onClick={() => {
                                                    handleOnClick(largeBlock, largeBlock);
                                                }}
                                                onMouseEnter={(e) => {
                                                    (e.currentTarget as HTMLButtonElement).style.border =
                                                        '1px solid #EA7024';
                                                    (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                        '5px solid #EA7024';
                                                }}
                                                onMouseLeave={(e) => {
                                                    (e.currentTarget as HTMLButtonElement).style.border =
                                                        '1px solid #D9D9D9';
                                                    (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                        '5px solid #D9D9D9';
                                                }}
                                                style={{
                                                    transition: 'background-color 0.3s ease',
                                                    border: '1px solid #D9D9D9',
                                                    borderLeft: '5px solid #D9D9D9',
                                                }}
                                            >
                                                {largeBlock}
                                            </button>

                                            {hoveredButton === buttonKey &&
                                                Array.isArray(values) &&
                                                values.length > 0 && ( // rendering logic to show sub prompt buttons based on hover state
                                                    <div>
                                                        {values.map((arrayValue, arrayIndex) => {
                                                            return (
                                                                <div key={arrayIndex} className={classes.followUp}>
                                                                    <ArrowRight20Regular
                                                                        style={{ paddingRight: '10px' }}
                                                                    />
                                                                    <button
                                                                        className={classes.largeButton}
                                                                        onClick={() =>
                                                                            handleOnClick(arrayValue, arrayValue)
                                                                        }
                                                                        onMouseEnter={(e) => {
                                                                            (
                                                                                e.currentTarget as HTMLButtonElement
                                                                            ).style.border = '1px solid #EA7024';
                                                                            (
                                                                                e.currentTarget as HTMLButtonElement
                                                                            ).style.borderLeft = '5px solid #EA7024';
                                                                        }}
                                                                        onMouseLeave={(e) => {
                                                                            (
                                                                                e.currentTarget as HTMLButtonElement
                                                                            ).style.border = '1px solid #D9D9D9';
                                                                            (
                                                                                e.currentTarget as HTMLButtonElement
                                                                            ).style.borderLeft = '5px solid #D9D9D9';
                                                                        }}
                                                                        style={{
                                                                            transition: 'background-color 0.3s ease',
                                                                            border: '1px solid #D9D9D9',
                                                                            borderLeft: '5px solid #D9D9D9',
                                                                        }}
                                                                    >
                                                                        {arrayValue}
                                                                    </button>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        //If we want to add more types of blocks continue like this...
        <div></div>
    );
};
