import React, { useState } from 'react';
import { Button, Tooltip, makeStyles, shorthands } from '@fluentui/react-components';
import { Send20Regular } from '@fluentui/react-icons';
import { GetResponseOptions, useChat } from '../../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { setCanvasOpen, setSelectedTab } from '../../../../redux/features/conversations/conversationsSlice';
import { ChatMessageType } from '../../../../libs/models/ChatMessage';
import { customColors } from '../../../../styles';
import { useFormContext } from 'react-hook-form';
import { AcceleratorType } from '../../../../libs/models/ChatSession';

const useClasses = makeStyles({
    generateDocButton: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        ...shorthands.margin('25px'),
        fontSize: '1.2em',
        padding: '10px 100px',
        maxHeight: '120px',
        alignSelf: 'center',
        marginBottom: '150px',
        ...shorthands.transition('all', '0.3s', 'ease'),

        // styles for the arrow icon
        '& > span > svg': {
            fill: customColors.protivitiDarkBlue,
            stroke: customColors.protivitiDarkBlue,
            ...shorthands.transition('all', '0.3s', 'ease'),
        },

        ':hover': {
            color: 'white',
            backgroundColor: customColors.protivitiDarkBlue,
            ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
            '& > span > svg': { fill: 'white', stroke: 'white' },

            ':active': {
                backgroundColor: customColors.protivitiOrange,
                color: 'white',
                ...shorthands.transition('all', '0s'),
            },
        },

        ':disabled': {
            color: 'gray',
            ...shorthands.border('1px', 'solid', 'lightgray'),
            cursor: 'default',
            '& > span > svg': { fill: 'lightgray', stroke: 'lightgray' },

            ':hover': {
                color: 'gray',
                backgroundColor: 'white',
                ...shorthands.border('1px', 'solid', 'lightgray'),
                cursor: 'default',
                '& > span > svg': { fill: 'lightgray', stroke: 'lightgray' },

                ':active': {
                    color: 'gray',
                    backgroundColor: 'white',
                    ...shorthands.border('1px', 'solid', 'lightgray'),
                    ...shorthands.transition('all', '0s'),
                },
            },

            ':active': {
                color: customColors.protivitiDarkBlue,
                backgroundColor: customColors.protivitiOrange,
                ...shorthands.border('1px', 'solid', 'lightgray'),
                ...shorthands.transition('all', '0s'),
            },
        },
    },
});

const mapStringToEnum = (value: string): AcceleratorType | undefined => {
    switch (value) {
        case 'kickoffdeck':
            return AcceleratorType.KickOffDeck;
        case 'onboarding':
            return AcceleratorType.OnboardingBrief;
        case 'checklist':
            return AcceleratorType.CheckList;
        default:
            return undefined;
    }
};

export const GeneratorButton: React.FC = () => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [isGenerating, setIsGenerating] = useState(false);
    const eaSubAssistants = JSON.parse(process.env.REACT_APP_ENGAGEMENT_ACCELERATOR_SUB_ASSISTANTS || '[]');
    const { handleSubmit } = useFormContext();

    const onSubmit = async (data: any) => {
        setIsGenerating(true);
        var originalEndpoint = conversations[selectedId].gptEndpoint;
        const acceleratorTypeEnum = mapStringToEnum(data.acceleratorType);
        if (eaSubAssistants.length > 0 && acceleratorTypeEnum !== undefined) {
            const assistantIndex = acceleratorTypeEnum;
            if (assistantIndex >= 0 && assistantIndex < eaSubAssistants.length) {
                originalEndpoint = originalEndpoint.replace(
                    conversations[selectedId].customGPTId,
                    eaSubAssistants[assistantIndex]
                );
            }
        }

        const jsonTriggerPrompt = process.env.REACT_APP_EA_IOBF_PROMPT;

        const options: GetResponseOptions = {
            messageType: ChatMessageType.Message,
            chatId: selectedId,
            value: JSON.stringify(jsonTriggerPrompt, null, 2),
            isDocumentChat: conversations[selectedId].chatType,
            regenerate: false,
            gptEndpoint: originalEndpoint,
            engagementAccelerator: { ...data, acceleratorType: acceleratorTypeEnum }, // Use enum value
            threadRunConfig: conversations[selectedId].threadRunConfig ?? { temperature: 1 },
        };

        dispatch(setSelectedTab('ea-chat'));
        setTimeout(() => {
            dispatch(setCanvasOpen(true));
        }, 1000);
        await chat.getResponse(options);
        setIsGenerating(false);
    };

    return (
        <Tooltip content="Generate Document" relationship="label">
            <Button
                className={classes.generateDocButton}
                icon={<Send20Regular />}
                onClick={handleSubmit(onSubmit)}
                disabled={isGenerating}
            >
                {isGenerating ? 'Generating...' : 'Generate Document'}
            </Button>
        </Tooltip>
    );
};
