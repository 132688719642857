import React, { useEffect, useState } from 'react';
import Loading from '../../../views/Loading';

interface PowerPointViewerProps {
    fileUrl?: string;
    fileName?: string;
}

const PowerPointViewer: React.FC<PowerPointViewerProps> = ({ fileUrl, fileName }) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{ width: '100%', height: '100%', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {loading ? (
                <Loading text={`Loading ${fileName}...`} />
            ) : (
                <iframe src={fileUrl} width="100%" height="100%" frameBorder="0"></iframe>
            )}
        </div>
    );
};

export default PowerPointViewer;
