import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Divider, makeStyles } from '@fluentui/react-components';
import Loading from '../../../views/Loading';


const usePaginationStyles = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
        gap: '1rem',
    },
    paginationButton: {
        backgroundColor: '#004068',
        color: 'white',
        border: 'none',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight: 500,
        transition: 'background-color 0.2s',
        '&:hover:not(:disabled)': {
            backgroundColor: '#005a94',
        },
        '&:disabled': {
            backgroundColor: '#ccc',
            cursor: 'not-allowed',
        },
    },
    pageInfo: {
        color: '#004068',
        fontWeight: 500,
    },
});


const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const classes = usePaginationStyles();
    return (
        <div className={classes.pagination}>
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={classes.paginationButton}
            >
                Previous
            </button>
            <span className={classes.pageInfo}>Page {currentPage} of {totalPages}</span>
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={classes.paginationButton}
            >
                Next
            </button>
        </div>
    );
};

const useStyles = makeStyles({
    csvContainer: {
        width: '100%',
        margin: '5px',
        fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        overflow: 'hidden',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 16px',
        background: 'none',
        color: 'white',
    },
    title: {
        margin: 0,
        fontSize: '16px',
        fontWeight: 600,
    },
    searchBox: {
        padding: '6px 12px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '14px',
        width: '100%',
    },
    tableWrapper: {
        maxHeight: '85%',
        overflowY: 'auto',
        overflowX: 'auto',
        borderBottom: '1px solid #e0e0e0',
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(0, 64, 104, 0.4) rgba(0, 0, 0, 0.1)',
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 64, 104, 0.4)',
            borderRadius: '8px',
        },
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '14px',
    },
    headerRow: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    headerCell: {
        backgroundColor: '#004068',
        color: 'white',
        padding: '12px 16px',
        textAlign: 'left',
        fontWeight: 600,
        borderRight: '1px solid rgba(255, 255, 255, 0.1)',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
    },
    row: {
        transition: 'background-color 0.2s',
        '&:nth-of-type(even)': {
            backgroundColor: 'rgba(0, 64, 104, 0.05)',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 64, 104, 0.1)',
        },
        borderBottom: '1px solid #e0e0e0',
    },
    cell: {
        padding: '12px 16px',
        textAlign: 'left',
        borderRight: '1px solid #e0e0e0',
        whiteSpace: 'nowrap',
    },
    footer: {
        padding: '12px 16px',
        backgroundColor: '#f9f9f9',
        color: '#004068',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    noResults: {
        textAlign: 'center',
        padding: '40px',
        color: '#666',
    },
    rowsPerPage: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    select: {
        padding: '4px 8px',
        borderRadius: '4px',
        border: '1px solid #ddd',
    },
});

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

interface CSVViewerProps {
    csvContent: string;
}

const CSVViewer: React.FC<CSVViewerProps> = ({ csvContent }) => {
    const [data, setData] = useState<string[][]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredData, setFilteredData] = useState<string[][]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const classes = useStyles();

    useEffect(() => {
        try {
            const decodedContent = atob(csvContent);
            Papa.parse(decodedContent, {
                complete: (result) => {
                    const parsedData = result.data as string[][];

                    if (parsedData.length > 0) {
                        setHeaders(parsedData[0]);
                        setData(parsedData.slice(1).filter(row => row.some(cell => cell !== "")));
                    }

                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                },
                error: (error: any) => {
                    console.error("Error parsing CSV:", error);
                    setLoading(false);
                }
            });
        } catch (error) {
            console.error("Error decoding CSV content:", error);
            setLoading(false);
        }
    }, [csvContent]);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredData(data);
        } else {
            const filtered = data.filter(row =>
                row.some(cell =>
                    cell && cell.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
        setCurrentPage(1);
    }, [searchTerm, data]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const totalPages = Math.max(1, Math.ceil(filteredData.length / rowsPerPage));
    const displayData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const formatDate = (dateStr: string): string => {
        
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
            const date = new Date(dateStr);
            return date.toLocaleDateString();
        }
        return dateStr;
    };

    const formatValue = (value: string, columnIndex: number): string => {
        
        if (value === null || value === undefined) {
            return '';
        }

        
        if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            return formatDate(value);
        }

        
        if (!isNaN(parseFloat(value)) && isFinite(Number(value))) {
            
            const headerText = headers[columnIndex] ? headers[columnIndex].toLowerCase() : '';
            if (headerText.includes('budget') ||
                headerText.includes('spend') ||
                headerText.includes('cost')) {
                return parseFloat(value).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                });
            }
            
            if (headerText.includes('rate') ||
                headerText.includes('percentage')) {
                return parseFloat(value).toFixed(1) + '%';
            }

            
            return parseFloat(value).toLocaleString();
        }

        return value;
    };

    return (
        <div className={classes.csvContainer}>
            <div className={classes.toolbar}>
                <input
                    type="text"
                    placeholder="Search the table..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={classes.searchBox}
                />
            </div>
            <Divider />
            {loading ? (
                <Loading text="Loading data..." />
            ) : (
                <>
                    <div className={classes.tableWrapper}>
                        <table className={classes.table}>
                            <thead>
                                <tr className={classes.headerRow}>
                                    {headers.map((header, index) => (
                                        <th key={index} className={classes.headerCell}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {displayData.length > 0 ? (
                                    displayData.map((row, rowIndex) => (
                                        <tr key={rowIndex} className={classes.row}>
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex} className={classes.cell}>
                                                    {formatValue(cell, cellIndex)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={headers.length}
                                            className={classes.noResults}
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className={classes.footer}>
                        <div className={classes.rowsPerPage}>
                            <span>Rows per page:</span>
                            <select
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(Number(e.target.value));
                                    setCurrentPage(1);
                                }}
                                className={classes.select}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />

                        <div>
                            Showing {filteredData.length === 0 ? 0 : Math.min(filteredData.length, (currentPage - 1) * rowsPerPage + 1)} to {Math.min(filteredData.length, currentPage * rowsPerPage)} of {filteredData.length} entries
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CSVViewer;