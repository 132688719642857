import React, { useCallback, useState, useEffect } from 'react';
import {
    Button,
    mergeClasses,
    makeStyles,
    shorthands,
    Persona,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Text,
    tokens,
} from '@fluentui/react-components';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { useChat } from '../../../../libs/hooks/useChat';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breakpoints, customColors, customFonts } from '../../../../styles';
import { IGPT } from '../../../../libs/models/GPT';
import { ReactComponent as Pinned } from '../../../../assets/Pinned.svg';
import { ReactComponent as Unpinned } from '../../../../assets/Unpin.svg';
import { setPinnedGPTs } from '../../../../redux/features/users/userPreferencesSlice';
import { useUser } from '../../../../libs/hooks/useUser';
import { Tooltip } from '@fluentui/react-tooltip';
import { setShowRecentlyUsedGPTs } from '../../../../redux/features/commonData/CommonDataSlice';
import { EALanding } from '../../../engagementaccelerator/EALanding';

const useClasses = makeStyles({
    button: {
        ...shorthands.border('1px', 'solid', 'transparent'),
        backgroundColor: 'transparent',
        fontWeight: 500,
        ...shorthands.padding('8px', '5px'),
        color: 'white',
        // width: '45%',
        textAlign: 'left',
        ...shorthands.transition('all', '0.3s', 'ease'),
        '&:not(:disabled)': {
            '&:hover': {
                border: '1px solid white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
                color: 'white',
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
            },
            '&:focus': {
                ...shorthands.outline('none'),
                boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
            },
        },
        '&:disabled': {
            backgroundColor: 'rgba(211, 211, 211, 0.2)',
            color: 'rgba(140, 140, 140, 0.2)',
            cursor: 'progress',
            opacity: 0.5,
        },
        display: 'block',
        marginLeft: '15px',
        width: 'calc(100% - 30px)'
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
        },
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '12.5px',
        fontFamily: customFonts.Lato,
        color: customColors.white,
        lineHeight: tokens.lineHeightBase200,
    },
    popoverSurface: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            flexDirection: 'column',
        }),
    },
    gptIcon: {
        '& > span': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    icon: {
        '& > span': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    small: {
        minWidth: '42px',
        ...shorthands.border('1px', 'solid', 'transparent'),
        backgroundColor: 'transparent',
        fontWeight: 500,
        color: 'white',
        width: '42px',
        height: '42px',
        borderRadius: '50%',
        ...shorthands.transition('all', '0.3s', 'ease'),
        '&:not(:disabled)': {
            '&:hover': {
                border: '1px solid white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
                color: 'white',
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
            },
            '&:focus': {
                ...shorthands.outline('none'),
                boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
            },
        },
        '&:disabled': {
            backgroundColor: 'rgba(211, 211, 211, 0.2)',
            color: 'rgba(140, 140, 140, 0.2)',
            cursor: 'progress',
            opacity: 0.5,
        },
        marginBottom: '5px',
        marginLeft: '5px',
        paddingLeft: '20px',
    },
    pinIcon: {
        background: 'transparent',
        outline: 'none',
        border: 'none',
        '&:hover, &:focus': {
            background: 'transparent',
            outline: '1px solid',
            outlineColor: customColors.white,
        },
    },
    svgIcon: {
        '& > path': {
            fill: 'white',
        },
    },
    createButton: {
        background: 'none',
        border: 'none',
        textAlign: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:disabled': {
            backgroundColor: 'rgba(211, 211, 211, 0.2)',
            color: 'rgba(140, 140, 140, 0.2)',
            cursor: 'progress',
            opacity: 0.5,
        },
        '&:hover, &:focus': {
            background: 'transparent',
        },
    },
    innerButtonContent: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        '@media (max-width: 1000px)': {
            display: 'block',
            width: 'auto',
        },
    },
    innerButtonContentOverlay: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
});
interface RecentlyUsedGPTButtonProps {
    width: number;
}


const getGPTAvatar = (gpt: IGPT): string => {
    if (gpt.gptIconFileName && process.env.REACT_APP_CDN_IMAGE_URL) {
      return process.env.REACT_APP_CDN_IMAGE_URL + gpt.gptIconFileName;
    }
    return gpt.cardImageURL || '';
  };


const RecentlyUsedGPTs: React.FC<RecentlyUsedGPTButtonProps> = ({ width }) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useUser();
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const { isCollapsed, isOverlay } = useAppSelector((state: RootState) => state.commonData);
    const { userGPTs } = useSelector((state: RootState) => state.gpts);
    const { pinnedGPTs } = useSelector((state: RootState) => state.userpreferences);
    const [gptArray, setGptArray] = useState<IGPT[]>([]);
    //const [gptIconNameArray, setGptIconNameArray] = useState<Record<string, string>>({});
    const [isCreatingChat, setIsCreatingChat] = useState(false);
    const chatCount: number = Object.keys(conversations).length;
    const ALLOWEDCHATS = 40;
    const [isEALandingOpen, setIsEALandingOpen] = useState(false);
    const [customGPTId, setCustomGPTId] = useState<string | undefined>(undefined);

    const handleEALanding = (gptId: string) => {
        setCustomGPTId(gptId);
        setIsEALandingOpen(true);
    };

    const handleCloseEALanding = () => {
        setIsEALandingOpen(false);
    };

    const createChat = useCallback(async (gpt: IGPT) => {
        setIsCreatingChat(true);
        var newChatId = await toast.promise(chat.createChat(gpt), {
            pending: {
                render: 'Creating chat...',
                position: 'top-right',
                className: classes.toastMessage,
            },
        });

        if (newChatId == 'Chat session cannot be created more than 40.')
            toast.error(
                'You have reached the maximum limit of 40 chat sessions. Please delete an existing chat to create a new one.',
                {
                    className: classes.toastMessage,
                },
            );
        else if (newChatId.startsWith('Unable to create new chat'))
            toast.error(null, {
                className: classes.toastMessage,
            });
        else {
            navigate(`/chat/${newChatId}`);
        }
        setIsCreatingChat(false);
    }, []);

    useEffect(() => {
        const sortedIds = Object.keys(conversations).sort((a, b) => {
            if (conversations[a].modifiedDate === undefined) {
                return 1;
            }
            if (conversations[b].modifiedDate === undefined) {
                return -1;
            }
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            return conversations[a].modifiedDate! - conversations[b].modifiedDate!;
        });

        const newGptArray: IGPT[] = [];
        for (const chatSession of sortedIds) {
            if (newGptArray.length >= 4) break; // we're setting the limit to be 4 here
            if (conversations[chatSession].type !== 0) {
                const filteredGPTs = userGPTs.filter(
                    (gpt) =>
                        gpt.gptEndpoint === conversations[chatSession].gptEndpoint &&
                        !pinnedGPTs.some((pinned) => pinned.gptEndpoint === gpt.gptEndpoint), // make sure we're NOT including the pinned gpts (no duplicates)
                );
                if (filteredGPTs[0]) {
                    if (!newGptArray.some((gpt) => gpt.gptEndpoint === filteredGPTs[0].gptEndpoint)) {
                        newGptArray.push(filteredGPTs[0]);
                    }
                }
            }
        }

        setGptArray([...pinnedGPTs, ...newGptArray].slice(0, 4));
        if (newGptArray.length > 0 || pinnedGPTs.length > 0) {
            dispatch(setShowRecentlyUsedGPTs(true));
        } else {
            dispatch(setShowRecentlyUsedGPTs(false));
        }
    }, [conversations, pinnedGPTs, userGPTs]);

    const pinGPT = async (gpt: IGPT) => {
        if (pinnedGPTs.includes(gpt)) {
            // if we're already pinned, we're going to unpin it by filtering that gpt out
            const localGPTs = pinnedGPTs.filter((x) => x !== gpt); // remove this gpt
            const ids: string[] = localGPTs.map((x) => x.id);
            await user.updatePinnedGPTS(ids).then(() => {
                dispatch(setPinnedGPTs({ pinnedGPTs: localGPTs }));
            });
        } else if (pinnedGPTs.length < 4) {
            // if we're able to pin it, we're going to pin
            const localGPTs = [...pinnedGPTs, gpt]; // creating a local copy with the included gpt
            const ids: string[] = localGPTs.map((x) => x.id); // extract just the ids
            await user.updatePinnedGPTS(ids).then(() => {
                // update the backend first, upon success then we're going to update the redux state
                dispatch(setPinnedGPTs({ pinnedGPTs: localGPTs }));
            });
        } else {
            // error since you can only pin up to 4
            toast.error('You can only pin up to 4 gpts!', {
                className: classes.toastMessage,
            });
        }
    };

    useEffect(() => {}, [pinnedGPTs, isOverlay]);

    return (
        <div>
            {gptArray.map((gpt, index) => (
                <Popover
                    key={index}
                    openOnHover={true}
                    mouseLeaveDelay={0}
                    positioning={{
                        position: 'after',
                        autoSize: 'width',
                    }}
                >
                    <PopoverTrigger disableButtonEnhancement>
                        <div>
                            <Button
                                className={
                                    (width > 64 || isOverlay) ? mergeClasses(classes.root,classes.button) : classes.small
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (gpt.isAccelerator) {
                                        handleEALanding(gpt.id);
                                    } else {
                                        createChat(gpt);
                                    }
                                }}
                                data-testid={`gptButton-${index}`}
                                disabled={isCreatingChat || chatCount == ALLOWEDCHATS}
                                style={
                                    isCreatingChat
                                        ? {
                                              cursor: 'progress',
                                              opacity: 0.5,
                                              pointerEvents: 'visible',
                                            //   width: `${width - 30}px`,
                                          }
                                        : { }
                                }
                            >
                                <div
                                    className={
                                        isOverlay ? classes.innerButtonContentOverlay : classes.innerButtonContent
                                    }
                                    style={isCollapsed ? { display: 'block', width: 'auto' } : {}}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '80%' }}>
                                         <Persona
                                            className={classes.icon}
                                            avatar={
                                                (width > 64 || isOverlay)
                                                ? { image: { src: getGPTAvatar(gpt) }, shape: 'square' }
                                                : { image: { src: getGPTAvatar(gpt) }, shape: 'circular' }
                                            }
                                            />
                                        {(width > 64 || isOverlay) && <Text className={classes.title}>{gpt.name}</Text>}
                                    </div>
                                    <div style={{ justifySelf: 'end' }}>
                                        {(width > 64 || isOverlay) && (
                                            <Tooltip
                                                content={pinnedGPTs.includes(gpt) ? 'Unpin GPT' : 'Pin GPT'}
                                                relationship="label"
                                            >
                                                <Button
                                                    className={classes.pinIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        pinGPT(gpt);
                                                    }}
                                                    icon={
                                                        pinnedGPTs.includes(gpt) ? (
                                                            <Pinned className={classes.svgIcon} />
                                                        ) : (
                                                            <Unpinned className={classes.svgIcon} />
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </PopoverTrigger>
                    <PopoverSurface className={classes.popoverSurface} style={isCollapsed ? { display: 'flex', flexDirection: 'column' } : {}}>
                        <Text weight="bold">{gpt.name}</Text>
                    </PopoverSurface>
                </Popover>
            ))}
            {isEALandingOpen && (
                <EALanding
                    gptId={customGPTId}
                    isOpen={isEALandingOpen}
                    onClose={handleCloseEALanding}
                />
            )}
        </div>
    );
};

export default RecentlyUsedGPTs;
