import React, { useEffect, useState } from 'react';
import { EAHeader } from '../components/inputs/header';
import { ChooseAccelerator } from '../components/inputs/ChooseAccelerator';
import { FileAttachment } from '../components/inputs/FileAttachment';
import { DocumentProperty } from '../components/inputs/DocumentProperty';
import { IEngagementAccelerator } from '../../../libs/models/ChatSession';
import { FormProvider, useForm } from 'react-hook-form';
import { GeneratorButton } from '../components/inputs/GeneratorButton';
import useClasses from '../EAStyles';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

export const InputsTab: React.FC = () => {
    const [selectedAccelTypeOption, setSelectedAccelTypeOption] = useState<
        string | (readonly string[] & string) | undefined
    >('');
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const engagement = conversations[selectedId].engagementAccelerator;

    const classes = useClasses();

    const handleAccelTypeOptionSelect = (option: string | undefined) => {
        setSelectedAccelTypeOption(option);
    };

    const onSubmit = (data: any) => {
        console.log(data);
    };

    const methods = useForm<IEngagementAccelerator>({
        defaultValues: {
            acceleratorType: engagement?.acceleratorType || 0,
            industry: engagement?.industry || '',
            solution: engagement?.solution || '',
            thirdPartyTools: engagement?.thirdPartyTools || '',
            departmentInScope: engagement?.departmentInScope || '',
            contractorInformation: engagement?.contractorInformation || 'yes',
            backgroundCheck: engagement?.backgroundCheck || 'yes',
            country: engagement?.country || '',
            region: engagement?.region || '',
            deliveryCenter: engagement?.deliveryCenter || '',
            ecosystemPartner: engagement?.ecosystemPartner || '',
            clientDataRequirements: engagement?.clientDataRequirements || '',
            teamsSite: engagement?.teamsSite || '',
        },
    });

    // reset form values when engagement changes
    useEffect(() => {
        methods.reset({
            acceleratorType: engagement?.acceleratorType || 0,
            industry: engagement?.industry || '',
            solution: engagement?.solution || '',
            thirdPartyTools: engagement?.thirdPartyTools || '',
            departmentInScope: engagement?.departmentInScope || '',
            contractorInformation: engagement?.contractorInformation || 'yes',
            backgroundCheck: engagement?.backgroundCheck || 'yes',
            country: engagement?.country || '',
            region: engagement?.region || '',
            deliveryCenter: engagement?.deliveryCenter || '',
            ecosystemPartner: engagement?.ecosystemPartner || '',
            clientDataRequirements: engagement?.clientDataRequirements || '',
            teamsSite: engagement?.teamsSite || '',
        });
    }, [engagement, methods]);

    return (
        <>
            <div className={classes.scrollableContainer}>
                <EAHeader />
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <ChooseAccelerator
                            selectedAccelTypeOption={selectedAccelTypeOption}
                            handleAccelTypeOptionSelect={handleAccelTypeOptionSelect}
                        />
                        <FileAttachment />
                        <DocumentProperty selectedAccelTypeOption={selectedAccelTypeOption} />
                        {/* Put select file type component here */}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <GeneratorButton />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </>
    );
};
