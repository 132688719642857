import { createSelector } from 'reselect';
import { RootState } from '../../app/store';

// Memoized selector
export const selectChatMemorySources = createSelector(
    (state: RootState) => state.chatMemory.chatMemorySources,  // Input selector
    (_: RootState, chatId: string) => chatId,              // Second input parameter (chatId)
    (chatMemorySources, chatId) => {
        console.log(' 4 ChatMemorySources selector:', chatMemorySources);
        // Return the sources for the specific chatId
        return chatMemorySources[chatId];
    }
);
