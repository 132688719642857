import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Text, makeStyles, shorthands, tokens, Tooltip } from '@fluentui/react-components';
import {
    Dismiss24Regular,
    ArrowLeft24Regular,
    Copy24Regular,
    CheckmarkCircle24Regular,
    Image24Regular,
    DocumentPdf24Regular,
    Code24Regular,
    Table24Regular,
    Document24Regular,
    ArrowMaximize24Regular,
    ArrowMinimize24Regular,
    ArrowReset24Regular,
    ZoomIn24Regular,
    ZoomOut24Regular,
    DocumentBriefcase24Regular,
    ArrowExportUp24Regular,
    ArrowRight24Regular
} from '@fluentui/react-icons';
import { Artifact } from '../../../libs/models/ChatMessage';
import { customColors, customFonts, ScrollBarStyles } from '../../../styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PowerPointViewer from './viewers/PowerPointViewer';
import { OutputCanvasPreBlock } from '../chat-history/output-components/OutputCanvasPreBlock';
import { OutputCodeBlock } from '../chat-history/output-components/OutputCodeBlock';
import PDFViewer from './viewers/PDFViewer';
import { Badge } from 'react-bootstrap';
import CSVViewer from './viewers/CSVViewer';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import ArtifactSpinner from './enhacements/ArtifactSpinner';
import DocxViewer from './viewers/DocViewer';
import { toast } from 'react-toastify';
import { TimeAgo } from './enhacements/TimeAgo';
import ExcelViewer from './viewers/ExcelViewer';

const canvasPallet = {
    primary: customColors.protivitiDarkBlue,
    primaryLight: customColors.protivitiDarkBlue,
    secondary: '#E2E8F0',
    secondaryDark: '#CBD5E0',
    accent: customColors.protivitiDarkBlue,
    accentLight: '#63B3ED',
    success: '#38A169',
    successLight: '#9AE6B4',
    text: '#1A202C',
    textLight: '#4A5568',
    textInverse: '#FFFFFF',
    background: '#F7FAFC',
    border: '#E2E8F0',
    shadow: 'rgba(0, 0, 0, 0.1)',
};

const useStyles = makeStyles({
    artifactsListContainer: {
        padding: '16px',
        backgroundColor: canvasPallet.background,
        height: '100%',
        transition: 'width 0.5s ease, opacity 0.5s ease, transform 0.5s ease',
    },
    artifactsList: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    artifactContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...shorthands.padding('12px', '16px'),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        backgroundColor: canvasPallet.secondary,
        borderLeft: `4px solid ${canvasPallet.accent}`,
        color: canvasPallet.text,
        boxShadow: `0 1px 3px ${canvasPallet.shadow}`,
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        fontWeight: 500,
        fontSize: '14px',
        marginBottom: '8px',
        '&:hover': {
            backgroundColor: canvasPallet.secondaryDark,
            transform: 'translateY(-1px)',
            boxShadow: `0 2px 4px ${canvasPallet.shadow}`,
        },
    },
    artifactItemIcon: {
        marginRight: '12px',
        color: canvasPallet.accent,
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    artifactItemDate: {
        fontSize: '12px',
        color: canvasPallet.textLight,
    },
    actionButton: {
        background: 'none',
        color: canvasPallet.textInverse,
        border: 'none',
        padding: '6px',
        minWidth: '32px',
        height: '32px',
        ...shorthands.margin('0', '4px'),
        '&:hover': {
            color: canvasPallet.textInverse,
            backgroundColor: `${canvasPallet.secondary}80`,
            ...shorthands.borderRadius('4px'),
        },
    },
    actionButtonSuccess: {
        color: canvasPallet.success,
        '&:hover': {
            color: canvasPallet.successLight,
            backgroundColor: `${canvasPallet.secondary}80`,
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderBottom: `1px solid ${canvasPallet.border}`,
    },
    headerTitle: {
        fontSize: '20px',
        fontWeight: 600,
        color: canvasPallet.text,
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
        transition: 'transform 0.3s ease-out',
        overflow: 'auto',
        ...ScrollBarStyles,
    },
    pdfIframe: {
        width: '100%',
        height: '100%',
        border: 'none',
    },
    codeContainer: {
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        boxShadow: `0 2px 4px ${canvasPallet.shadow}`,
        backgroundColor: canvasPallet.background,
        border: `1px solid ${canvasPallet.border}`,
    },
    codeContent: {
        overflow: 'auto',
        maxHeight: '80vh',
        ...ScrollBarStyles,
    },
    modalOverlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modalContent: {
        position: 'relative',
        width: '95vw',
        height: '95vh',
        backgroundColor: canvasPallet.background,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        boxShadow: tokens.shadow64,
        ...shorthands.padding('24px'),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    modalImageWrapper: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        padding: tokens.spacingVerticalL,
        ...ScrollBarStyles,
    },
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: canvasPallet.textLight,
        padding: '24px',
        textAlign: 'center',
    },
    detailsHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        borderBottom: `1px solid ${canvasPallet.border}`,
        backgroundColor: canvasPallet.primary,
        color: canvasPallet.textInverse,
    },
    detailsContent: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: canvasPallet.background,
        position: 'relative',
        overflow: 'auto',
        ...ScrollBarStyles,
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        borderTop: `1px solid ${canvasPallet.border}`,
        backgroundColor: canvasPallet.primary,
    },
    footerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    scaleControls: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    artifactName: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    artifactIcon: {
        marginRight: '8px',
        color: canvasPallet.accent,
    },
    badge: {
        backgroundColor: customColors.protivitiDarkBlue,
        color: canvasPallet.textInverse,
        fontSize: '20px',
        fontWeight: 500,
        ...shorthands.padding('2px', '8px'),
        ...shorthands.borderRadius('12px'),
        marginLeft: '8px',
    },
    progressIndicator: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        height: '3px',
        backgroundColor: canvasPallet.accent,
        transition: 'width 0.3s ease-in-out',
        color: canvasPallet.textInverse
    },
    buttonWithProgress: {
        position: 'relative',
        overflow: 'hidden',
    },
    divider: {
        width: '1px',
        height: '24px',
        backgroundColor: canvasPallet.border,
        margin: '0 8px',
    },
    loadingText: {
        fontSize: '12px',
        color: canvasPallet.textInverse,
        marginLeft: '8px',
    },
    backButton: {
        background: 'none',
        color: canvasPallet.textInverse,
        border: 'none',
        padding: '4px',
        minWidth: 'auto',
        marginRight: '8px',
        '&:hover': {
            color: canvasPallet.textInverse,
            backgroundColor: `${canvasPallet.secondary}80`,
            ...shorthands.borderRadius('4px'),
        },
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

const useScrollPreservation = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        if (ref.current) {
            setScrollPosition(ref.current.scrollTop);
        }
    };

    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            currentRef.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    return { ref, handleScroll };
};

const categorizeAndSortArtifacts = (artifacts: Artifact[]) => {
    const categories: { [key: string]: { latest: Artifact; versions: Artifact[] } } = {};

    artifacts.forEach(artifact => {
        if (!categories[artifact.type]) {
            categories[artifact.type] = { latest: artifact, versions: [] };
        } else {
            const currentLatest = categories[artifact.type].latest;
            if (new Date(artifact.lastModifiedDate).getTime() > new Date(currentLatest.lastModifiedDate).getTime()) {
                categories[artifact.type].versions.unshift(currentLatest);
                categories[artifact.type].latest = artifact;
            } else {
                categories[artifact.type].versions.push(artifact);
            }
        }
    });

    // Limit versions to the 3 most recent ones, including the latest
    Object.keys(categories).forEach(type => {
        categories[type].versions = [categories[type].latest, ...categories[type].versions]
            .slice(0, 3);
    });

    return categories;
};

const Canvas: FC<{
    artifacts: Artifact[];
    onCloseCanvas?: () => void;
    onExpandCanvas?: () => void;
    onRetractCanvas?: () => void;
}> = ({
    artifacts,
    onCloseCanvas,
    onExpandCanvas,
    onRetractCanvas,
}: {
    artifacts: Artifact[];
    onCloseCanvas?: () => void;
        onExpandCanvas?: () => void;
        onRetractCanvas?: () => void;
}) => {
    const [selectedArtifact, setSelectedArtifact] = useState<Artifact | null>(null);
    const [showDetails, setShowDetails] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [isCopied, setIsCopied] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const classes = useStyles();
    const { ref: codeScrollRef, handleScroll: handleCodeScroll } = useScrollPreservation();
    const imageRef = useRef<HTMLImageElement>(null);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const botResponseStatus = useAppSelector(
        (state: RootState) => state.currentmessages.generatingMessages[selectedId]?.botResponseStatus,
        shallowEqual,
    );

    const getArtifactIcon = (type: string) => {
        switch (type) {
            case 'png':
                return <Image24Regular />;
            case 'image':
                return <Image24Regular />;
            case 'pdf':
                return <DocumentPdf24Regular />;
            case 'code':
                return <Code24Regular />;
            case 'table':
                return <Table24Regular />;
            case 'csv':
                return <img
                    src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/excel_48x1.svg"
                    width="28"
                    height="28"
                />;
            case 'xlsx':
                return <img
                    src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/excel_48x1.svg"
                    width="28"
                    height="28"
                />;
            case 'txt':
                return <DocumentBriefcase24Regular />;
            case 'pptx':
                return <img
                    src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/powerpoint_48x1.svg"
                    width="28"
                    height="28"
                />;
            case 'docx':
                return <img
                    src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/word_48x1.svg"
                    width="28"
                    height="28"
                />;
            default:
                return <Document24Regular />;
        }
    };

    const handleCloseCanvas = useCallback(() => {
        if (onCloseCanvas) {
            onCloseCanvas();
        }
    }, [onCloseCanvas]);

    const handleSelectArtifact = async (artifact: Artifact) => {
        setSelectedArtifact(artifact);
        setShowDetails(true);
        if (onExpandCanvas) {
            onExpandCanvas();
        }
    };

    const handleZoomIn = useCallback(() => {
        setScale((prev) => Math.min(prev + 0.25, 3));
    }, []);

    const handleZoomOut = useCallback(() => {
        setScale((prev) => Math.max(prev - 0.25, 0.5));
    }, []);

    const handleResetZoom = useCallback(() => {
        setScale(1);
    }, []);

    const handleBack = () => {
        setShowDetails(false);
        setSelectedArtifact(null);
        if (onRetractCanvas) {
            onRetractCanvas();
        }
    };

    const toggleModal = useCallback(() => {
        setIsModalOpen((prev) => !prev);
        setScale(1);
    }, []);

    const handleCopy = useCallback(async () => {
        if (!selectedArtifact) return;

        if (selectedArtifact.type === 'image' && imageRef.current) {
            try {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = imageRef.current.naturalWidth;
                canvas.height = imageRef.current.naturalHeight;

                if (ctx) {
                    ctx.drawImage(imageRef.current, 0, 0);

                    const blob = await new Promise<Blob>((resolve) => {
                        canvas.toBlob((blob) => {
                            if (blob) resolve(blob);
                            else resolve(new Blob());
                        }, 'image/png');
                    });

                    const item = new ClipboardItem({ 'image/png': blob });

                    await navigator.clipboard.write([item]);

                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 2000);
                }
            } catch (err) {
                console.error('Failed to copy image: ', err);

                navigator.clipboard.writeText(selectedArtifact.content!.toString())
                    .then(() => {
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 2000);
                    });
            }
        } else {
            navigator.clipboard.writeText(selectedArtifact.content!.toString())
                .then(() => {
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 2000);
                })
                .catch(err => console.error('Failed to copy: ', err));
        }
    }, [selectedArtifact]);

    const handleExport = useCallback(() => {
        if (!selectedArtifact) return;

        setIsExporting(true);
        setDownloadProgress(0);

        const progressInterval = setInterval(() => {
            setDownloadProgress(prev => {
                if (prev >= 100) {
                    clearInterval(progressInterval);
                    setTimeout(() => {
                        setIsExporting(false);
                        setDownloadProgress(0);
                    }, 500);
                    return 100;
                }
                return prev + 10;
            });
        }, 100);

        let fileName = selectedArtifact.name || `artifact-${selectedArtifact.id}`;
        let fileContent = selectedArtifact.content?.toString() || '';
        let fileType = 'text/plain';
        let isBase64 = false;

        switch (selectedArtifact.type) {
            case 'image':
                if (!fileName.toLowerCase().endsWith('.png')) {
                    fileName += '.png';
                }
                fileType = 'image/png';
                if (!fileContent.startsWith('data:image')) {
                    fileContent = `data:image/png;base64,${fileContent}`;
                }
                break;
            case 'pptx':
                if (!fileName.toLowerCase().endsWith('.pptx')) {
                    fileName += '.pptx';
                }
                fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                isBase64 = true;
                break;
            case 'code':
                if (!fileName.toLowerCase().endsWith('.txt')) {
                    fileName += '.txt';
                }
                fileType = 'text/plain';
                break;
            case 'csv':
                if (!fileName.toLowerCase().endsWith('.csv')) {
                    fileName += '.csv';
                }
                fileType = "text/csv";
                isBase64 = true;
                break;
            case 'txt':
                if (!fileName.toLowerCase().endsWith('.txt')) {
                    fileName += '.txt';
                }
                fileType = 'text/plain';
                isBase64 = true;
                break;
            case 'docx':
                if (!fileName.toLowerCase().endsWith('.docx')) {
                    fileName += '.docx';
                }
                fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                isBase64 = true;
                break;
            case 'pdf':
                if (!fileName.toLowerCase().endsWith('.pdf')) {
                    fileName += '.pdf';
                }
                fileType = 'application/pdf';
                isBase64 = true;
                break;
            case 'xlsx':
                if (!fileName.toLowerCase().endsWith('.xlsx')) {
                    fileName += '.xlsx';
                }
                fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                isBase64 = true;
                break;
            default:
                if (!fileName.toLowerCase().endsWith('.txt')) {
                    fileName += '.txt';
                }
                fileType = 'text/plain';
        }

        try {
            const element = document.createElement('a');

            if (selectedArtifact.type === 'image') {
                element.href = fileContent;
            } else {
                const binaryContent = isBase64 ?
                    new Uint8Array(
                        atob(fileContent)
                            .split('')
                            .map(char => char.charCodeAt(0))
                    ) :
                    fileContent;

                const blob = new Blob([binaryContent], { type: fileType });
                element.href = URL.createObjectURL(blob);
            }

            element.download = fileName;
            document.body.appendChild(element);

            setTimeout(() => {
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(element.href); 
            }, 800);
        } catch (error) {
            setIsExporting(false);
            toast.error(
                `Failed to export ${selectedArtifact.name}. Please try again or contact support.`,
                {
                    className: classes.toastMessage,
                },
            );
        }
    }, [selectedArtifact]);

    const renderCodeContent = (content: string) => {
        return (
            <div className={classes.codeContainer}>
                <div className={classes.codeContent} ref={codeScrollRef} onScroll={handleCodeScroll}>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            pre: ({ node, ...props }) => <OutputCanvasPreBlock {...props} />,
                            code: ({ node, className, children, ...props }) => (
                                <OutputCodeBlock {...props}>{String(children)}</OutputCodeBlock>
                            ),
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    const renderArtifactsList = () => {
        const categorizedArtifacts = categorizeAndSortArtifacts(artifacts);
        const displayedArtifactCount = Object.values(categorizedArtifacts).reduce(
            (count, group) => count + group.versions.length,
            0
        );

        return (
            <div className={classes.artifactsListContainer}>
                <div className={classes.header}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.headerTitle}>
                            Chat Artifacts
                        </Text>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge
                                style={{ marginLeft: '10px', width: '30px', flex: 1, alignSelf: 'center' }}
                                bg={"primary"}
                            >
                                {displayedArtifactCount}
                            </Badge>
                        </div>
                    </div>
                    <Button
                        icon={<Dismiss24Regular />}
                        onClick={handleCloseCanvas}
                        className={classes.actionButton}
                        style={{ color: canvasPallet.primary }}
                        title="Close Artifacts Panel"
                    />
                </div>

                {artifacts.length > 0 ? (
                    <div className={classes.artifactsList}>
                        {Object.keys(categorizedArtifacts).map(type => (
                            <div key={type}>
                                <Text style={{ fontWeight: 600, marginTop: '16px' }}>{type.toUpperCase()}</Text>
                                {categorizedArtifacts[type].versions.map((artifact, index) => (
                                    <div
                                        key={artifact.id}
                                        className={classes.artifactContainer}
                                        onClick={() => handleSelectArtifact(artifact)}
                                        style={index > 0 ? { marginLeft: '16px' } : {}}
                                    >
                                        <span className={classes.artifactName}>
                                            <span className={classes.artifactIcon}>{getArtifactIcon(type)}</span>
                                            <span>
                                                {artifact.name.length > 32 ? `${artifact.name.substring(0, 40)}...` : artifact.name}
                                            </span>
                                        </span>
                                        <span className={classes.artifactItemDate}>
                                            <TimeAgo dateString={artifact.lastModifiedDate} />
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.emptyState}>
                        <Document24Regular style={{ fontSize: '48px', marginBottom: '16px' }} />
                        <Text style={{ fontSize: '16px', marginBottom: '8px' }}>No artifacts yet</Text>
                            <Text style={{ fontSize: '14px', color: canvasPallet.textLight }}>
                            Artifacts will appear here when they are created during your conversation.
                        </Text>
                    </div>
                )}
            </div>
        );
    };

    const renderArtifactDetails = () => {
        if (!selectedArtifact) {
            return null;
        }

        const categorizedArtifacts = categorizeAndSortArtifacts(artifacts);
        const artifactType = selectedArtifact.type;
        const artifactGroup = categorizedArtifacts[artifactType];
        const artifactIndex = artifactGroup.versions.findIndex(
            (artifact) => artifact.id === selectedArtifact.id
        );

        const handlePreviousVersion = () => {
            if (artifactIndex < artifactGroup.versions.length - 1) {
                setSelectedArtifact(artifactGroup.versions[artifactIndex + 1]);
            }
        };

        const handleNextVersion = () => {
            if (artifactIndex > -1) {
                setSelectedArtifact(
                    artifactIndex === 0 ? artifactGroup.latest : artifactGroup.versions[artifactIndex - 1]
                );
            }
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <div className={classes.detailsHeader}>
                    <Button
                        icon={<ArrowLeft24Regular />}
                        onClick={handleBack}
                        className={classes.backButton}
                        title="Back to List"
                    />
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontWeight: 600,
                            fontSize: '14px',
                        }}
                    >
                        {selectedArtifact.name}
                    </Text>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <Tooltip content="Scroll to the older version" relationship="label">
                            <Button
                                icon={artifactIndex === artifactGroup.versions.length - 1 ? null : <ArrowLeft24Regular />}
                                onClick={handlePreviousVersion}
                                style={{ display: artifactIndex === artifactGroup.versions.length - 1 ? 'none' : 'flex', }}
                                disabled={artifactIndex === artifactGroup.versions.length - 1}
                                className={classes.actionButton}
                            />
                        </Tooltip>
                        <Text style={{ fontSize: '12px', color: canvasPallet.textInverse, position: 'static' }}>
                            Version {artifactGroup.versions.length - artifactIndex} of {artifactGroup.versions.length}
                        </Text>
                        <Tooltip content="Scroll to the newer version" relationship="label">
                            <Button
                                icon={artifactIndex === -1 || artifactGroup.latest.id === selectedArtifact.id ? null : <ArrowRight24Regular />}
                                onClick={handleNextVersion}
                                style={{ display: artifactIndex === -1 || artifactGroup.latest.id === selectedArtifact.id ? 'none' : 'flex' }}
                                className={classes.actionButton}
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className={classes.detailsContent}>
                    {(selectedArtifact.type === 'image' || selectedArtifact.type === 'png') && selectedArtifact.content && (
                        <img
                            ref={imageRef}
                            src={`data:image/png;base64,${selectedArtifact.content}`}
                            alt={selectedArtifact.name || 'Generated image'}
                            className={classes.image}
                            style={{ transform: `scale(${scale})` }}
                        />
                    )}

                    {selectedArtifact.type === 'pdf' && selectedArtifact.content && (
                        <PDFViewer pdfContent={selectedArtifact.content} fileName={selectedArtifact.name} />
                    )}

                    {selectedArtifact.type === 'pptx' && selectedArtifact.content && (
                        <PowerPointViewer fileUrl={selectedArtifact.embedUrl} fileName={selectedArtifact.name} />
                    )}

                    {selectedArtifact.type === 'code' && renderCodeContent(selectedArtifact.content!.toString())}

                    {selectedArtifact.type === 'csv' && selectedArtifact.content && (
                        <CSVViewer csvContent={selectedArtifact.content.toString()} />
                    )}

                    {selectedArtifact.type === 'xlsx' && selectedArtifact.content && (
                        <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                            <ExcelViewer uri={selectedArtifact.embedUrl} fileName={selectedArtifact.name} />
                        </div>
                    )}
                    {selectedArtifact.type === 'docx' && selectedArtifact.content && (
                        <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                            <DocxViewer uri={selectedArtifact.embedUrl} fileName={selectedArtifact.name} />
                        </div>
                    )}
                </div>

                <div className={classes.footer}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedArtifact.lastModifiedDate && (
                            <Text style={{ fontSize: '12px', color: customColors.lightBlueGrayBackground1 }}>
                                Last Modified: <TimeAgo dateString={selectedArtifact.lastModifiedDate} />
                            </Text>
                        )}
                    </div>

                    {(selectedArtifact.type === 'image' || selectedArtifact.type === 'png') && (
                        <div className={classes.scaleControls}>
                            <Tooltip content="Zoom in" relationship="label">
                                <Button
                                    icon={<ZoomIn24Regular />}
                                    onClick={handleZoomIn}
                                    disabled={scale >= 3}
                                    className={classes.actionButton}
                                />
                            </Tooltip>

                            <Tooltip content="Zoom out" relationship="label">
                                <Button
                                    icon={<ZoomOut24Regular />}
                                    onClick={handleZoomOut}
                                    disabled={scale <= 0.5}
                                    className={classes.actionButton}
                                />
                            </Tooltip>

                            <Tooltip content="Reset zoom" relationship="label">
                                <Button
                                    icon={<ArrowReset24Regular />}
                                    onClick={handleResetZoom}
                                    disabled={scale === 1}
                                    className={classes.actionButton}
                                />
                            </Tooltip>

                            <Tooltip content={isModalOpen ? "Exit fullscreen" : "Fullscreen view"} relationship="label">
                                <Button
                                    icon={isModalOpen ? <ArrowMinimize24Regular /> : <ArrowMaximize24Regular />}
                                    onClick={toggleModal}
                                    className={classes.actionButton}
                                />
                            </Tooltip>
                        </div>
                    )}

                    {/* Left side actions*/}
                    <div className={classes.footerActions}>
                        {(selectedArtifact.type === 'image' || selectedArtifact.type === 'png') && (
                            <Tooltip content={isCopied ? "Copied!" : "Copy to clipboard"} relationship="label">
                                <Button
                                    style={{ color: canvasPallet.textInverse }}
                                    icon={isCopied ? <CheckmarkCircle24Regular /> : <Copy24Regular />}
                                    onClick={handleCopy}
                                    className={`${classes.actionButton} ${isCopied ? classes.actionButtonSuccess : ''}`}
                                />
                            </Tooltip>
                        )}

                        <div className={classes.buttonWithProgress}>
                            <Tooltip
                                content={isExporting ? "Exporting..." : "Export artifact"}
                                relationship="label"
                            >
                                <Button
                                    style={{ color: canvasPallet.textInverse }}
                                    icon={<ArrowExportUp24Regular />}
                                    onClick={handleExport}
                                    className={classes.actionButton}
                                    disabled={isExporting}
                                />
                            </Tooltip>
                            {isExporting && (
                                <>
                                    <div
                                        className={classes.progressIndicator}
                                        style={{ width: `${downloadProgress}%` }}
                                    />
                                    <span className={classes.loadingText}>
                                        {downloadProgress < 100 ? 'Exporting...' : 'Complete!'}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>

                </div>

                {isModalOpen && (
                    <div className={classes.modalOverlay} onClick={toggleModal}>
                        <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                            <div className={classes.modalImageWrapper}>
                                {(selectedArtifact.type === 'image' || selectedArtifact.type === 'png') && (
                                    <img
                                        ref={imageRef}
                                        src={`data:image/png;base64,${selectedArtifact.content}`}
                                        alt={selectedArtifact.name}
                                        className={classes.image}
                                        style={{ transform: `scale(${scale})` }}
                                    />
                                )}

                                {selectedArtifact.type === 'code' && renderCodeContent(selectedArtifact.content!.toString())}
                            </div>
                            <div className={classes.footerActions} style={{
                                position: 'absolute',
                                top: '16px',
                                right: '16px',
                                zIndex: 999,
                                background: canvasPallet.background,
                                borderRadius: '8px',
                            }}>
                                <Tooltip content="Zoom in" relationship="label">
                                    <Button
                                        icon={<ZoomIn24Regular />}
                                        onClick={handleZoomIn}
                                        disabled={scale >= 3}
                                        className={classes.actionButton}
                                        style={{ color: 'rgb(0, 64, 104, 1)' }}
                                    />
                                </Tooltip>

                                <Tooltip content="Zoom out" relationship="label">
                                    <Button
                                        icon={<ZoomOut24Regular />}
                                        onClick={handleZoomOut}
                                        disabled={scale <= 0.5}
                                        className={classes.actionButton}
                                        style={{ color: 'rgb(0, 64, 104, 1)' }}
                                    />
                                </Tooltip>

                                <Tooltip content="Reset zoom" relationship="label">
                                    <Button
                                        icon={<ArrowReset24Regular />}
                                        onClick={handleResetZoom}
                                        disabled={scale === 1}
                                        className={classes.actionButton}
                                        style={{ color: 'rgb(0, 64, 104, 1)' }}
                                    />
                                </Tooltip>

                                <Tooltip content="Exit fullscreen" relationship="label">
                                    <Button
                                        icon={<ArrowMinimize24Regular />}
                                        onClick={toggleModal}
                                        className={classes.actionButton}
                                        style={{ color: 'rgb(0, 64, 104, 1)' }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderGeneratingArtifact = () => {
        return (
            <div className={classes.emptyState}>
                <ArtifactSpinner />
            </div>
        );
    };

    return (
        <div
            style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: canvasPallet.background,
                borderRadius: '8px',
                boxShadow: `0 4px 8px ${canvasPallet.shadow}`,
                overflow: 'hidden',
            }}
        >
            {botResponseStatus ? renderGeneratingArtifact() : (showDetails ? renderArtifactDetails() : renderArtifactsList())}
        </div>
        );
    };

export default Canvas;
