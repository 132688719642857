import { ChatMemorySource } from "../../../libs/models/ChatMemorySource";

export interface CommonDataState {   
    tokenData:TokenData; 
    isUploadedFileDeleted:boolean;
    isConfirmationDialogOpen:boolean;
    confirmationDialogResponse:string;
    itemToDelete:ChatMemorySource;
    currentExportMessageId:string;
    isExportInProgress:boolean;
    isCollapsed: boolean;
    isSmallScreen: boolean;
    isOverlay: boolean;
    showQuickGuide: boolean;
    showRecentGPTs: boolean;
    isDeletingChats: boolean;
 }
 export interface TokenData{
     tokenExpiry: string;
 }
 
 export const initialState: CommonDataState = {
     tokenData:{"tokenExpiry":""},
     isUploadedFileDeleted:false,
     isConfirmationDialogOpen:false,
     confirmationDialogResponse:"No",     
     itemToDelete:{"id":"", "chatId":"", "sourceType":"", "name":"", "sharedBy":"", "createdOn":0, "tokens":0, "size":0},
     currentExportMessageId:"",
     isExportInProgress:false,
     isCollapsed: false,
     isSmallScreen: false,
     isOverlay: false,
     showQuickGuide: false,
     showRecentGPTs: false,
     isDeletingChats: false,
 };