import * as React from 'react';
import {
    makeStyles,
    Image,
    Text,
    Avatar,
    Button,
    Card,
    CardHeader,
    tokens,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
    shorthands,
} from '@fluentui/react-components';
import { IGPT } from '../../libs/models/GPT';
import { customColors, customFonts } from '../../styles';
import { IADEmployee } from '../../libs/models/ADEmployee';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { useGPT } from '../../libs/hooks/useGPT';
import { CardBody } from 'react-bootstrap';
import { ScrollBarStyles } from '../../styles';
import { toast } from 'react-toastify';
import { useChat } from '../../libs/hooks';
import { useNavigate } from 'react-router-dom';

const useClasses = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        padding: tokens.spacingVerticalL,
        backgroundColor: tokens.colorNeutralBackground1,
        ...ScrollBarStyles,
    },
    parentCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    childCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow16,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
    },
    childrenCardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%',
        height: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
    },
    headerText: {
        fontFamily: customFonts.TitleFont,
        fontSize: 'clamp(2rem, 8vw, 68px)',
        '@media (max-width: 1000px)': {
            lineHeight: '1em',
        },
    },

    logo: {
        width: '80px',
        height: '80px',
        marginRight: '10px',
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
    },
    productName: {
        fontSize: tokens.fontSizeHero700,
        fontWeight: tokens.fontWeightBold,
        color: customColors.protivitiDarkBlue,
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        textAlign: 'center',
        wordWrap: 'break-word',
        fontSize: tokens.fontSizeBase400,
        marginBottom: tokens.spacingVerticalM,
        color: customColors.protivitiDarkBlue,
    },
    owner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalL,
    },
    ownerDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalS,
        marginLeft: tokens.spacingHorizontalS,
    },
    ownerName: {
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
    },
    ownerEmail: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground3,
    },
    childFAQCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow16,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
    },
    faq: {
        borderRadius: tokens.borderRadiusMedium,
        height: 'auto',
        marginBottom: '0',
    },
    faqQuestionContainer: {
        padding: tokens.spacingVerticalXS + ' ' + tokens.spacingHorizontalM,
        marginLeft: '-10px',
        marginBottom: '-10px',
    },
    faqQuestion: {
        color: customColors.protivitiOrange,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: '16px',
    },
    faqAnswerContainer: {
        paddingTop: tokens.spacingVerticalS,
        backgroundColor: customColors.lightBlueGrayBackground3,
        borderRadius: tokens.borderRadiusLarge,
        paddingBottom: tokens.spacingVerticalM,
        paddingLeft: tokens.spacingHorizontalM,
        marginTop: '5px',
        marginBottom: '5px',
        width: '95%',
        height: '90%',
        overflow: 'auto',
    },
    faqAnswer: {
        color: customColors.protivitiDarkBlue,
        wordWrap: 'break-word',
    },
    dialogSurfaceContainer: {
        maxWidth: '75%',
        height: '80%',
        left: '20%',
        overflowY: 'auto',
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                scrollPaddingBlockStart: '2px',
                backgroundColor: customColors.protivitiDarkBlue,
                visibility: 'visible',
                borderRadius: '8px',
            },
            '&::-webkit-scrollbar-track': {
                scrollPaddingBlockStart: '2px',
                backgroundColor: tokens.colorNeutralBackground1,
                WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
                visibility: 'visible',
                borderRadius: '8px',
            },
        },
        '@media (max-width: 1000px)': {
            left: '5rem',
            width: '90%',
        },
    },
    dialogTitleContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '20px',
    },
    dialogHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    dialogCardHeader: {
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
    },
    dialogActions: {
        width: '100%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
    },
    closeButton: {
        width: '35px',
        minWidth: '35px',
        bottom: '20px',
        borderRadius: '0',
        ...shorthands.borderColor(customColors.protivitiOrange),
        color: customColors.protivitiDarkBlue,
        '&:hover': {
            ...shorthands.borderColor(customColors.protivitiDarkBlue),
        },
    },
    startChatButton: {
        borderRadius: '0',
        backgroundColor: customColors.protivitiOrange,
        border: 'none',
        color: customColors.white,
        '&:hover': {
            backgroundColor: customColors.protivitiDarkBlue,
            color: customColors.white,
        },
    },
    modalParentCard: {
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'scroll',
    },

    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        padding: '20px',
        width: '100%',
        height: 'inherit',
        margin: '0 auto',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    modalCloseButton: {
        fontFamily: customFonts.Lato,
        border: '1px solid #000',
        borderRadius: '0',
        padding: '5px 45px',
        margin: '0 8px 8px 0',
        float: 'right' as 'right',
        color: '#000',
        background: 'transparent',
        fontWeight: '600',
        width: '20%',
        marginLeft: 'auto',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '10%',
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
});

interface GPTOverviewProps {
    gpt?: IGPT | undefined;
    gptId?: string;
    isModal?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
}

export const GPTOverview: React.FC<GPTOverviewProps> = ({ gpt, gptId, isModal, isOpen, onClose }) => {
    const classes = useClasses();
    const chat = useChat();
    const navigate = useNavigate();
    const [adEmployeeInfo, setADEmployeeInfo] = React.useState<IADEmployee[] | undefined>();
    const [modalIsOpen, setModalIsOpen] = useState(isOpen);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const gptService = useGPT();
    const [isCreatingChat, setIsCreatingChat] = useState(false);

    const [blobUrl, setBlobUrl] = useState<string>('');

    const activeGPT = React.useMemo(() => {
        if (!gpt) return userGPTs.find((g) => g.id === gptId);
        return userGPTs.find((g) => g.gptEndpoint === conversations[selectedId]?.gptEndpoint);
    }, [selectedId, userGPTs]);
    const hasOwners = Boolean(activeGPT?.gptOwnerInfo && activeGPT.gptOwnerInfo.length > 0);
    const hasSupportAddress = Boolean(activeGPT?.gptSupportAddress && activeGPT.gptSupportAddress.trim() !== '');

    useEffect(() => {
        if (hasSupportAddress){
            return;
        }
        const fetchOwners = async () => {
            try {
                const owners = activeGPT?.gptOwnerInfo ?? [];
                const results = await Promise.all(
                    owners.map(async (owner) => {
                        const employees = await gptService.getADEmployeeByUpnAsync(owner.userPrincipalName);
                        return employees.length ? employees[0] : null;
                    })
                );
                const validOwners = results.filter((r) => r !== null) as IADEmployee[];
                setADEmployeeInfo(validOwners);
            } catch (error) {
                console.error('Error fetching GPT owners:', error);
            }
        };
    
        fetchOwners();
    }, [activeGPT, gptService]);

    
    useEffect(() => {
        const fetchBlobUrl = async () => {
            try {
                if (gpt?.gptIconFileName) {
                    const url = process.env.REACT_APP_CDN_IMAGE_URL + gpt.gptIconFileName;
                    setBlobUrl(url);
                } else if (activeGPT?.gptIconFileName) {
                    const url = process.env.REACT_APP_CDN_IMAGE_URL + activeGPT.gptIconFileName;
                    setBlobUrl(url);
                }
            } catch (error) {
                console.error('Error fetching SAS URL:', error);
            }
        };

        fetchBlobUrl();
    }, [gpt?.gptIconFileName, activeGPT?.gptIconFileName]);

    const createChat = useCallback(async () => {
        if (activeGPT) {
            setIsCreatingChat(true);
            var newChatId = await toast.promise(chat.createChat(activeGPT), {
                pending: {
                    render: 'Creating chat...',
                    position: 'top-right',
                    className: classes.toastMessage,
                },
            });
            if (newChatId == 'Chat session cannot be created more than 40.')
                toast.error('More than 40 chat sessions cannot be created. Remove a chat to create a new chat!', {
                    className: classes.toastMessage,
                });
            else if (newChatId.startsWith('Unable to create new chat'))
                toast.error(null, {
                    className: classes.toastMessage,
                });
            else navigate(`/chat/${newChatId}`);
        }
        setIsCreatingChat(false);
    }, []);

    // const getADEmployeeByUpnAsync = async (upn: string) => {
    //     const adEmployee = await gptService.getADEmployeeByUpnAsync(upn);
    //     setADEmployeeInfo(adEmployee);
    // };

    const formatFAQAnswer = (answer: string) => {
        return answer
            .replaceAll(/\\+/g, '\\')
            .split('\\n')
            .map((prompt, index) => (
                <li key={index}>
                    <Text>{prompt}</Text>
                </li>
            ));
    };
    const validateLink = (htmlString: string) => {
        // Regular expression to match links, excluding any HTML tags at the end
        // Since response from bot is plain text, sometimes line breaks and other html tags are included in the response for readability.
        const linkRegex =
            /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?(?=(<br|<p|<div|<span)\s*\/>|$)/g;
    
        const result = htmlString?.replace(linkRegex, function (link) {
            try {
                // Basic validation to check if the link is a valid URL
                if (!/^https?:\/\/\S+$/.test(link)) {
                    throw new Error('Invalid URL format');
                }
    
                // Parse URL first -- URL class handles cybersecurity concerns related to URL parsing and manipulation
                const safeHref = new URL(link).toString();
    
                // Replace each link with anchor tags
                return safeHref;
            } catch (e) {
                console.error(`Invalid URL: ${link}`, e);
                // Return the original link if it's invalid
                return link;
            }
        });
    
        return result;
    }
    const closeModal = () => {
        setModalIsOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    // useEffect(() => {
    //     if (activeGPT) {
    //         getADEmployeeByUpnAsync(activeGPT?.gptOwnerInfo?.[0]?.userPrincipalName ?? '');
    //     }
    // }, []);

    if (!isModal) {
        return (
            <div className={classes.container}>
                <Card className={classes.parentCard}>
                    <CardHeader
                        className={classes.header}
                        header={
                            <Text className={classes.headerText} size={1000} weight="semibold">
                                {gpt?.name}
                            </Text>
                        }
                        image={
                            <Image src={gpt?.gptIconFileName ? blobUrl : gpt?.cardImageURL} className={classes.logo} />
                        }
                    />

                    <Text className={classes.description}>{gpt?.description}</Text>

                    <div className={classes.childrenCardContainer}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                alignItems: 'start',
                                margin: '0 10px 0 5px',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {gpt?.gptCapabilities && (
                                <Card className={classes.childCard} style={{ width: '100%', height: 'auto' }}>
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{ fontFamily: customFonts.TitleFont }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                Capabilities
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px' }}
                                    />
                                    {gpt?.gptCapabilities && (
                                        <CardBody
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxHeight: '60%',
                                                marginBottom: '3px',
                                            }}
                                        >
                                            <ul style={{ paddingLeft: '12px' }}>
                                                {gpt?.gptCapabilities.map((capability, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            color: customColors.protivitiDarkBlue,
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            justifyContent: 'start',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <img
                                                            src={gpt?.gptIconFileName ? blobUrl : gpt.cardImageURL}
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginRight: '5px',
                                                            }}
                                                        />
                                                        <Text size={400}>{capability.replace('\\n', '')}</Text>
                                                    </div>
                                                ))}
                                            </ul>
                                        </CardBody>
                                    )}
                                </Card>
                            )}
                            {gpt?.faQs && gpt.faQs.length > 0 && (
                                <Card
                                    className={classes.childFAQCard}
                                    style={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        height: 'max-content',
                                        marginTop: '5px',
                                    }}
                                >
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{ fontFamily: customFonts.TitleFont }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                Frequently Asked Questions
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px', marginBottom: '-15px' }}
                                    />
                                    {gpt?.faQs && (
                                        <CardBody style={{ width: '100%', maxWidth: '100%' }}>
                                            {gpt?.faQs.map((faq, index) => (
                                                
                                                <Accordion collapsible className={classes.faq}>
                                                    <AccordionItem value={index.toString()}>
                                                        <AccordionHeader className={classes.faqQuestionContainer}>
                                                            <Text className={classes.faqQuestion}>{faq.question}</Text>
                                                        </AccordionHeader>
                                                        <AccordionPanel className={classes.faqAnswerContainer}>
                                                            {' '}
                                                            {faq.question.toLowerCase().includes('prompt') ? (
                                                                <div className={classes.faqAnswer}>
                                                                    <ul>
                                                                        <Text>{formatFAQAnswer(faq.answer)}</Text>
                                                                    </ul>
                                                                </div>
                                                            ) : (
                                                                <Text className={classes.faqAnswer}>{faq.answer}</Text>
                                                            )}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            ))}
                                        </CardBody>
                                    )}
                                </Card>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '40%',
                                height: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            {hasOwners && !hasSupportAddress && adEmployeeInfo && adEmployeeInfo.length > 0 && (
                                    <Card
                                        className={classes.childCard}
                                        style={
                                            gpt?.gptKnowledgeBase
                                                ? {
                                                    width: '100%',
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }
                                                : {
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }
                                        }
                                    >
                                        <CardHeader
                                            className={classes.header}
                                            style={{ marginTop: '-5px' }}
                                            header={
                                                <Text
                                                    style={{ fontFamily: customFonts.TitleFont, color: customColors.protivitiDarkBlue }}
                                                    size={800}
                                                    weight="semibold"
                                                >
                                                    GPT Owners
                                                    <Divider />
                                                </Text>
                                            }
                                        />
                                        <CardBody>
                                            {(() => {
                                                const firstOwner = adEmployeeInfo[0];
     
                                                const otherOwners = adEmployeeInfo.slice(1);

                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center', 
                                                            textAlign: 'center',  
                                                            height: '100%',
                                                        }}
                                                    >
                                                        {/* First Owner */}
                                                        <div className={classes.owner}>
                                                            <Avatar
                                                                name={`${firstOwner?.givenName ?? ''} ${firstOwner?.surName ?? ''}`}
                                                                image={{ src: firstOwner?.photoUrl }}
                                                                shape="square"
                                                                size={128}
                                                            />
                                                            <div className={classes.ownerDetails}>
                                                                <Text className={classes.ownerName} block>
                                                                    {firstOwner?.givenName} {firstOwner?.surName}
                                                                </Text>
                                                                <Text className={classes.ownerEmail} block>
                                                                    <a
                                                                        href={`mailto:${firstOwner?.userPrincipalName}?subject=GPT%20Feedback`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {firstOwner?.userPrincipalName}
                                                                    </a>
                                                                </Text>
                                                                <Text
                                                                    style={{
                                                                        fontStyle: 'italic',
                                                                        textAlign: 'center',
                                                                        marginTop: '20px',
                                                                        color: customColors.protivitiDarkBlue,
                                                                        fontFamily: customFonts.Lato,
                                                                        width: 'auto',
                                                                    }}
                                                                >
                                                                    {gpt?.gptOwnerBlurb
                                                                        ? `"${gpt?.gptOwnerBlurb}"`
                                                                        : `"Hope you enjoy using ${gpt?.name}! Please reach out to me if you have any questions or feedback."`}
                                                                </Text>
                                                            </div>
                                                        </div>

                                                        {otherOwners.length > 0 && (
                                                            <div
                                                                style={{
                                                                    marginTop: '20px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontFamily: customFonts.TitleFont,
                                                                        color: customColors.protivitiDarkBlue,
                                                                    }}
                                                                    size={800}
                                                                    weight="semibold"
                                                                >
                                                                    Co-owners
                                                                </Text>
                                                                <Divider style={{ width: '100%', maxWidth: '220px' }} />
                                                                {otherOwners.map((owner, index) => (
                                                                    <div key={index} style={{ marginTop: '10px' }}>
                                                                        <Text className={classes.ownerName} block>
                                                                            {owner.givenName} {owner.surName}
                                                                        </Text>
                                                                        <Text className={classes.ownerEmail} block>
                                                                            <a
                                                                                href={`mailto:${owner.userPrincipalName}?subject=GPT%20Feedback`}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {owner.userPrincipalName}
                                                                            </a>
                                                                        </Text>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })()}
                                        </CardBody>
                                    </Card>
                                )}


                                {hasSupportAddress && (
                                    <Card
                                        className={classes.childCard}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CardHeader
                                            className={classes.header}
                                            style={{ marginTop: '-5px' }}
                                            header={
                                                <Text
                                                    style={{
                                                        fontFamily: customFonts.TitleFont,
                                                        color: customColors.protivitiDarkBlue,
                                                    }}
                                                    size={800}
                                                    weight="semibold"
                                                >
                                                    GPT Support
                                                    <Divider />
                                                </Text>
                                            }
                                        />
                                        <CardBody>
                                            <div className={classes.owner}>
                                                <Avatar
                                                    name="GPT Support"
                                                    image={{ src: blobUrl || gpt?.cardImageURL }}
                                                    shape="square"
                                                    size={128}
                                                />
                                                <div className={classes.ownerDetails}>
    
                                                    <Text className={classes.ownerName}>GPT Support</Text>
                                                    
                                                    <Text className={classes.ownerEmail}>
                                                        <a
                                                            href={`mailto:${activeGPT?.gptSupportAddress}?subject=GPT%20Support`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {activeGPT?.gptSupportAddress}
                                                        </a>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontStyle: 'italic',
                                                            textAlign: 'center',
                                                            marginTop: '20px',
                                                            color: customColors.protivitiDarkBlue,
                                                            fontFamily: customFonts.Lato,
                                                            width: 'auto',
                                                        }}
                                                    >
                                                        {`"Hope you enjoy using ${
                                                            activeGPT?.name || 'this GPT'
                                                        }! Please reach out to us at the support address if you have any questions or feedback."`}
                                                    </Text>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}



                            {gpt?.gptKnowledgeBase && (
                                <Card
                                    className={classes.childCard}
                                    style={{
                                        width: '100%',
                                        height: '50%',
                                        marginTop: '5px',
                                    }}
                                >
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{
                                                    fontFamily: customFonts.TitleFont,
                                                    color: customColors.protivitiDarkBlue,
                                                }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                GPT Knowledge Source
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px' }}
                                    />
                                    <CardBody>
                                        <Text
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                height: '100%',
                                                fontFamily: customFonts.Lato,
                                                color: customColors.protivitiDarkBlue,
                                                fontSize: '12px',
                                                marginLeft: '5px',
                                            }}
                                            block
                                        >
                                            {gpt?.gptKnowledgeBase}
                                        </Text>
                                    </CardBody>
                                </Card>
                            )}
                            {gpt?.relatedLinks && gpt?.relatedLinks.length > 0 && (
                                <Card
                                    className={classes.childCard}
                                    style={{
                                        width: '100%',
                                        height: '50%',
                                        maxHeight: 'max-content',
                                        marginTop: '5px',
                                    }}
                                >
                                    <CardHeader
                                        className={classes.header}
                                        header={
                                            <Text
                                                style={{
                                                    fontFamily: customFonts.TitleFont,
                                                    color: customColors.protivitiDarkBlue,
                                                }}
                                                size={800}
                                                weight="semibold"
                                            >
                                                Related Links
                                                <Divider />
                                            </Text>
                                        }
                                        style={{ marginTop: '-5px' }}
                                    />
                                    <CardBody>
                                        <Text
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                height: '100%',
                                                fontFamily: customFonts.Lato,
                                                color: customColors.protivitiDarkBlue,
                                                fontSize: '12px',
                                                marginLeft: '5px',
                                            }}
                                            block
                                        >
                                            {gpt &&
                                                gpt?.relatedLinks.map((relatedLink, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            color: customColors.protivitiDarkBlue,
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            justifyContent: 'start',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <a
                                                            href={validateLink(relatedLink.link)}
                                                            style={{ fontSize: '16px', fontWeight: '500' }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {relatedLink.title}
                                                        </a>
                                                    </div>
                                                ))}
                                        </Text>
                                    </CardBody>
                                </Card>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
    return (
        <Dialog modalType="modal" open={modalIsOpen}>
            <DialogSurface className={classes.dialogSurfaceContainer}>
                <DialogTitle className={classes.dialogTitleContainer}>
                    <div className={classes.dialogActions}>
                        <DialogTrigger>
                            <Button
                                onClick={createChat}
                                style={{
                                    cursor: isCreatingChat ? 'progress' : 'pointer',
                                    opacity: isCreatingChat ? 0.5 : 1,
                                    pointerEvents: isCreatingChat ? 'none' : 'auto',
                                    borderColor: customColors.protivitiOrange,
                                }}
                                className={classes.startChatButton}
                            >
                                Start a chat
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger action="close">
                            <Button onClick={closeModal} className={classes.closeButton}>
                                X
                            </Button>
                        </DialogTrigger>
                    </div>
                    <div className={classes.dialogHeader}>
                        <CardHeader
                            className={classes.dialogCardHeader}
                            header={
                                <Text style={{ fontFamily: customFonts.TitleFont }} size={1000} weight="semibold">
                                    {activeGPT?.name}
                                </Text>
                            }
                            image={<Image src={activeGPT?.cardImageURL} className={classes.logo} />}
                        />
                    </div>
                    <Text className={classes.description}>{activeGPT?.description}</Text>
                </DialogTitle>
                <DialogBody className={classes.modalContainer}>
                    <div style={{ width: '100%' }}>
                        <Card className={classes.modalParentCard}>
                            <div className={classes.childrenCardContainer} style={{ width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        margin: '0 10px 0 5px',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    {activeGPT?.gptCapabilities && (
                                        <Card className={classes.childCard} style={{ width: '100%', height: 'auto' }}>
                                            <CardHeader
                                                className={classes.header}
                                                header={
                                                    <Text
                                                        style={{ fontFamily: customFonts.TitleFont }}
                                                        size={800}
                                                        weight="semibold"
                                                    >
                                                        Capabilities
                                                        <Divider />
                                                    </Text>
                                                }
                                                style={{ marginTop: '-5px' }}
                                            />
                                            {activeGPT?.gptCapabilities && (
                                                <CardBody
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxHeight: '60%',
                                                        marginBottom: '3px',
                                                    }}
                                                >
                                                    <ul>
                                                        {activeGPT?.gptCapabilities.map((capability, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    color: customColors.protivitiDarkBlue,
                                                                    display: 'flex',
                                                                    alignItems: 'start',
                                                                    justifyContent: 'start',
                                                                    marginBottom: '5px',
                                                                }}
                                                            >
                                                                <img
                                                                    src={activeGPT.cardImageURL}
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        marginRight: '5px',
                                                                    }}
                                                                />
                                                                <Text size={400}>{capability.replace('\\n', '')}</Text>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            )}
                                        </Card>
                                    )}
                                    {activeGPT?.faQs && activeGPT.faQs.length > 0 && (
                                        <Card
                                            className={classes.childFAQCard}
                                            style={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                height: 'max-content',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <CardHeader
                                                className={classes.header}
                                                header={
                                                    <Text
                                                        style={{ fontFamily: customFonts.TitleFont }}
                                                        size={800}
                                                        weight="semibold"
                                                    >
                                                        Frequently Asked Questions
                                                        <Divider />
                                                    </Text>
                                                }
                                                style={{ marginTop: '-5px', marginBottom: '-20px' }}
                                            />
                                            {activeGPT?.faQs && (
                                                <CardBody style={{ width: '100%', maxWidth: '100%' }}>
                                                    {activeGPT?.faQs.map((faq, index) => (
                                                        <Accordion collapsible className={classes.faq}>
                                                            <AccordionItem value={index.toString()}>
                                                                <AccordionHeader
                                                                    className={classes.faqQuestionContainer}
                                                                >
                                                                    <Text className={classes.faqQuestion}>
                                                                        {faq.question}
                                                                    </Text>
                                                                </AccordionHeader>
                                                                <AccordionPanel className={classes.faqAnswerContainer}>
                                                                    {' '}
                                                                    {faq.question.toLowerCase().includes('prompt') ? (
                                                                        <div className={classes.faqAnswer}>
                                                                            <ul>
                                                                                <Text>
                                                                                    {formatFAQAnswer(faq.answer)}
                                                                                </Text>
                                                                            </ul>
                                                                        </div>
                                                                    ) : (
                                                                        <Text className={classes.faqAnswer}>
                                                                            {faq.answer}
                                                                        </Text>
                                                                    )}
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    ))}
                                                </CardBody>
                                            )}
                                        </Card>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '40%',
                                        height: '100%',
                                    }}
                                >
                                    {hasOwners && !hasSupportAddress && adEmployeeInfo && adEmployeeInfo.length > 0 && (
                                        <Card
                                            className={classes.childCard}
                                            style={
                                                gpt?.gptKnowledgeBase
                                                    ? {
                                                        width: '100%',
                                                        height: 'auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }
                                                    : {
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }
                                            }
                                        >
                                            <CardHeader
                                                className={classes.header}
                                                style={{ marginTop: '-5px' }}
                                                header={
                                                    <Text
                                                        style={{ fontFamily: customFonts.TitleFont, color: customColors.protivitiDarkBlue }}
                                                        size={800}
                                                        weight="semibold"
                                                    >
                                                        GPT Owners
                                                        <Divider />
                                                    </Text>
                                                }
                                            />
                                            <CardBody>
                                                {(() => {
                                                    const firstOwner = adEmployeeInfo[0];
                                                    const otherOwners = adEmployeeInfo.slice(1);

                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center', 
                                                                textAlign: 'center',  
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <div className={classes.owner}>
                                                                <Avatar
                                                                    name={`${firstOwner?.givenName ?? ''} ${firstOwner?.surName ?? ''}`}
                                                                    image={{ src: firstOwner?.photoUrl }}
                                                                    shape="square"
                                                                    size={128}
                                                                />
                                                                <div className={classes.ownerDetails}>
                                                                    <Text className={classes.ownerName} block>
                                                                        {firstOwner?.givenName} {firstOwner?.surName}
                                                                    </Text>
                                                                    <Text className={classes.ownerEmail} block>
                                                                        <a
                                                                            href={`mailto:${firstOwner?.userPrincipalName}?subject=GPT%20Feedback`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {firstOwner?.userPrincipalName}
                                                                        </a>
                                                                    </Text>
                                                                    <Text
                                                                        style={{
                                                                            fontStyle: 'italic',
                                                                            marginTop: '20px',
                                                                            textAlign: 'center',
                                                                            color: customColors.protivitiDarkBlue,
                                                                            fontFamily: customFonts.Lato,
                                                                            width: 'auto',
                                                                        }}
                                                                    >
                                                                        {gpt?.gptOwnerBlurb
                                                                            ? `"${gpt?.gptOwnerBlurb}"`
                                                                            : `"Hope you enjoy using ${gpt?.name}! Please reach out to me if you have any questions or feedback."`}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            {otherOwners.length > 0 && (
                                                                <div
                                                                    style={{
                                                                        marginTop: '20px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={{
                                                                            fontFamily: customFonts.TitleFont,
                                                                            color: customColors.protivitiDarkBlue,
                                                                        }}
                                                                        size={800}
                                                                        weight="semibold"
                                                                        block
                                                                    >
                                                                        Co-owners
                                                                    </Text>
                                                                    <Divider style={{ width: '100%', maxWidth: '220px' }} />

                                                                    {otherOwners.map((owner, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                marginTop: '10px',
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            <Text className={classes.ownerName} block>
                                                                                {owner.givenName} {owner.surName}
                                                                            </Text>
                                                                            <Text className={classes.ownerEmail} block>
                                                                                <a
                                                                                    href={`mailto:${owner.userPrincipalName}?subject=GPT%20Feedback`}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    {owner.userPrincipalName}
                                                                                </a>
                                                                            </Text>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                        </div>
                                                    );
                                                })()}
                                            </CardBody>
                                        </Card>
                                    )}
                                        {hasSupportAddress && (
                                            <Card
                                                className={classes.childCard}
                                                style={{
                                                    width: '100%',
                                                    minHeight: 'fit-content',
                                                    maxHeight: 'max-content',
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                <CardHeader
                                                    className={classes.header}
                                                    style={{ marginTop: '-5px' }}
                                                    header={
                                                        <Text
                                                            style={{
                                                                fontFamily: customFonts.TitleFont,
                                                                color: customColors.protivitiDarkBlue,
                                                            }}
                                                            size={800}
                                                            weight="semibold"
                                                        >
                                                            GPT Support
                                                            <Divider />
                                                        </Text>
                                                    }
                                                />
                                                <CardBody>
                                                    <div className={classes.owner}>
                                                        <Avatar
                                                            name="GPT Support"
                                                            image={{ src: blobUrl || activeGPT?.cardImageURL }}
                                                            shape="square"
                                                            size={128}
                                                        />
                                                        <div className={classes.ownerDetails}>
                                                            <Text className={classes.ownerEmail}>
                                                                <a
                                                                    href={`mailto:${activeGPT?.gptSupportAddress}?subject=GPT%20Support`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {activeGPT?.gptSupportAddress}
                                                                </a>
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                    textAlign: 'center',
                                                                    marginTop: '20px',
                                                                    color: customColors.protivitiDarkBlue,
                                                                    fontFamily: customFonts.Lato,
                                                                    width: 'auto',
                                                                }}
                                                            >
                                                                {`"Hope you enjoy using ${
                                                                    activeGPT?.name || 'this GPT'
                                                                }! Please reach out to us at the support address if you have any questions or feedback."`}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}



                                    {activeGPT?.gptKnowledgeBase && (
                                        <Card
                                            className={classes.childCard}
                                            style={{
                                                width: '100%',
                                                height: '50%',
                                                maxHeight: 'max-content',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <CardHeader
                                                className={classes.header}
                                                header={
                                                    <Text
                                                        style={{
                                                            fontFamily: customFonts.TitleFont,
                                                            color: customColors.protivitiDarkBlue,
                                                        }}
                                                        size={800}
                                                        weight="semibold"
                                                    >
                                                        GPT Knowledge Source
                                                        <Divider />
                                                    </Text>
                                                }
                                                style={{ marginTop: '-5px' }}
                                            />
                                            <CardBody>
                                                <Text
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'start',
                                                        alignItems: 'start',
                                                        height: '100%',
                                                        fontFamily: customFonts.Lato,
                                                        color: customColors.protivitiDarkBlue,
                                                        fontSize: '12px',
                                                        marginLeft: '5px',
                                                    }}
                                                    block
                                                >
                                                    {activeGPT?.gptKnowledgeBase}
                                                </Text>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {activeGPT?.relatedLinks && activeGPT?.relatedLinks.length > 0 && (
                                        <Card
                                            className={classes.childCard}
                                            style={{
                                                width: '100%',
                                                height: '50%',
                                                maxHeight: 'max-content',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <CardHeader
                                                className={classes.header}
                                                header={
                                                    <Text
                                                        style={{
                                                            fontFamily: customFonts.TitleFont,
                                                            color: customColors.protivitiDarkBlue,
                                                        }}
                                                        size={800}
                                                        weight="semibold"
                                                    >
                                                        Related Links
                                                        <Divider />
                                                    </Text>
                                                }
                                                style={{ marginTop: '-5px' }}
                                            />
                                            <CardBody>
                                                <Text
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'start',
                                                        alignItems: 'start',
                                                        height: '100%',
                                                        fontFamily: customFonts.Lato,
                                                        color: customColors.protivitiDarkBlue,
                                                        fontSize: '12px',
                                                        marginLeft: '5px',
                                                    }}
                                                    block
                                                >
                                                    {activeGPT &&
                                                        activeGPT?.relatedLinks.map((relatedLink, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    color: customColors.protivitiDarkBlue,
                                                                    display: 'flex',
                                                                    alignItems: 'start',
                                                                    justifyContent: 'start',
                                                                    marginBottom: '5px',
                                                                }}
                                                            >
                                                                <a
                                                                    href={validateLink(relatedLink.link)}
                                                                    style={{ fontSize: '16px', fontWeight: '500' }}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {relatedLink.title}
                                                                </a>
                                                            </div>
                                                        ))}
                                                </Text>
                                            </CardBody>
                                        </Card>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default GPTOverview;
