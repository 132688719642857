
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChat } from '../../../libs/hooks';
import Loading from '../../views/Loading';

export const GPTChatRedirect: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { loadChats } = useChat();

    // Local state to store the parameters
    const [params, setParams] = useState<{ gptId: string | null; shellId: string | null }>({
        gptId: null,
        shellId: null,
    });

    useEffect(() => {
        // Extract parameters only on the first render
        if (!params.gptId || !params.shellId) {
            const searchParams = new URLSearchParams(location.search);
            const gptId = searchParams.get("gptId");
            const shellId = searchParams.get("shellId");

            setParams({ gptId, shellId });

            if (gptId && shellId) {
                loadChats(gptId, shellId).then(() => {
                    // Navigate to the main chat page after loading
                    navigate("/", { replace: true });
                });
            } else {
                console.error("Missing gptId or shellId in the URL.");
                navigate("/", { replace: true }); // Redirect to the main page if parameters are missing
            }
        }
    }, [location, navigate, params, loadChats]);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Loading text="Loading Chats..." />
        </div>
    );
};




