

import { TabValue } from '@fluentui/react-components';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { ChatState } from './ChatState';

export type Conversations = Record<string, ChatState>;

export interface ConversationsState {
    conversations: Conversations;
    selectedId: string;
    selectedTab: TabValue;
    savedInput: string;
    selectedConversations: ChatState[];
    canvasOpen: boolean;
}

export const initialState: ConversationsState = {
    conversations: {},
    selectedId: '',
    selectedTab: 'chat',
    savedInput: '',
    selectedConversations: [],
    canvasOpen: false,
};

export interface UpdateConversationPayload {
    id: string;
    messages: IChatMessage[];
}

export interface ConversationTitleChange {
    id: string;
    newTitle: string;
    isTitleGenerated: boolean;
}

export interface ConversationInputChange {
    id: string;
    newInput: string;
}

export interface ConversationSystemDescriptionChange {
    id: string;
    newSystemDescription: string;
}

export interface UpdatePluginStatePayload {
    id: string;
    pluginId: string;
    newState: boolean;
}

export type AppendMessageArgs = {
    chatId: string;
    messages: IChatMessage[]; 
};

export interface ChatInfo {
    chatId: string;
    gptEndpoint: string;
}