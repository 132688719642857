import { Button, makeStyles, shorthands, Tooltip } from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { customColors, customFonts } from '../../../styles';
import { IGPT } from '../../../libs/models/GPT';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { appInsights } from '../../../App';
import { updateChatInput } from '../../../redux/features/currentmessages/currentmessagesSlice';
import { shallowEqual } from 'react-redux';
import { ISavedPrompt } from '../../../libs/models/ISavedPrompt';
import { DeleteRegular, EditRegular } from '@fluentui/react-icons';
import SaveDialog from '../../utils/SaveDialog';
import { useUser } from '../../../libs/hooks/useUser';
import { deleteUserPrompt } from '../../../redux/features/users/userPreferencesSlice';

const useClasses = makeStyles({
    headerText: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: customFonts.Lato,
        color: customColors.summarizeButtonDarker,
        ...shorthands.padding('15px'),
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 1410px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (max-width: 920px)': {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    largeButton: {
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        width: '100%',
        whiteSpace: 'normal',
        textAlign: 'left',
        cursor: 'auto',
        color: customColors.summarizeButtonDarker,
        backgroundColor: customColors.white,
        fontSize: '15px',
        fontweight: '500',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: customFonts.Lato,
        ...shorthands.padding('5px', '10px', '5px', '10px'),
        ...shorthands.margin('5px', 0, 0, 0),
        '&:hover': {
            cursor: 'pointer',
            fontWeight: '700',
        },
    },
    columnContainer: {
        width: '98%',
    },
    largeBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5px',
        ...shorthands.padding('15px'),
    },
    largeBlockCategoryContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&: hover': {
            cursor: 'pointer',
        },
        ...shorthands.margin(0, 0, '5px', 0),
    },
    buttonContainer: {
        display: 'flex',
    },
});

interface SavedToolTipBlockProps {
    suggestions: ISavedPrompt[];
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SavedToolTipBlock: React.FC<SavedToolTipBlockProps> = ({ suggestions, setValue }) => {
    const [_hoveredButton, setHoveredButton] = React.useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [editingPromptId, setEditingPromptId] = React.useState('');
    const [editingPromptText, setEditingPromptText] = React.useState('');
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const activeUserInfo = useAppSelector((state: RootState) => state.app.activeUserInfo, shallowEqual);
    const [_selectedGPT, setSelectedGPT] = useState<IGPT | undefined>(undefined);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);

    const classes = useClasses();
    const dispatch = useAppDispatch();
    const userService = useUser();
    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId].gptEndpoint);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    const handleOnClick = (suggestion: string, buttonText: string) => {
        const contentToCopy: string = suggestion;
        setValue(contentToCopy);
        dispatch(updateChatInput({ chatId: selectedId, text: contentToCopy }));
        document.body.click();

        // Tracking the click event with Application Insights
        appInsights.trackEvent({
            name: 'Prompt Suggestion Click',
            properties: {
                source: 'Suggested Prompts Button',
                type: 'Prompt',
                email: activeUserInfo?.email,
                buttonText: buttonText,
                suggestion: suggestion,
            },
        });
    };
    const handleEdit = (promptId: string, promptText: string) => {
        setIsDialogOpen(true);
        setEditingPromptId(promptId);
        setEditingPromptText(promptText);
    };
    const handleSavePrompt = async (newPromptText: string) => {
        const userId = conversations[selectedId].userId;
        const data = {
            promptId: editingPromptId,
            promptText: newPromptText,
            gptEndpoint: conversations[selectedId].gptEndpoint,
            isDocumentChat: conversations[selectedId].chatType,
        };
        await userService.updatePrompt(userId, data);
        setIsDialogOpen(false);
        setEditingPromptId('');
    };
    const handleDelete = async (promptId: string) => {
        const userId = conversations[selectedId].userId;
        await userService.deletePrompt(userId, promptId);
        dispatch(deleteUserPrompt({ promptId: promptId }));
    };

    return (
        <div>
            <div className={classes.columnContainer}>
                <div className={classes.largeBlockContainer}>
                    {suggestions.map((item, index) => {
                        const buttonKey = `${item.promptText}`;
                        return (
                            <Tooltip key={index} content={item.promptText} relationship="label" showDelay={500}>
                                <div
                                    key={index}
                                    onMouseEnter={() => setHoveredButton(buttonKey)}
                                    onMouseLeave={() => setHoveredButton(null)}
                                    className={classes.buttonContainer}
                                >
                                    <button
                                        className={classes.largeButton}
                                        onClick={() => {
                                            handleOnClick(item.promptText, item.promptText);
                                        }}
                                        onMouseEnter={(e) => {
                                            (e.currentTarget as HTMLButtonElement).style.border = '1px solid #EA7024';
                                            (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                '5px solid #EA7024';
                                        }}
                                        onMouseLeave={(e) => {
                                            (e.currentTarget as HTMLButtonElement).style.border = '1px solid #D9D9D9';
                                            (e.currentTarget as HTMLButtonElement).style.borderLeft =
                                                '5px solid #D9D9D9';
                                        }}
                                        style={{
                                            transition: 'background-color 0.3s ease',
                                            border: '1px solid #D9D9D9',
                                            borderLeft: '5px solid #D9D9D9',
                                        }}
                                    >
                                        {item.promptText}
                                    </button>
                                    <Tooltip content={'Edit prompt'} relationship="label">
                                        <Button
                                            icon={<EditRegular />}
                                            appearance="transparent"
                                            aria-label="Edit"
                                            onClick={() => handleEdit(item.promptId, item.promptText)}
                                        />
                                    </Tooltip>
                                    <Tooltip content={'Delete Prompt'} relationship="label">
                                        <Button
                                            icon={<DeleteRegular />}
                                            appearance="transparent"
                                            aria-label="Delete"
                                            onClick={() => handleDelete(item.promptId)}
                                        />
                                    </Tooltip>
                                </div>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
            {isDialogOpen && (
                <SaveDialog
                    tooltipContent="Enter the prompt you would like to save"
                    textAreaValue={editingPromptText}
                    title="Prompt"
                    onClose={() => setIsDialogOpen(false)}
                    onSave={handleSavePrompt}
                />
            )}
        </div>
    );
};
