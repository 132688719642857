// chatmemoryState.ts
import { ChatMemorySource } from "../../../libs/models/ChatMemorySource";

export interface ChatMemoryState {
    chatMemorySources: {
        [chatId: string]: ChatMemorySource[];
    };
    chatLoading: {
        [chatId: string]: boolean;
    };
}

export const initialChatMemoryState: ChatMemoryState = {
    chatMemorySources: {},
    chatLoading: {},
};
