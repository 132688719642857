import { useEffect, useRef, useState } from 'react';
import Loading from '../../../views/Loading';
interface PdfViewerProps {
    pdfContent: string | ArrayBuffer;
    fileName?: string;
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

const PDFViewer = ({ pdfContent, fileName }: PdfViewerProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let buffer: ArrayBuffer;

        if (typeof pdfContent === 'string') {
            buffer = base64ToArrayBuffer(pdfContent);
        } else {
            buffer = pdfContent;
        }

        const pdfBlob = new Blob([buffer], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob) + '#zoom=80';

        const timer = setTimeout(() => {
            if (iframeRef.current) {
                iframeRef.current.src = pdfUrl;
            }
        }, 500);

        return () => {
            clearTimeout(timer);
            URL.revokeObjectURL(pdfUrl);
        };
    }, [pdfContent]);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>

            {loading && <Loading text={`Loading ${fileName}...`} />}
            <iframe
                ref={iframeRef}
                title="PDF Viewer"
                width="100%"
                height="100%"
                style={{ border: 'none', display: loading ? 'none' : 'block' }}
                onLoad={handleLoad}
            />
        </div>
    );
};

export default PDFViewer;
