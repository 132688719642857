import {
    CheckboxProps,
    Checkbox,
    makeStyles,
    mergeClasses,
    Persona,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    shorthands,
    Text,
    tokens,
} from '@fluentui/react-components';
import { ShieldTask16Regular } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import {
    selectConversationsToDelete,
    setSelectedConversation,
} from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, customColors, customFonts } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';
import { useNavigate } from 'react-router-dom';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...Breakpoints.small({
            justifyContent: 'center',
        }),
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    collapsedRoot: {
        justifyContent: 'center',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        ...Breakpoints.small({
            display: 'none',
        }),
        alignSelf: 'center',
    },
    overlayBody: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        // ...Breakpoints.small({
        //     display: 'none',
        // }),
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '12.5px',
        fontFamily: customFonts.Lato,
        color: customColors.white,
        lineHeight: tokens.lineHeightBase200,
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
        ...shorthands.overflow('hidden'),
    },
    popoverSurface: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            flexDirection: 'column',
        }),
    },
    selected: {
        backgroundImage: 'linear-gradient(270deg, #01416B 66%, rgba(0, 64, 104, 0.1) 124.52%)',
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    gptIcon: {
        '& > span': {
            //backgroundColor: tokens.colorNeutralForegroundInverted,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    icon: {
        '& > span': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    checkbox: {
        '@media(max-width: 1000px)': {
            display: 'none',
        },
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    timestamp: number;
    preview: string;
    botProfilePicture: string;
    isSelected: boolean;
}

export const ChatListItem: FC<IChatListItemProps> = ({
    id,
    header,
    timestamp,
    preview,
    botProfilePicture,
    isSelected,
}) => {
    const chat = useChat();
    const classes = useClasses();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { conversations, selectedConversations } = useAppSelector((state: RootState) => state.conversations);
    const { isCollapsed, isOverlay } = useAppSelector((state: RootState) => state.commonData);
    const { features } = useAppSelector((state: RootState) => state.app);
    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
    const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;
    const [isChecked, setChecked] = useState<CheckboxProps['checked']>(false);
    const [showCheckbox, setShowCheckBoxes] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [chatSessionAvatar, setChatSessionAvatar] = useState<string | undefined>();

    useEffect(() => {
        if (conversations[id]) {
            let newAvatar: string | undefined = undefined;
            const currentGPTEndpoint = conversations[id].gptEndpoint;
            if (
                userGPTs.find((gpt) => gpt.gptEndpoint === currentGPTEndpoint && gpt.isActive)?.id ===
                process.env.REACT_APP_DEFAULT_PROGPT
            ) {
                newAvatar = botProfilePicture;
                setChatSessionAvatar(newAvatar);
            } else {
                const gpt = userGPTs.find((gpt) => gpt.gptEndpoint === currentGPTEndpoint);
                if (gpt) {
                    const gptIconFileName = gpt.gptIconFileName;
                    if (gptIconFileName) {
                        const url = process.env.REACT_APP_CDN_IMAGE_URL + gptIconFileName;
                        setChatSessionAvatar(url);
                    } else if (gpt.cardImageURL) {
                        setChatSessionAvatar(gpt.cardImageURL);
                    }
                }
            }
        }
    }, [userGPTs, conversations, id]);

    useEffect(() => {
        if (
            selectedConversations &&
            selectedConversations.length > 0 &&
            selectedConversations.find((conversation) => conversation.id === id)
        ) {
            setChecked(true);
        } else {
            setChecked(false);
        }
        if (selectedConversations && selectedConversations.length > 0) {
            setShowCheckBoxes(true)
        } else {
            setShowCheckBoxes(false)
        }
    }, [selectedConversations]);

    const onClick = (_ev: any) => {
        if (_ev.target.type === 'checkbox') {
            return;
        }
        dispatch(setSelectedConversation(id));
        const chatType = conversations[id].chatType;
        navigate(chatType ? `/documentchat/${id}` : `/chat/${id}`);
        /* eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition */
        if (conversations[id].messages.length == 0) {
            let count = -1;
            let skip = 0;
            if (process.env.REACT_APP_DEFAULT_PROGPT === conversations[id].customGPTId) {
                count = 100;
                skip = 0;
            }
            chat.loadChat(id, conversations[id].gptEndpoint, skip, count)
                .then((succeeded) => {
                    if (succeeded) {
                        //console.log(new Date().toString());
                    }
                })
                .catch(() => {
                    const errorMessage = `Unable to get chat messages. Please refresh the page or contact support.`;
                    console.log(errorMessage);
                });
        }
        if (selectedConversations && selectedConversations.length > 0) {
            if (selectedConversations.find((conversation) => conversation.id === id)) {
                const newSelectedConversations = selectedConversations.filter((item) => item.id !== id);
                dispatch(selectConversationsToDelete({ selectedConversations: newSelectedConversations }));
                setChecked(false);
            }
        }
    };
    const handleChecked = (e: any, checked: CheckboxProps['checked']) => {
        e.stopPropagation();
        if (selectedConversations.find((conversation) => conversation.id === id)) {
            const newSelectedConversations = selectedConversations.filter((item) => item.id !== id);
            dispatch(selectConversationsToDelete({ selectedConversations: newSelectedConversations }));
            setChecked(checked);
        } else {
            const newSelectedConversations = [...selectedConversations, conversations[id]];
            dispatch(selectConversationsToDelete({ selectedConversations: newSelectedConversations }));
            setChecked(checked);
        }
    };
    const time = timestampToDateString(timestamp);
    return (
        <Popover
            openOnHover={!isSelected}
            mouseLeaveDelay={0}
            positioning={{
                position: 'after',
                autoSize: 'width',
            }}
            // open={true}
        >
            <PopoverTrigger disableButtonEnhancement>
                <div
                    className={mergeClasses(classes.root, isSelected && classes.selected)}
                    onClick={onClick}
                    style={isCollapsed ? { justifyContent: 'center' } : {}}
                >
                    <Persona
                        className={chatSessionAvatar && chatSessionAvatar.length > 2 ? classes.gptIcon : classes.icon}
                        avatar={{
                            image: {
                                src:
                                    chatSessionAvatar && chatSessionAvatar.length > 2
                                        ? chatSessionAvatar
                                        : botProfilePicture,
                            },
                            shape: 'square',
                        }}
                        presence={
                            !features[FeatureKeys.SimplifiedExperience].enabled ? { status: 'available' } : undefined
                        }
                    />
                    {editingTitle ? (
                        <EditChatName
                            name={header}
                            chatId={id}
                            exitEdits={() => {
                                setEditingTitle(false);
                            }}
                        />
                    ) : (
                        <>
                            <div
                                className={isOverlay ? classes.overlayBody : classes.body}
                                style={isCollapsed ? {} : {}}
                            >
                                <div className={classes.header}>
                                    <Text className={classes.title}>
                                        {header}
                                        {features[FeatureKeys.AzureContentSafety].enabled && (
                                            <ShieldTask16Regular className={classes.protectedIcon} />
                                        )}
                                    </Text>
                                    {!features[FeatureKeys.SimplifiedExperience].enabled && (
                                        <Text className={classes.timestamp} size={300}>
                                            {time}
                                        </Text>
                                    )}
                                </div>
                                {showPreview && (
                                    <>
                                        {
                                            <Text
                                                id={`message-preview-${id}`}
                                                size={200}
                                                className={classes.previewText}
                                            >
                                                {preview}
                                            </Text>
                                        }
                                    </>
                                )}
                            </div>
                            {showActions && (
                                <ListItemActions
                                    chatId={id}
                                    chatName={header}
                                    onEditTitleClick={() => {
                                        setEditingTitle(true);
                                    }}
                                />
                            )}
                            {!showActions && !isCollapsed && showCheckbox &&  (
                                <>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={isChecked}
                                        onChange={(e, data) => handleChecked(e, data.checked)}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </PopoverTrigger>
            <PopoverSurface
                className={classes.popoverSurface}
                style={isCollapsed ? { display: 'flex', flexDirection: 'column' } : {}}
            >
                <Text weight="bold">{header}</Text>
            </PopoverSurface>
        </Popover>
    );
};
