import {
    Avatar,
    Text,
} from '@fluentui/react-components';
import useClasses from '../../EAStyles';
import logo from '../../../../assets/ea-icon.png'

import React from 'react';

export const EAHeader: React.FC = () => {
    const classes = useClasses();
    return (
        <>
            <div className={classes.hWrapper}>
                <Avatar
                    className={classes.logo}
                    aria-label="logo"
                    image={{
                        src: logo,
                    }}
                />
                <div className={classes.hTextWrapper}>
                    <Text className={classes.hHeaderText}>Welcome to the Engagement Accelerator</Text>
                    <Text className={classes.hDescriptionText}>Use this AI tool to generate project documents</Text>
                </div>
            </div>
        </>
    );
};