import { Text, Tooltip, Button, Spinner } from '@fluentui/react-components';
import useClasses from '../../EAStyles';
import { AddCircleFilled, AddCircleRegular, bundleIcon } from '@fluentui/react-icons';

import React, { useEffect, useRef } from 'react';
import DropzoneArea, { DropzoneAreaRef } from './DropzoneArea';
import FileList, { IFileItem } from './FileList';

import { useChat } from '../../../../libs/hooks';

import { useEngagementDocument } from '../../../../libs/hooks/useEAService';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { IChatSessionAttachedFiles } from '../../../../libs/models/ChatSession';
import { setConversations } from '../../../../redux/features/conversations/conversationsSlice';
import { EngagementDocument, EngagementFiles } from '../../../../libs/models/EngagementDocument';
import { toast } from 'react-toastify';

export const FileAttachment: React.FC = () => {
    const classes = useClasses();
    const chat = useChat();
    const engagementDocument = useEngagementDocument();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [selectedConversationId, setSelectedConversationId] = React.useState<string>(selectedId);
    const dispatch = useAppDispatch();
    const [isClosed, setIsClosed] = React.useState(false);
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<IChatSessionAttachedFiles[]>([]);
    const [engagementFiles, setEngagementFiles] = React.useState<EngagementFiles>();
    const dropzoneRef = useRef<DropzoneAreaRef>(null);
    const AddCircle = bundleIcon(AddCircleFilled, AddCircleRegular);

    const handleAddFile = () => {
        setIsClosed(false);
        dropzoneRef.current?.openDialog();
    };

    useEffect(() => {
        (async () => {
            if (refresh || selectedConversationId !== selectedId) {
                setIsProcessing(true);
                setSelectedConversationId(selectedId);
                const conversation = conversations[selectedId];
                const engagementFiles: EngagementFiles = (await engagementDocument.getEngagementFilesAsync(
                    conversation.shellId as string,
                )) as EngagementFiles;

                engagementFiles?.files?.map((file) => {
                    if (conversation.attachedFiles?.some((attFile) => attFile.fileName === file.fileName)) return;
                    return file;
                });
                setEngagementFiles(engagementFiles as EngagementFiles);
                setUploadedFiles(conversation.attachedFiles as IChatSessionAttachedFiles[]);
                setRefresh(false);
                setIsProcessing(false);
            }
        })();
    }, [conversations[selectedId], refresh]);

    const handleFilesAdded = async (droppedFiles: File[]) => {
        droppedFiles.map(async (dFile) => {
            if (uploadedFiles.findIndex((uFile) => uFile.fileName === dFile.name) === -1) {
                const newUploadedFiles: IFileItem[] = droppedFiles.map((file) => ({
                    extension: file.name.split('.')[1],
                    id: Math.random().toString(),
                    name: file.name,
                    file: file,
                }));

                setIsProcessing(true);
                const uploadedDocuments = await chat.uploadDocumentsAsync(
                    conversations[selectedId].id,
                    newUploadedFiles,
                );

                let updatedConversations = {
                    ...conversations,
                    [selectedId]: {
                        ...conversations[selectedId],
                        attachedFiles: [...uploadedDocuments],
                    },
                };

                dispatch(setConversations(updatedConversations));
                setRefresh(true);
                setIsProcessing(false);
            } else {
                toast.error('Duplicate file is not allowed.');
            }
        });
    };

    const handleDelete = (fileId: string) => {
        console.log(fileId);
    };

    const handleToggleCheckboxForUploadedFiles = (file: EngagementDocument) => {
        setIsProcessing(true);
        const conversation = conversations[selectedId];
        if (file) {
            engagementDocument
                .uploadEngagementFilesToAssitantAsync(conversation.shellId as string, conversation.id, [file])
                .then(() => {
                    setIsProcessing(false);
                    setRefresh(true);
                });
        }
    };

    return (
        <>
            <span className={classes.fileListHeaders}>
                <Text className={classes.fileListTitle}>ALL FILES</Text>

                <div className={classes.addFileButton}>
                    <Tooltip relationship="label" content="Add files from your computer">
                        <Button disabled={isClosed} appearance="outline" size="small" onClick={handleAddFile}>
                            <AddCircle className={classes.buttonImage} />
                            Add Files
                        </Button>
                    </Tooltip>
                </div>
            </span>
            <section className={classes.fileList}>
                {uploadedFiles?.length > 0 && (
                    <FileList
                        engagementFiles={engagementFiles}
                        uploadedFiles={uploadedFiles}
                        onToggleCheckboxForUploadedFiles={handleToggleCheckboxForUploadedFiles}
                        handleDelete={handleDelete}
                    />
                )}
                <DropzoneArea onFilesAdded={handleFilesAdded} ref={dropzoneRef} isHidden={uploadedFiles?.length > 0} />
                {isProcessing && (
                    <div className={classes.spinnerContainer}>
                        <Spinner size="extra-large" />
                    </div>
                )}
            </section>
        </>
    );
};
