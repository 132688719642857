import { Delete16Regular, DocumentPdf24Regular, DocumentText24Regular } from '@fluentui/react-icons';
import PptComponent from '../../../../assets/icons8-microsoft-powerpoint-2019-48.png';
import WordComponent from '../../../../assets/icons8-microsoft-word-2019-48.png';
import ExcelComponent from '../../../../assets/icons8-microsoft-excel-2019-48.png';

import { Button, makeStyles, shorthands } from '@fluentui/react-components';

import { IChatSessionAttachedFiles } from '../../../../libs/models/ChatSession';
import { EngagementDocument, EngagementFiles } from '../../../../libs/models/EngagementDocument';

export interface IFileItem {
    id: string;
    name: string;
    extension: string;
    file?: File;
}
const useClasses = makeStyles({
    text: {
        color: '#555',
    },
    fileListTable: {
        paddingRight: '10px',
        paddingLeft: '10px',
        width: '100%',
        height: '200px',
        overflowY: 'auto',
        tableLayout: 'fixed',
    },
    fileListTableTh: {
        textAlign: 'left',
        color: 'black',
        fontSize: 'medium',
        fontWeight: '400',
        padding: '5px',
    },
    fileListTableTd: {
        padding: '0.5rem',
        textAlign: 'right',
    },
    customCheckBox: {
        width: '20px',
    },

    iconAndName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'medium',
    },
    pulledFromText: {
        fontSize: 'small',
        color: 'gray',
    },
    fileNameText: {},
    select: {
        padding: '5px',
    },
    fileNameSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    fileNameColContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    fileListTableBody: {
        maxHeight: '200px',
        overflowY: 'auto',
    },
    deleteButton: {
        color: 'red',
        padding: '0px',
        width: 'fit-content',
        border: 'none',
        ':hover': {
            ...shorthands.borderColor('red'),
            color: 'red',
        },
    },
});
interface FileListProps {
    uploadedFiles: IChatSessionAttachedFiles[];
    engagementFiles: EngagementFiles | undefined;
    onToggleCheckboxForUploadedFiles: (file: EngagementDocument) => void;
    handleDelete: (fileId: string) => void;
}

const FileList: React.FC<FileListProps> = ({
    uploadedFiles,
    onToggleCheckboxForUploadedFiles,
    engagementFiles,
    handleDelete,
}) => {
    const classes = useClasses();
    const getFileIcon = (extension: string) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return <DocumentPdf24Regular style={{ color: '#E81123', marginRight: '8px' }} />;
            case 'doc':
            case 'docx':
                return <img src={WordComponent} style={{ color: '#E81123', marginRight: '8px', width: '25px' }} />;
            case 'xlsx':
            case 'csv':
                return <img src={ExcelComponent} style={{ color: '#E81123', marginRight: '8px', width: '25px' }} />;
            case 'ppt':
            case 'pptx':
                return <img src={PptComponent} style={{ color: '#E81123', marginRight: '8px', width: '25px' }} />;
            default:
                return <DocumentText24Regular style={{ color: '#E81123', marginRight: '8px' }} />;
        }
    };
    return (
        <div className={classes.fileListTable}>
            <table>
                <thead>
                    <tr>
                        <th className={classes.fileListTableTh} style={{ width: '80%' }}>
                            <div className={classes.fileNameColContainer}>FILE NAMES</div>
                        </th>

                        <th className={classes.fileListTableTh} style={{ textAlign: 'right', width: '15%' }}>
                            USE FILE
                        </th>
                        <th className={classes.fileListTableTh} style={{ textAlign: 'right', width: '5%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {uploadedFiles?.map((file) => (
                        <tr key={file.id}>
                            <td className={classes.fileListTableTd}>
                                <div className={classes.iconAndName}>
                                    {getFileIcon(file.fileName.split('.')[1])}
                                    <span className={classes.fileNameText}>{file.fileName}</span>
                                </div>
                            </td>
                            <td className={classes.fileListTableTd}>
                                <input className={classes.customCheckBox} type="checkbox" checked={true} disabled />
                            </td>
                            <td className={classes.fileListTableTd}>
                                <Button
                                    className={classes.deleteButton}
                                    // disabled={file.pulledFromApi}
                                    size="small"
                                    onClick={() => {
                                        handleDelete(file.id);
                                    }}
                                >
                                    <Delete16Regular />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {engagementFiles?.files?.map((file) => (
                        <tr key={file.fileId}>
                            <td className={classes.fileListTableTd}>
                                <div className={classes.iconAndName}>
                                    {getFileIcon(file.fileName.split('.')[1])}
                                    <span className={classes.fileNameText}>{file.fileName}</span>
                                </div>
                            </td>
                            <td className={classes.fileListTableTd}>
                                <input
                                    className={classes.customCheckBox}
                                    type="checkbox"
                                    checked={false}
                                    onChange={() => onToggleCheckboxForUploadedFiles(file)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FileList;
